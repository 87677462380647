import request from "@/utils/request";
import request2 from "@/utils/request2";

let velService = "vehicle-service/";
let userService = "user-service/";
let alarmService = "alarm-service/";
let orgService = "organization-service/";
let disService = "dispatcher-service/";
let statiService = "statistics-service/";

export function queryUser(params) {
	return request({
		url: userService + `user/query/user/bytoken`,
		method: "get",
		params
	});
}

// 查询当前登录用户能看到的根组织列表
// /saas/api/organization-service/organization/findRootOrgs
export function findRootOrgs(params) {
    return request({
        url: velService + `organization/findOrgTreeList`,
        method: "get",
        params
    });
}
// 查询当前登录用户能看到的所有车
export function queryAllVehicleTree(params) {
    return request({
        url: velService + `vehicle/queryAllVehicleTree`,
        method: "get",
        params
    });
}
// 查询当前登录用户能看到的所有车全量终端信息
export function queryAllTerminalTree(params) {
    return request({
        url: velService + `terminal/queryAllTerminalTree`,
        method: "get",
        params
    });
}



// 获取组织
export function getOrg(data) {
    return request({
        url: velService + `organization/findOrgTree`,
        method: "post",
        data
    });
}

// 获取车辆树
// /saas/api/vehicle-service/api/vehicle/organization/tree
// export function getVehicleTree(params) {
// 	return request({
// 		url: velService + "vehicle/organization/tree",
// 		method: "get",
// 		params
// 	});
// }

// 获取车辆树：方法二
// /saas/api/vehicle-service/api/vehicle/organization/tree/v2
export function getVehicleTree2(params) {
    return request({
        url: velService + "vehicle/organization/tree/v2",
        method: "get",
        params
    });
}

// 获取指定用户车辆树
// /saas/api/vehicle-service/vehicle/organization/oneself/tree
export function getOneVehicleTree(params) {
    return request({
        url: velService + "vehicle/organization/oneself/tree",
        method: "get",
        params
    });
}

// 查询用户配置
// /saas/api/user-service/platform/config/query
export function queryPlatformConfig() {
    return request({
        url: userService + `platform/config/query`,
        method: "get"
    });
}

// 保存用户配置
// /saas/api/user-service/platform/config/save
export function savePlatformConfig(data) {
    return request({
        url: userService + `platform/config/save`,
        method: "post",
        data
    });
}

// 报警类型码表
// /saas/api/alamr-service/alarmType/queryAllAlarmType
export function queryAllAlarmType() {
    return request({
        url: alarmService + "alarmType/queryAllAlarmType",
        method: "get"
    });
}

export function getLocation(data){
    return request({
        url: velService + `location/address/rgeo`,
        method: "post",
        data
    });
}

// 查询证据列表
// /saas/api/alarm-service/active/attach/queryActiveAttachList
export function queryActiveAttachList(params) {
    return request({
        url: alarmService + "active/attach/queryActiveAttachList",
        method: "get",
        params
    });
}

// 查询证据详情
// /saas/api/alarm-service/active/attach/pullFtpEvidenceStream
export function pullFtpEvidenceStream(params) {
    return request({
        url: alarmService + "active/attach/pullFtpEvidenceStream",
        method: "get",
        params,
        responseType: "blob",
        contentType: "video/mp4"
    });
}

// 查岗通知回复
// /saas/api/dispatcher-service/issue/jgPlatformQuery/answer
export function jgPlatformAnswer(data) {
    return request({
        url: disService + "jgPlatformQuery/answer",
        method: "post",
        data
    });
}

// 车辆日上线状态查询
// /saas/api/statistics-service/vehicleDayStatus/queryVehicleOnlineStatus
export function queryVelOLStatus(params) {
    return request({
        url: statiService + "vehicleDayStatus/queryVehicleOnlineStatus",
        method: "get",
        params
    });
}

// 平台配置查询（向上递归）
// /saas/api/user-service/person/config/queryPersonConfigByRecurseUp
export function personConfigByRecurseUp(params) {
    return request({
        url: userService + `person/config/queryPersonConfigByRecurseUp`,
        method: "get",
        params
    });
}

// 平台配置添加
// /saas/api/user-service/person/config/addPersonConfig
export function addPersonConfig(data) {
    return request({
        url: userService + `person/config/addPersonConfig`,
        method: "post",
        data
    });
}

// 平台配置修改
// /saas/api/user-service/person/config/modifyPersonConfig
export function modifyPersonConfig(data) {
    return request({
        url: userService + `person/config/modifyPersonConfig`,
        method: "put",
        data
    });
}

// 平台配置查询 （个人设置）
// /saas/api/user-service/person/config/queryPersonConfig
export function personConfig(params) {
    return request({
        url: userService + "person/config/queryPersonConfig",
        method: "get",
        params
    });
}

// 平台配置删除
// /saas/api/user-service/person/config/{id}/clear
export function deletePersonConfig(id) {
    return request({
        url: userService + `person/config/${id}/clear`,
        method: "delete",
    });
}

// 查询某用户看到的组织树及组织下车辆总数
// /saas/api/vehicle-service/tree/oneself/orgVehCountTree
export function orgVehCountTree(params) {
    return request({
        url: velService + 'tree/oneself/orgVehCountTree',
        method: "get",
        params
    });
}

// 查询某个组织下的直属车辆
// /saas/api/vehicle-service/tree/orgself/simpleVehicle
export function simpleVehicle(data) {
    return request({
        url: velService + 'tree/orgself/simpleVehicle',
        method: "post",
        data
    });
}

// 组织车辆树模糊搜索
// /saas/api/vehicle-service/tree/oneself/search
export function vehicleSearch(params) {
    return request({
        url: velService + 'tree/oneself/search',
        method: "get",
        params
    });
}

// 查询组织或车辆叶子树
// /saas/api/vehicle-service/tree/orgVeh/leaftree
export function leaftree(params) {
    return request({
        url: velService + 'tree/orgVeh/leaftree',
        method: "get",
        params
    });
}

// 刷新组织树里车辆在线状态
// /saas/api/vehicle-service/tree/vehicles/state/refresh
export function refreshStatus(data) {
    return request({
        url: velService + 'tree/vehicles/state/refresh',
        method: "post",
        data
    });
}
export function queryAllOrgTree(data) {
    return request({
        url: velService + 'organization/queryAllOrgTree',
        method: "get",
        data
    });
}
export function queryOrgTreeByUserId(data) {
    return request({
        url: velService + `organization/queryOrgTreeByUserId?userId=${data.userId}`,        
        method: "get"
    });
}

// 刷新组织树里车辆在线状态
// /saas/api/vehicle-service/tree/vehicles/state/refresh
export function refreshStatusV3(data) {
    return request({
        url: velService + 'tree/vehicles/state/refreshV3',
        method: "post",
        data
    });
}


export function getVehicleStatusV3(data) {
    return request({
        url: velService + 'tree/getVehicleStatusV3',
        method: "post",
        data
    });
}

// 全量查询车辆管理服务信息树
export function queryAllVehicleManageTree(params) {
    return request({
        url: velService + `/vehicleManage/queryAllVehicleManageTree`,
        method: "get",
        params
    });
}


// 全量车辆基础信息树查询
export function queryVehicleBaseTree(params) {
    return request({
        url: velService + `tree/queryVehicleBaseTree1`,
        method: "get",
        params
    });
}
// 全量车辆基础信息树查询
export function queryVehicleBaseTreeV2(params) {
    return request({
        url: velService + `tree/queryVehicleBaseTree1`,
        method: "get",
        params
    });
}
// 车辆树实时信息查询
export function queryTreeRealTime(data) {
    if(data.vehicleBusinessStatus && typeof data.vehicleBusinessStatus=='number'){
        delete data.vehicleBusinessStatus;
    }
    return request({
        url: velService + 'tree/queryTreeRealTime',
        method: "post",
        data
    });
}

