<template>
    <div class="saas-tree4" :class="elName + 'org-tree'">
        <el-input
                ref="saasTree4Input"
                :placeholder="placeholder"
                class="suffix"
                v-model="formValue[formProps.label]"
                autocomplete="off"
                :disabled="disabled"
                clearable
                @clear="clear()"
                @input="treeInput"
                @mouseover.native="inputMouseover"
                @mouseout.native="inputMouseout"
        >
            <i v-if="iconXialaShow" @click="isTreeShow=true" class="input-icon iconfont iconxiala" slot="suffix"></i>
        </el-input>
        <el-tree
                v-show="isTreeShow"
                :data="newTreeData"
                :props="treeProps"
                :default-expand-all="isExpandAll"
                :default-expanded-keys="expandedKeys"
                :current-node-key="currentNodeKey"
                :node-key="formProps.value"
                ref='saasTree4'
                class="tree"
                :filter-node-method="filterNode"
                @node-click="handleClick"
        ></el-tree>
    </div>
</template>

<script>
    export default {
        name: "SAASTree4",
        props: {
            formValue: {
                required: true
            },
            treeProps: {
                type: Object,
                default: function () {
                    return {
                        label: "label",
                        value: "value",
                        children: "children"
                    };
                }
            },
            formProps: {
                type: Object,
                default: function () {
                    return {
                        label: "label",
                        value: "value"
                    };
                }
            },
            treeData: {
                required: true
            },
            placeholder: {
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            isExpandAll: {
                type: Boolean,
                default: false
            },
            currentNodeKey: {
                required: false
            },
            expandedKeys: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            notClick: {
                required: false
            },
            elName: {
                type: String,
                default: function () {
                    return "";
                }
            },
            pageType: {required: false}
        },
        watch: {
            "filterText"(val) {
                if (this.$refs.saasTree4) {
                    this.$refs.saasTree4.filter(val);
                }
            },
			"treeData"(val){
				this.newTreeData = this.$utils.deepClone(val);
				this.$forceUpdate();
			}
        },
        data() {
            return {
                filterText: "",
                isTreeShow: false,
                iconXialaShow: true,
                newTreeData: this.$utils.deepClone(this.treeData)
            };
        },
        mounted() {
            let that = this;
            if (this.pageType == "vehicleManage1") {
                function recursionOrg(list) {
                    for (let i = 0; i < list.length; i++) {
                        if (list[i].children) {
                            recursionOrg(list[i].children);
                        }
                        if (!list[i].mdFlag && list[i].entId != -1) {
                            list.splice(i, 1);
                            i--;
                        }
                    }
                }
                recursionOrg(this.newTreeData);
            }
            document.addEventListener("mousedown", (event) => {
                that.handleClose(event);
            });
        },
        methods: {
            onResionIsShow() {
                this.filterText = "";
                this.isTreeShow = true;
            },
            inputMouseover() {
                if (this.formValue[this.formProps.label]) {
                    this.iconXialaShow = false;
                }
            },
            inputMouseout() {
                if (!this.isTreeShow) {
                    this.iconXialaShow = true;
                }
            },
            treeInput(val) {
                this.filterText = val;
            },
            clear() {
                this.formValue[this.formProps.label] = "";
                this.formValue[this.formProps.value] = "";
                this.$forceUpdate();
                this.$emit("orgTreeClick", {});
            },
            handleClose(e) {
                if (!$("." + this.elName + "org-tree").length) {
                    return;
                }
                if (!$("." + this.elName + "org-tree")[0].contains(e.target)) {
                    this.isTreeShow = false;
                    this.iconXialaShow = true;
                } else {
                    this.isTreeShow = true;
                }
            },
            filterNode(value, data) {
                if (!value) return true;
                return data[this.treeProps.label].indexOf(value) !== -1;
            },
            handleClick(data) {
                if (data) {
                    if (typeof this.notClick == "object") {
                        if (this.notClick.length && this.notClick.indexOf(data[this.treeProps.value]) != -1) {
                            return;
                        }
                        if (this.notClick.length == undefined && data[this.notClick.label] == this.notClick.value) {
                            return;
                        }
                    }
                    this.iconXialaShow = true;
                    this.isTreeShow = false;
                    this.formValue[this.formProps.label] = data[this.treeProps.label];
                    this.formValue[this.formProps.value] = data[this.treeProps.value];
                    this.$emit("orgTreeClick", data);
                }
            }
        }
    };
</script>

<style lang="less">
    .saas-tree4 {
        .el-input__inner {
            height: 34px;
            padding-right: 25px;
        }

        .tree {
            width: 100%;
            height: 280px;
            overflow: auto;
            position: absolute;
            top: 34px;
            z-index: 10;
            -webkit-box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, .1);
            box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, .1);
        }

        .el-tree-node > .el-tree-node__children {
            overflow: inherit;
        }
    }
</style>
