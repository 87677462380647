<template>
    <div class="tree-search-container" :class="elName + 'vehicle-tree2'"  v-clickoutside="handleClose">
        <div v-if="isLoading && $store.state.vehicleTreeLoading" class="vehicle-tree-loading">
            <div>数据加载中...</div>
            <div class="loading-icon iconfont iconjiazai"></div>
        </div>        
        <template v-else>
            <el-input
                    ref="vehicleTreeInput"
                    class="suffix"
                    v-model="treeSearchValue"
                    autocomplete="off"
                    type="text"
                    clearable
                    :disabled="disabled"
                    :title="treeSearchValue"
                    :placeholder="placeholder || '输入进行查询'"
                    @focus="treeInputFocus"
                    @clear="clearInput"
                    @input="((val)=>{treeInput(val)})"
                    @mouseenter.native="inputMouseover"
                    @mouseleave.native="inputMouseout"
            >
                <template>
                    <i v-if="iconXialaShow" @click="isiTreeShow=true" class="input-icon iconfont"
                       :class="iconName"
                       slot="suffix"></i>
                </template>
            </el-input>
            <ul class="search-list el-select-group__wrap" v-if="treeSearchList.length && searchListShow">
				<template v-if="entList.length>0">
					<li class="el-select-group__title" >组织</li>
					<li v-for="item in entList" :key="item[treeProps.label]" @click="treeSearchById(item)">
						{{ item[treeProps.label] }}
					</li>				
				</template>
				<template v-if="vehicleList.length>0">
					<li class="el-select-group__title">车辆</li>
					<li v-for="item in vehicleList" :key="item[treeProps.label]" @click="treeSearchById(item)">
						{{ item[treeProps.label] }}
					</li>
				</template>
            </ul>			
            <div class="no-search-tree-data" v-if="noSearchTreeData && isiTreeShow">暂无数据</div>
			<div
                    v-if="treeContainerShow || treeShowFlag"
                    v-show="isiTreeShow || treeShowFlag"
                    ref="vehicleTree2"
                    id="vehicleTree2"
                    class="vel-tree-container"
            >
                <div style="position: absolute;min-width: 100%;">
                    <VehicleRecursionTree2
                        ref="recursionTree"
                        :treeData="treeData"
                        :treeIndex="0"
                        :treeProps="treeProps"
                        :checkBox="checkBox"
                        :pageType="pageType"
                        :maxLength="maxLength"
                        :checkedLength="checkedLength"
                        @treeClick="treeClick"
                        @treeChoose="treeChoose"
                        @treeExtend="treeExtend"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import {simpleVehicle, vehicleSearch, leaftree} from "@/api/index";

    let hashSet = new Set();
    let setVehicleNo = new Set();
    export default {
        name: "VehicleTree2",
        props: {
            isLoading: {required: false},
            placeholder: {required: false},
            treeData: {required: true},
            notClick: {required: false},
            checkBox: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            disabledList: {
                type: Set,
                default: () => {
                    return new Set();
                }
            },
            treeProps: {
                type: Object,
                default: function () {
                    return {
                        label: "label",
                        value: "value",
                        children: "children"
                    };
                }
            },
            formValue: {required: false},
            treeShowFlag: {required: false},
            pageType: {
                type: String,
                default: function () {
                    return "default";
                }
            },
            maxLength: {required: false},
            checkedLength: {required: false},
            iconName: {
                type: String,
                default: function () {
                    return "iconxiala";
                }
            },
            elName: {
                type: String,
                default: function () {
                    return "";
                }
            },
			dataType:{
				type: Number,
				default: null
			}
        },
        watch: {
            formValue: {
                handler(val) {
                    if (!val) {
                        return;
                    }
                    if (val.entName) {
                        this.treeSearchValue = val.entName;
                    }
                },
                immediate: true,
                deep: true
            },
            treeData: {
                handler(val) {
                    this.vehicleTreeList = val;
                },
                deep: true
            }
        },
        data() {
            return {
                treeNum: 1,
                isiTreeShow: false,
                iconXialaShow: true,
                treeContainerShow: false,
                treeSearchValue: "",
                timerNum: 0,
                timer: "",
                treeSearchList: [],
                searchListShow: false,
                noSearchTreeData: false, // 搜索无数据
                vehicleTreeList: this.treeData,
				entList: [], // 从treeSearchList筛选出组织和车辆
				vehicleList: []
            };
        },
        mounted() {
            hashSet = new Set();
            setVehicleNo = new Set();
            let that = this;
            document.addEventListener("mousedown", (event) => {
                that.handleClose(event);
            });
        },
        methods: {
            clearInput() {
                this.$emit("vehicleTreeCheck", "", {});
            },
            treeInputFocus() {
                this.treeContainerShow = true;
                this.isiTreeShow = true;
                this.searchListShow = true;
            },
            treeClick(handler, item) {
                if (item && !this.checkBox) {
                    if (item[this.treeProps.value] == -1) {
                        return;
                    }
                    if (this.notClick && item[this.notClick.label] == this.notClick.value) {
                        return;
                    }
                    this.treeSearchValue = item[this.treeProps.label];
                    this.isiTreeShow = false;
                    this.searchListShow = false;
                    this.treeSearchList = [];
                    this.$emit("vehicleTreeCheck", handler, item);
                }
            },
            treeChoose(item, vehicles) {
                if (this.pageType == "velAssignSms" ||
                    this.pageType == "velAssign" ||
                    this.pageType == "ruleVelTree" ||
                    this.pageType == "vehicleTemplate"
                ) {
                    this.toggleCheckBox2();
                } else {
                    this.toggleCheckBox();
                }
                this.$emit("vehicleTreeCheck", "vehicleTree", item,vehicles);
            },
            treeExtend(item, callback) {
                // isExpand记录已经展开过，获取过组织下的车辆
                if (item.isExpand) {
                    callback && callback();
                    return;
                }
                // 解决从VehicleRecursionTree2中触发toggleNode，组织展开后，组织下的车辆不显示 
                if(item.flag){
                    callback = item.callback;
                    item = item.data;
                }
                let params = {
                    entId: item.value,
                    isCache: true
                };
                item.isExpand = false;
                if (this.pageType.indexOf("terminalParam") != -1) {
                    params.moreFields = "pt";
                }
                simpleVehicle(params).then(res => {
                    if (res.code === 0) {
                        item.isExpand = true;
						// 获取到车辆
                        if (res.result.length) {
                            let arr = [],valueSet = new Set();
                            for (let i = 0; i < res.result.length; i++) {
                                let vehicle = res.result[i];
								valueSet.add(vehicle.v1);
                                vehicle.type = 2;
                                vehicle.label = vehicle.v2;
                                delete vehicle.v2;
                                vehicle.value = vehicle.v1;
                                delete vehicle.v1;
                                if (this.pageType == "velAssignSms" ||
                                    this.pageType == "velAssign" ||
                                    this.pageType == "ruleVelTree" ||
                                    this.pageType == "vehicleTemplate"
                                ) {
                                    vehicle.checked = item.partchecked;
                                } else {
                                    vehicle.checked = item.checked;
                                }
                                if (this.disabledList.has(vehicle.value)) {
                                    vehicle.disabled = true;
                                    vehicle.checked = true;
                                }
                                if (this.pageType.indexOf("terminalParam") != -1) {
                                    vehicle.vehicleStatus = vehicle.v6;
                                    delete vehicle.v6;
                                }
                                if (this.pageType == "emphasisMonitor") {
                                    vehicle.checkBox = true;
                                }
                                if (this.pageType == "user") {
                                    vehicle.pathIds = [...item.pathIds, item.value];
                                }
                                if (this.pageType == "terminalParam") {
                                    if (vehicle.pt != 17 && vehicle.pt != 18 && vehicle.pt != 10 && vehicle.pt != 19) {
                                        arr.push(vehicle);
                                    }
                                } else if (this.pageType == "terminalParamYue") {
                                    if (vehicle.pt == 17) {
                                        arr.push(vehicle);
                                    }
                                } else if (this.pageType == "terminalParamZhe") {
                                    if (vehicle.pt == 18) {
                                        arr.push(vehicle);
                                    }
                                } else if (this.pageType == "terminalParamGui") {
                                    if (vehicle.pt == 10) {
                                        arr.push(vehicle);
                                    }
                                } else if (this.pageType == "terminalParamLu") {
                                    if (vehicle.pt == 19) {
                                        arr.push(vehicle);
                                    }
                                } else {
                                    arr.push(vehicle);
                                }
                            }
                            //item.children.push(...arr);
							// children中有可能有和arr中重复的数据 
							let arrTmp = [];
							if(item.children.length==0){
								item.children = [...arr];
							}else{
								item.children.forEach((v,index)=>{
									if(!valueSet.has(v.value)){
										arrTmp.push(v);
									}
								});
								item.children = [...arrTmp,...arr];
							}

							this.$emit("treeExtend", item);
                        }
                        //item.isExpand = true;
                        callback && callback(item.children);
                    }
                });
            },
            treeInput(value) {
                this.iconXialaShow = false;
                if (!value.length) {
                    this.iconXialaShow = true;
                }
                let that = this;
                if (this.timer) {
                    clearTimeout(this.timer);
                }
				if (value.length < 2) {
				    this.treeSearchList = [];
				    this.noSearchTreeData = false;
				    return;
				}		
                this.timer = setTimeout(function () {
                    if (!value) {
                        let item = {
                            value: "",
                            label: "",
                            type: 2
                        };
                        that.$emit("vehicleTreeCheck", "vehicleTree", item);
                    }
                    that.treeSearch(value);
                }, 500);
            },
            // TODO:前端本地搜索
            treeSearch(value) {
                let params = {
                    name: value,
                    limit: 50,
					dataType: this.dataType
                };
                if (this.pageType == "terminalParamYue") {
                    params.protocolType = "17";
                } else if (this.pageType == "terminalParamZhe") {
                    params.protocolType = "18";
                } else if (this.pageType == "terminalParamGui") {
                    params.protocolType = "10";
                }
				this.entList = [];
				this.vehicleList = [];				
                vehicleSearch(params).then(res => {
                    if (res.code === 0) {
                        this.noSearchTreeData = false;
                        if (res.result.length) {
							res.result.forEach(v=>{
								let obj = {
									label: v.name,
									value: v.v1,
									dataType: v.dataType, // dataType: 1 车辆；2 组织
									type: v.dataType == 1 ? 2 : 1 // 应该是和选中组织的type统一
								};
								this.treeSearchList.push(obj);
								if(v.dataType==1){
									this.vehicleList.push(obj);
								}
								if(v.dataType==2){
									this.entList.push(obj);
								}
							});					
                        } else {
                            this.noSearchTreeData = true;
							this.treeSearchList = [];
                        }
                    }
                });
            },
            // TODO:点击搜索后的结果有问题
            treeSearchById(data) {
                if (this.pageType == "default") {
                    this.treeClick("vehicleTree", data);
                    return;
                }
                if (this.pageType == "areaManage" && data.type == 2) {
                    this.treeClick("vehicleTree", data);
                    return;
                }
                let params = {
                    dataType: data.dataType,
                    id: data.value
                }, that = this;
                leaftree(params).then(res => {
                    if (res.code === 0) {
                        var result = res.result;

                        function recursionFun(treeList = [], resultItem) {
                            for (let j = 0; j < treeList.length; j++) {
                                let item = treeList[j];
                                that.$set(item, "active", false);
                                if (data.dataType == 2) {
                                    if (resultItem[0].o1 == item[that.treeProps.value]) {
                                        if (!resultItem[0].c) {
                                            setTimeout(function () {
                                                let node = $("#treeItem" + item[that.treeProps.value]);
                                                that.$refs.vehicleTree2.scrollTop = 0;
                                                that.$refs.vehicleTree2.scrollTop = node.offset().top - $("#vehicleTree2").offset().top;
                                                that.$set(item, "active", true);
                                                that.$emit("treeSearchById", item, data.dataType);
                                            }, 500);
                                            continue;
                                        } else {
                                            that.$set(item, "expand", true);
                                            that.treeExtend(item);
                                            recursionFun(item[that.treeProps.children], resultItem[0].c);
                                            continue;
                                        }
                                    }
                                } else {                                    
                                    if (resultItem[0].o1 == item[that.treeProps.value]) {
                                        if (resultItem[0].c) {
                                            that.$set(item, "expand", true);
                                            that.treeExtend(item);
                                            recursionFun(item[that.treeProps.children], resultItem[0].c);
                                            continue;
                                        } else if (resultItem[0].vs) {
                                            that.$set(item, "expand", true);
                                            that.treeExtend(item, () => {
                                                recursionFun(item[that.treeProps.children], resultItem[0].vs);
                                            });
                                            continue;
                                        }
                                    } else if (resultItem[0].v1 == item[that.treeProps.value]) {
                                        setTimeout(function () {
                                            let node = $("#treeItem" + item[that.treeProps.value]);
                                            that.$refs.vehicleTree2.scrollTop = 0;
                                            that.$refs.vehicleTree2.scrollTop = node.offset().top - $("#vehicleTree2").offset().top;
                                            that.$set(item, "active", true);
                                            that.$emit("treeSearchById", item, data.dataType);
                                        }, 500);
                                        continue;
                                    }
                                }
                            }
                        }

                        that.searchListShow = false;
                        recursionFun(that.vehicleTreeList[0].children, result);                     
                        this.searchListShow = false;
                    }
                });
            },
            inputMouseover() {
                if (this.treeSearchValue) {
                    this.iconXialaShow = false;
                }
            },
            inputMouseout() {
                if (!this.isiTreeShow) {
                    this.iconXialaShow = true;
                }
            },
            toggleCheckBox() {
                // 勾选某一行时，修改父节点及子节点选中状态
                var that = this;
                function recursionList(item) {
                    var checkedNum = 0;
                    var partCheckedNum = 0;
                    item.children.forEach((li) => {
                        if (li.isExpand) {
                            if (li.children && li.children.length > 0) {
                                recursionList(li);
                            }
                        }
                        if (li.checked === true) {
                            checkedNum++;
                        } else if (li.partchecked === true) {
                            partCheckedNum++;
                        }
                    });
                    if (that.pageType == "user") {
                        if (checkedNum === item.children.length && item.checked) {	//全选
                            that.$set(item, "checked", true);
                            that.$set(item, "partchecked", false);
                        } else if (checkedNum > 0 || partCheckedNum > 0) {	//部分勾选
                            that.$set(item, "checked", false);
                            that.$set(item, "partchecked", true);
                        } else {	//没有勾选
                            that.$set(item, "checked", false);
                            that.$set(item, "partchecked", false);
                        }
                    } else {
                        if (checkedNum === item.children.length) {	//全选
                            that.$set(item, "checked", true);
                            that.$set(item, "partchecked", false);
                        } else if (checkedNum > 0 || partCheckedNum > 0) {	//部分勾选
                            that.$set(item, "checked", false);
                            that.$set(item, "partchecked", true);
                        } else {	
                            //没有勾选
                            that.$set(item, "checked", false);
                            that.$set(item, "partchecked", false);
                        }
                    }
                    that.$forceUpdate();
                }

                recursionList(this.treeData[0]);
                
            },
            toggleCheckBox2(flag) {
                // 勾选某一行时，修改父节点及子节点选中状态
                var that = this;
                function recursionList(item) {		
                    var checkedNum = 0;
                    var partCheckedNum = 0;
                    item.children.forEach((li) => {
                        if (li.isExpand || flag) {
                            if (li.children && li.children.length > 0) {
                                recursionList(li);
                            }
                        }
                        if (li.checked) {
                            checkedNum++;
                        } else if (li.partchecked) {
                            partCheckedNum++;
                        }
                    });
                    if ((item.partchecked || item.checked) && flag) {
                        return;
                    }
                    if (checkedNum > 0 || partCheckedNum > 0) {	
						//部分勾选
                        that.$set(item, "partchecked", true);
                    } else {	
						//没有勾选
                        that.$set(item, "partchecked", false);
                    }
                }
                recursionList(this.treeData[0]);
            },			
            handleClose(e) {
                if (!$("." + this.elName + "vehicle-tree2").length) {
                    return;
                }
                if (!$("." + this.elName + "vehicle-tree2")[0].contains(e.target)) {
                    this.hideTree();
                }
            },
            hideTree(){
                this.isiTreeShow = false;
                this.searchListShow = false;
                this.iconXialaShow = true;
            }
        }
    };
</script>

<style lang="less">
    .tree-search-container {
        height: 100%;
        position: relative;
        z-index: 1;

        .el-input--suffix .el-input__inner {
            padding-right: 25px;
        }

        .iconsousuo {
            font-size: 14px;
        }
    }

    .vel-tree-container {
        position: relative;
        width: 100%;
        height: 320px;
        overflow: auto;
        box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.1);
    }

    .search-list {
        z-index: 10;
        position: absolute;
        top: 34px;
        width: 100%;
        height: calc(100% - 34px);
        overflow: auto;
        font-size: 14px;
        line-height: 34px;
        box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.1);

        li {
            padding-left: 12px;
            cursor: pointer;
        }
    }

    .no-search-tree-data {
        z-index: 10;
        position: absolute;
        top: 34px;
        width: 100%;
        height: calc(100% - 34px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.1);
    }
</style>
