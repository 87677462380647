<template>
    <div class="tree-search-container" v-clickoutside="handleClose">
        <el-input placeholder="输入关键字进行过滤" 
        v-model="filterText"
        @focus="treeInputFocus"
        @clear="clearInput"  
        clearable @input="toSearch"> 
            <template>
                <i v-if="iconXialaShow" class="input-icon iconfont iconsousuo"
                    slot="suffix"></i>
            </template>
        </el-input>
        <div class="no-search-tree-data" v-if="noSearchTreeData" @click="searchListShow=false">暂无数据</div>
        <ul class="search-list el-select-group__wrap" v-if="(entList.length||vehicleList.length) && searchListShow">
            <template v-if="entList.length>0">
                <li class="el-select-group__title">组织</li>
                <li v-for="item in entList" 
                :key="item[props.label]"
                @click="expendNodeByPathIds(item)">
                    {{ item[props.label] }}
                </li>				
            </template>
            <template v-if="vehicleList.length>0">
                <li class="el-select-group__title">车辆</li>
                <li v-for="item in vehicleList"
                :key="item[props.label]"
                @click="expendNodeByPathIds(item)">
                    {{ item[props.label] }}
                </li>
            </template>
        </ul>	
        <div class="ve-tree">
            <!-- :default-checked-keys="checkedKeys" -->
            <vue-easy-tree :key="cascaderKey" ref="veTree"
            node-key="value" 
            :default-expanded-keys="defaultExpandedkeys" 
            :props="props" 
            :data="treeData"
            :show-checkbox="showCheckbox" 
            :check-strictly="checkStrictly"		
            :expand-on-click-node="false"		
            :load="loadNode" 
            lazy 
            :height="height"
            :highlight-current="true" 
            @check="handleCheck"  
            @node-expand="handleNodeExpand"
            :item-size="24">
            <template slot-scope="{ node, data }">
                <!-- 显示车辆 -->
                <p class="el-tree-node__label" v-if="data.leaf" :id="data.value+'2v3'">{{ node.label }}</p>
                <!-- 显示组织-->
                <p v-else-if="data.value==='-1'" class="el-tree-node__label" :id="data.value+'2v3'"><span class="custom-tree-node">{{ node.label}}</span><span>{{"["+total+"]"}}</span></p>
                <p v-else class="el-tree-node__label" :id="data.value+'2v3'"><span class="custom-tree-node">{{ node.label}}</span><span v-if="countMap.get(data.value)">{{"["+countMap.get(data.value).o9+"]"}}</span></p>
            </template>
        </vue-easy-tree>
        </div>
    </div>
    </template>
    
    <script>
    import VueEasyTree from "@wchbrad/vue-easy-tree";
    export default {
        name: "VehicleTree2V3",
        components: {
            VueEasyTree
        },
        props: {
            height: { // 组织树的高度
                required: false,
                default: '22.5rem' 
            },
            showCheckbox: { // 显示多选框
                required: false,
                default: true           
            },
            cascaderKey: { //整体刷新 :key="cascaderKey";
                required: false,
                default: '000'            
            },
            checkedKeys: { // 选中的key值 
                required: false,
                default: ()=> []           
            },
            partialCheckedKeys: { // 半选的key值 
                required: false,
                default: ()=> []              
            },
            checkStrictly: { //在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false
                type: Boolean,
                default: true
            },
            treeData: {
                required: false,
                default: ()=> []    
            },
            countMap: { // 每个组织下的车辆总数
                type: Map
            }        
        },
        data() {
            return {
                filterText: '',
                props: {
                    label: "label",
                    children: "children",
                    isLeaf: 'leaf'
                },
                defaultExpandedkeys: ['-1'],
                iconXialaShow: true,
                searchListShow: false,
                vehicleList: [],
                entList: [],
                noSearchTreeData: false, // 搜索无数据
                allPartialCheckedKeys: [], // 所有半选，页面传过来的半选，只有当前级别，没有父级
                allCheckedSet: new Set(), // 记录页面中全部选中的节点集合
                allPartialCheckedSet: new Set(),// 记录页面中半选中节点集合
            };
        },
        computed: {
            vehicles(){
                return JSON.parse(JSON.stringify(this.$store.state.vehicles)) || [];
            },
            partialCheckedKeysSet(){
                return new Set(this.partialCheckedKeys);
            },
            checkedKeysSet(){
                return new Set(this.checkedKeys);
            },
            orgData1Object(){
                return JSON.parse(JSON.stringify(this.$store.state.orgData1Object));
            },
            total(){
                let n = 0;
                if(this.treeData && this.treeData.length && this.treeData[0].children && this.treeData[0].children.length){
                    this.treeData[0].children.forEach(item=>{ 
                        console.log(this.countMap.get(item.value));                   
                        item.leaf ? n += 1 : n += this.countMap.get(item.value) ? parseInt(this.countMap.get(item.value).o9) : 0;                    
                    });
                }
                return n;
            }, 
            treeDataTile(){
                let treeDataTile = {};
                function recursion(list) {
                    list.forEach(item => {
                        if (item.children && item.children.length) {
                            recursion(item.children);
                        }
                        treeDataTile[item.value] = item;
                    });                            
                }
                recursion(this.treeData);
                return treeDataTile;
            }    
        },
        mounted(){
            this.defaultExpandedkeys = this.treeData && this.treeData.length?[this.treeData[0].value]:[]; 
        },
        methods: {
            init(){
                const _this = this;
                // 初始半选状态
                this.allPartialCheckedKeys = [];
                this.allCheckedSet.clear();
                this.allPartialCheckedSet.clear();
                this.filterText = '';
                this.searchListShow = false;
                this.vehicleList = [];
                this.entList = [];
                this.noSearchTreeData = false;   
    
                if(this.partialCheckedKeys.length>0){
                    let partialCheckedKeys = [...this.partialCheckedKeys];
                    for(let i=0;i<this.partialCheckedKeys.length;i++){
                        // 父级也得呈现半选状态,页面传过来的半选，只有当前级别，没有父级
                        let node = this.orgData1Object[this.partialCheckedKeys[i]];
                        if(node && node.pathIds){
                            partialCheckedKeys = [...partialCheckedKeys,...node.pathIds];
                        }                    
                    }
                    this.allPartialCheckedKeys = [...new Set(partialCheckedKeys)];
                }
                // 如果是全选状态，子组织是全选，
                // 而父组织在queryOrgVehChoiceRelation这个接口返回的数据，半选集合中没有该父组织的id
                if(this.checkedKeys.length){
                    let pathIds = [];
                    this.checkedKeys.forEach(v=>{
                        let node = this.orgData1Object[v];
                        pathIds = [...pathIds,...(node?node.pathIds:[])];
                    });
                    // 去重遍历
                    [...new Set(pathIds)].forEach(pid=>{
                        // 全选和半选都没有返回该组织的id,则加到半选状态中去
                        if(!this.checkedKeysSet.has(pid)&& !this.partialCheckedKeysSet.has(pid)){
                            this.allPartialCheckedKeys.push(pid);
                        }
                    });                
                }
                this.allPartialCheckedKeys.forEach(v=>{
                    let node = this.$refs.veTree.getNode(v);
                    if(node){
                        node.indeterminate = true;
                    };
                });
                
                this.allCheckedSet = new Set(this.checkedKeys);
                this.allPartialCheckedSet = new Set(this.allPartialCheckedKeys);
                // 初始选中的节点
                this.checkedKeys.forEach(val=>{
                    this.$refs.veTree.setChecked(val, true);
                });            
            },
            handleCheck(currentData, treeStatus) {
                const _this = this;
                if(this.checkStrictly){
                    // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
                    let checked = new Set(treeStatus.checkedKeys).has(currentData.value); 
                    //this.allCheckedSet = new Set(treeStatus.checkedKeys);
                    // 子节点只要被选中父节点就被选中(需要选中父节点时候调用此方法)
                    // 统一处理子节点为相同的勾选状态
                    // 未选中,处理子节点全部未选中
                    checked ? this.allCheckedSet.add(currentData.value) : this.allCheckedSet.delete(currentData.value);
                    this.uniteChildSame(currentData, checked);
                    this.uniteParentSame(currentData, checked);
                    this.formateChecked();
                }else{                
                    this.formateCheckedFalse();
                }
    
            },
            // 统一处理子节点为相同的勾选状态
            uniteChildSame(currentData, isSelected) {
                const _this = this;
                let value = currentData.value||currentData.key;
     
                if(currentData.leaf) return; // 如果是车辆，则不进行以下的操作
                let currentNode = this.$refs.veTree.getNode(value);
                // 当前是组织节点
                if(currentNode && currentNode.expanded){
                    // 如果是展开的，就直接操作childNodes子节点
                    const childNodes = currentNode.childNodes;
                    if(childNodes && childNodes.length){
                        for (let i = 0; i < childNodes.length; i++) {
                            let childNode = childNodes[i];
                            this.$refs.veTree.setChecked(childNode.data.value, isSelected);
                            isSelected? this.allCheckedSet.add(childNode.data.value):this.allCheckedSet.delete(childNode.data.value);
                            // 当前处理的是组织
                            this.uniteChildSame(childNode, isSelected);                                           
                        }
                    }                
                }else{                
                    // 节点还没有展开过
                    // 遍历组织下的直属车辆,在this.allCheckedSet中记录选中的组织和车辆
                    const vehicles = this.vehicles[value];               
                    if(vehicles && vehicles.length){
                        vehicles.forEach((item)=>{
                            isSelected ? _this.allCheckedSet.add(item.value):_this.allCheckedSet.delete(item.value);
                        });
                    }
                    const children = (this.orgData1Object[value] && this.orgData1Object[value].children)||[];
                    if(children && children.length){
                        children.forEach(child=>{
                            isSelected? this.allCheckedSet.add(child.value):this.allCheckedSet.delete(child.value);
                            this.uniteChildSame(child, isSelected);
                        });
                    }                
                }
            },         
            // 统一处理父节点
            uniteParentSame(currentData,checked){
                const _this = this;
                let currentNode = this.$refs.veTree.getNode(currentData);
                // currentNode不存在或者当前选中的是监控中心，直接返回
                if(!currentNode || currentData.value==='-1') return; 
                let parentNode = currentNode.parent;
    
                if(!parentNode) return;
                
                let parentValue = parentNode.data.value;
                // 如果子节点呈选中状态，父节点（包括）以上的节点为都为半选状态
                if(checked){   
                    // parentNode.indeterminate = true;
                    // this.allPartialCheckedSet.add(parentValue);
                    // this.uniteParentSame(parentNode,checked);    
                    if(this.allCheckedSet.has(parentValue)){
                        parentNode.checked = true;
                        this.allCheckedSet.add(parentValue);
                        this.uniteParentSame(parentNode,checked); 
                    }else{
                        parentNode.indeterminate = true;
                        this.allPartialCheckedSet.add(parentValue);
                        this.uniteParentSame(parentNode,checked);   
                    }
                }else{
                    // 如果子节点呈未选中状态，并且树中也没有其它子节点被选中或半选，父节点取消半选状态
                    // 否则父节点呈半选状态
                    let hasChecked = false;
                    for (let i = 0; i < currentNode.parent.childNodes.length; i++) {
                        if (currentNode.parent.childNodes[i].checked || currentNode.parent.childNodes[i].indeterminate) {
                            hasChecked = true;
                            break;
                        }
                    }
                    // 先取消全选状态，再设置半选状态
                    this.$refs.veTree.setChecked(parentNode, false);
                    parentNode.indeterminate = hasChecked;
                    // 半选集合中加入或删除父节点
                    hasChecked?this.allPartialCheckedSet.add(parentValue):this.allPartialCheckedSet.delete(parentValue);
                    this.uniteParentSame(parentNode,checked);                 
                }
                // 移除父节点在allCheckedSet的选中状态
                this.allCheckedSet.delete(parentValue);
    
            },
            formateChecked(){
                const _this = this;
                // 此次选中的集合
                let checkedNodes = new Set(this.$refs.veTree.getCheckedNodes());
    
                let halfCheckedNodes = this.$refs.veTree.getHalfCheckedNodes();
                let halfCheckedKeys = this.$refs.veTree.getHalfCheckedKeys();
                // 父节点半选状态，但是它下面的子节点有选中的状态，父节点在未展开的时候，
                // this.$refs.veTree.getCheckedKeys这个方法捕获不到它下面被选中的子节点，
                // recursion就是获取父节点是半选时，获取到它下面选中的子节点
                if(halfCheckedNodes.length){
                    if(halfCheckedKeys.indexOf('-1')>-1) halfCheckedNodes.splice(0,1);
                    recursion(halfCheckedNodes);
                }
                function recursion(list) {
                    list.forEach(item => {                    
                        if(_this.allCheckedSet.has(item.value)){
                            checkedNodes.add(item);
                        }
                        
                        let vehicles = _this.vehicles[item.value]||[];
                        if (item.children && item.children.length) {
                            // 组织下所有的子组织和车辆
                            let arr = [...item.children,...vehicles];
                            recursion(arr);
                        }else{
                            // 组织下所有的车辆
                            recursion(vehicles);
                        }                   
                    });                            
                }     
    
                this.allCheckedSet.has('-1') && this.allCheckedSet.delete('-1');
                this.$emit('check',{
                    checkedKeys: [...this.allCheckedSet]
                });              
            },  
            formateCheckedFalse(){
                const _this = this;
                // 此次选中的集合
                let checkedNodes = new Set(this.$refs.veTree.getCheckedNodes());
    
                let halfCheckedNodes = this.$refs.veTree.getHalfCheckedNodes();
                let halfCheckedKeys = this.$refs.veTree.getHalfCheckedKeys();
                // 父节点半选状态，但是它下面的子节点有选中的状态，父节点在未展开的时候，
                // this.$refs.veTree.getCheckedKeys这个方法捕获不到它下面被选中的子节点，
                // recursion就是获取父节点是半选时，获取到它下面选中的子节点
                if(halfCheckedNodes.length){
                    if(halfCheckedKeys.indexOf('-1')>-1) halfCheckedNodes.splice(0,1);
                    recursion(halfCheckedNodes);
                }
                function recursion(list) {
                    list.forEach(item => {                    
                        if(_this.allCheckedSet.has(item.value)){
                            checkedNodes.add(item);
                        }
                        
                        let vehicles = _this.vehicles[item.value]||[];
                        if (item.children && item.children.length) {
                            // 组织下所有的子组织和车辆
                            let arr = [...item.children,...vehicles];
                            recursion(arr);
                        }else{
                            // 组织下所有的车辆
                            recursion(vehicles);
                        }                   
                    });                            
                }     
    
                this.allCheckedSet.has('-1') && this.allCheckedSet.delete('-1');
                this.$emit('check',{
                    checkedKeys: [...this.allCheckedSet]
                });              
            },              
            handleNodeExpand(currentData, node) {
                this.setChildNodeChecked(node);
            },
            loadNode(node, resolve) {
                const _this = this;
                if (node.level === 0) {
                    //保存初始结点信息 用来以后刷新整棵树
                    _this.setChildNodeChecked(node);
                    return resolve(this.treeData);
                }
                if (node.level === 1 && node.data.value==='-1') {
                    _this.setChildNodeChecked(node);
                    return resolve(this.treeData[0].children);
                }
                //不是根节点 加载下级节点
                let vehicles = this.vehicles[node.data.value] || [];
                // 过滤出其中的组织节点，然后直接和vehicles合并
                if (node.data.children && node.data.children.length) {                
                    return resolve([...node.data.children, ...vehicles]); 
                }else{
                    // 组织下没有组织，只有车辆节点时
                    return resolve([...vehicles]);   
                }
            },
            toSearch(){
                const _this = this;
                this.entList = [];
                this.vehicleList = [];
                this.searchListShow = true;
                let entSet= new Set(),vehicleSet = new Set();
                if(this.filterText){
                    // 所有组织，this.orgData1Object，
                    // 筛选组织
                    for(let key in _this.treeDataTile){
                        let org = _this.treeDataTile[key];
                        if(org.label && org.label.toLowerCase().includes(_this.filterText.toLowerCase())){
                            org.dataType = 2;
                            entSet.add(org);
                        }
                        // 组织下的所有的车辆
                        let vehicles = _this.vehicles[org.value];
                        if(vehicles){
                            for (let i = 0; i < vehicles.length; i++) {
                                // 如果找到了100条记录就停止
                                if(vehicleSet.size>=100) break;
                                let vehicle = vehicles[i];
                                // 记录该车对应的所有的父级组织及上线的id
                                vehicle.pathIds = [...org.pathIds,...[vehicle.pid]];
                                vehicle.dataType = 1;
                                if(vehicle.label.toLowerCase().includes(_this.filterText.toLowerCase())){
                                    vehicleSet.add(vehicle);
                                }     
                            }                        
                        }
                    }
                    this.entList = [...entSet];
                    this.vehicleList = [...vehicleSet];
                    this.noSearchTreeData = this.entList.length+this.vehicleList.length==0?true:false;
                }else{
                    this.searchListShow = false;
                    this.noSearchTreeData = false;
                }
            },
            // 子节点根据父节点是否选中，设置子节点的状态
            setChildNodeChecked(node){
                const _this = this;
                node.childNodes && node.childNodes.forEach(cell => {
                    cell.checked = node.checked || _this.allCheckedSet.has(cell.data.value);
                    // 如果是选中的，则加到集合中,否则从选中集合中删除
                    cell.checked?_this.allCheckedSet.add(cell.data.value):_this.allCheckedSet.delete(cell.data.value);
                    cell.indeterminate = _this.allPartialCheckedSet.has(cell.data.value);
                });           
            },
            expendNodeByPathIds(item,renderTree=true){
                // renderTree 需要重新组装数据 
                const _this = this;
                let pathIds = item.pathIds;    
                // 不显示搜索列表
                this.searchListShow = false;
                // 从搜索列表中点击的节点，节点呈选中状态,加入到选中集合        
                this.allCheckedSet.add(item.value);
                _this.$refs.veTree.setChecked(item.value, true);
                let currentDom = document.getElementById(item.value+'2v3');
                // 选中节点在可视范围内
                if(currentDom){
                    currentDom.scrollIntoView({block: "start"}); 
                    this.$refs.veTree.setCurrentKey(item.value);
                    renderTree && this.formateChecked(); 
                    return;
                }
    
                // 折叠除根节点之外所有节点
                for (var i = 0; i < this.$refs.veTree.store._getAllNodes().length; i++) {
                    let node = _this.$refs.veTree.store._getAllNodes()[i];
                    node.expanded = node.data.value=='-1'? true:false;
                }   
    
                // 先让滚动条回到顶部
                const vueRecycleScroller = _this.$refs.veTree.$el.querySelector('.vue-recycle-scroller');
                vueRecycleScroller.scrollTop = 0;
                let count = 0;
    
                for(let i=0;i<pathIds.length;i++){                
                    // 展开所有父级
                    let parentNode = _this.$refs.veTree.getNode(pathIds[i]);
                    if(!parentNode) continue;
                    let parentValue = parentNode.data.value;
                    // 全选组织中是否有parentValue，
                    if(_this.allCheckedSet.has(parentValue)){
                        parentNode.checked = true;
                        _this.allCheckedSet.add(parentValue);
                    }else{
                        parentNode.indeterminate = true;
                        _this.allPartialCheckedSet.add(parentValue); 
                    }
                    parentNode && parentNode.expand(()=>{
                        // 设置子元素的选中状态
                        _this.setChildNodeChecked(parentNode);
                        _this.$nextTick(() => {
                            let childNodes = parentNode.childNodes;
                            // 计算一共循环过多少次
                            for (let m = 0; m < childNodes.length; m++) {
                                let childData = childNodes[m].data;
                                var c = i == pathIds.length - 1 ? item.value : pathIds[i + 1];
                                if (childData.value == c) {
                                    count += m;
                                    break;
                                }
                            }
                            vueRecycleScroller.scrollTop = count*24;
                            //遍历到最后一级进行选中和定位
                            if(i==pathIds.length-1){
                                _this.$refs.veTree.setChecked(item.value, true);
                                _this.$refs.veTree.setCurrentKey(item.value);
                                renderTree && _this.formateChecked();
                            }
                        });                     
                    });
                
                }            
            },
            expandNodes(node) {
                if (null != node.parent) {
                    this.expandNodes(node.parent);
                }
                node.expanded = true;
            },
            treeInputFocus() {
                this.searchListShow = true;
            },
            clearInput(){
                this.filterText = '';
                this.searchListShow = false;
                this.vehicleList= [];
                this.entList= [];          
            },
            handleClose(e) {
                this.searchListShow = false;
                this.iconXialaShow = true;
            }   
    
        }
    };
    </script>
    
    <style lang="less" scoped>
    .tree-search-container {
        position: relative;
        z-index: 1;
        overflow: hidden;
        height: 485px;
        display: flex;
        flex-flow: column;
        .el-input, .el-input__inner{
            height: 34px;
            line-height: 34px;
        }   
        .ve-tree{
            flex: 1;
            .el-tree{
                width: 100%;
                overflow: auto;
                height: 100%;
            }        
        }
        .input-icon {
            font-size: 12px;
            padding-right: 5px;
        }
        .el-input__suffix {
            line-height: 1.7rem;
        }
        .el-input__inner {
            height: 34px;
            line-height: 34px;
            padding-right: 24px;
        }
    
        .tree-item-checkbox {
            color: #ccc;
        }
    
        .iconCheckboxChecked-1-copy, .iconindeterminatecheckbox-copy {
            color: #409eff;
        }
    
        .vue-recycle-scroller{
            padding-bottom: 10px;
        }
        
    }
    
    </style>
    