<template>
    <ul class="recursion-tree" @click.capture='clickNodeWrap'>
        <li v-for="(item, index) in treeList" class="tree-item-container" :key="index">
            <div
                    class="tree-item"
                    :class="item.active?'tree-item-active':''"
                    :style="{paddingLeft: treeIndex + 0.1 + 'rem'}"
                    :id="'tree5'+item[treeProps.value]"
                    data-type="tree-item"
                    :data-index="index"
                    @click.capture="(e) => {clickNode(e, item)}"
                    @dblclick.stop="toggleNode(item)"
            >
                <span
                        v-if="item.children"
                        class="tree-expand iconfont"
                        :class="item.expand?iconList.pullDownIcon:iconList.pullUpIcon"
                        data-type="tree-extend"
                        :data-index="index"
                ></span>
                <span
                        v-if="item.checkBox !== null && item.checkBox !== undefined ? item.checkBox : treeProps.checkBox"
                        class="tree-item-checkbox iconfont"
                        :class="item.checked?'iconziyuan':item.partchecked?'iconbufenxuanzhong-copy':'iconziyuan1'"
                        data-type="tree-check"
                        :data-index="index"
                ></span><!-- 复选框 -->
                <span
                        class="tree-item-text"
                        :class=" [item.className, {
                            'fence-shape': item.type && typeof item.type == 'string' ? item.type.indexOf('fenceShape') != -1:false
                        }]"
                        :title="item[treeProps.label]"
                        :id="item.type"
                        :style="{color: item.color}"
                >
                    <i
                            v-if="item.type == 2"
                            style="padding-left: 0.1rem"
                            class="iconfont"
                            :class="'icon' + item.icon"
                    ></i>
					<template v-if="item.type == 2">
						<!-- 车辆信息 -->
    					<!-- 自编号和车牌号都没有选的情况下，显示车牌号 -->
                        <span v-if="treeParams.vehicleNo||(!treeParams.vehicleNo&&!treeParams.explains)" class="custom-tree-node">{{ item[treeProps.label] }}</span>
						<span v-if="treeParams.explains && item.exp" class="ml3">[{{item.exp}}]</span>
						<template v-if="pageType == 'realtimeMonitor' || pageType == 'trackPlay'">
							<span v-if="treeParams.state && item.vehicleStatus!=0" class="ml3">[{{item.status}}]</span>
							<!-- <span v-if="item.vehicleStatus == 0 && item.offlineTime && treeParams.offTimes" class="offline-time ml3">[{{"离线: "+$utils.getDuration2(item.offlineTime)}}]</span>
							<span v-if="treeParams.eType && item.vt" class="ml3" :class="vts[item.vt]" style="font-size: 16px;"></span> -->
                            <template v-if="item.vehicleStatus == 0 && (treeParams.area||treeParams.state||treeParams.offTimes)">
                                <span class="offline-time ml3">[{{ treeParams.state?'离线':'' }}{{treeParams.offTimes?'&nbsp;'+$utils.getDuration2(item.offlineTime):''}}{{ (treeParams.area&&item.area)?'&nbsp;'+item.area:'' }}]</span>
                            </template>
                            <span v-if="treeParams.eType && item.vt" class="ml3" :class="vts[item.vt]" style="font-size: 16px;"></span>
						</template>
					</template>
					<template v-else>
						{{item[treeProps.label]}}
						<span class="tree-item-num">{{item.o9!=null&&item.o7!=null?"["+item.o9+"/"+item.o7+"]":""}}</span>
					</template>	
                </span>

            </div>
            <VehicleRecursionTree
                    v-if="item.children"
                    v-show="item.expand"
                    :treeList="item[treeProps.children]"
                    :treeProps="treeProps"
                    :iconList="iconList"
                    :tree-index="treeIndex + 1"
                    :pageType="pageType"
                    @treeClick="treeClick"
                    @treeChoose="treeChoose"
                    @treeExtend="treeExtend"
            />
        </li>
    </ul>
</template>

<script>
    var timer = null;
    export default {
        name: "VehicleRecursionTree",
        props: ["treeList", "treeProps", "treeIndex", "pageType", "iconList"], 
		data(){
            let params = localStorage.getItem('treeParamsShower');
			return {
				vts: {
					'2': 'el-icon-location-outline',
					'4': 'el-icon-video-camera'
				},
                treeParams: params!='undefined'? {...JSON.parse(params)}:JSON.parse(JSON.stringify(this.$store.state.treeParamsShower)),
			}
		},
        created(){
            // 监听localhostStorage的数据变化
            window.addEventListener('setItem', ()=> {
                let newVal = localStorage.getItem('treeParamsShower');
                this.treeParams = {...JSON.parse(newVal)};
            });            
        },
        methods: {
            clickNode(e, item) {
                let that = this;
                let type = e.target.dataset.type;
                if (type == "tree-check") {
                    if (this.clickTimer) {
                        clearTimeout(this.clickTimer);
                        this.clickTimer = null;
                        return;
                    }
                    this.clickTimer = setTimeout(() => {
                        item.active = true;
                        that.checkBoxClick(item);
                        this.clickTimer = null;
                    }, 250);

                } else if (type == "tree-extend") {
                    this.toggleNode(item);
                } else {
                    // 单击行
                    clearTimeout(timer);
                    that.$set(item, "active", true);
                    timer = setTimeout(() => {
                        that.$emit("treeClick", "itemClick", item);
                    }, 300);
                }
            },
            toggleNode(item) {
                if (item.type == 2) {
                    return;
                }
                // 双击行或展开行
                clearTimeout(timer);
                this.$set(item, "active", true);
                // item.active = true;
                this.$set(item, "expand", !item.expand);
                this.$emit("treeExtend", item);
            },
            clickNodeWrap() {
                // 选中行高亮
                var that = this;
                var modifyDataFun = function (datas) {
                    if (datas) {
                        datas.forEach((m) => {
                            that.$set(m, "active", false);
                            if (m[that.treeProps.children]) {
                                modifyDataFun(m[that.treeProps.children]);
                            }
                        });
                    }
                };
                modifyDataFun(this.treeList);
            },
            checkBoxClick(item, flag) {
                if (item.type == 1) {
                    if (item.o7 && item.o7 > 20000) {
                        this.$toast("单次最多选择20000辆车");
                        return;
                    }
                }
                var that = this;
                if (flag) {
                    if (item.checked) {
                        return;
                    }
                    that.$set(item, "checked", true);
                } else {
                    that.$set(item, "checked", !item.checked);
                }
                //设置子元素是否勾选
                var checkChildFun = (childrenDatas) => {
                    childrenDatas.forEach((m) => {
                        that.$set(m, "checked", item.checked);
                        if (m.children) {
                            checkChildFun(m.children);
                        }
                    });
                };
                if (item.children) {
                    checkChildFun(item.children);
                }
                this.$emit("treeChoose", item);
            },
            treeClick(handler, item) {
                this.$emit("treeClick", handler, item);
            },
            treeChoose(item) {
                this.$emit("treeChoose", item);
            },
            treeExtend(item) {
                this.$emit("treeExtend", item);
            }
        },
    };
</script>

<style scoped lang="less">
    .recursion-tree {
        cursor: pointer;
		.ml3{margin-left: 3px;}
        .tree-item {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 14px;
            width: 100%;
            line-height: 20px;
            height: 30px;
            padding-right: 5px;
        }

        .tree-expand, .tree-item-checkbox {
            font-size: 18px;
            color: #1890FF;
        }

        .tree-item-active {
            background: url("../../assets/images/monitorManage/tree-item-back.png") no-repeat;
            background-size: 100% 100%;
        }

        .tree-item-text {
            margin-right: 5px;
        }

        .offline-time {
            opacity: 0.8;
        }

        .tree-item-num {
            opacity: 0.8;
        }
    }
</style>
