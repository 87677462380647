export default [{
    path: "/dataManage",
    name: "dataManage",
    redirect: "/speedalarm",
    component: () =>
        import ("@/views/DataManage/Index.vue"),
    meta: {
        menuId: "data_center_alarmquery_detail"
    },
    children: [
        {
            path: "/speedalarmmonth",
            name: "speedalarmmonth",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_speed_alarm_month"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/SpeedAlarmMonth.vue")
        }, {
            path: "/fatiguedrivemonth",
            name: "fatiguedrivemonth",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_overtime_alarm_month"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/FatigueDriveMonth.vue")
        }, {
            path: "/speeddayalarm",
            name: "speeddayalarm",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_speed_alarm"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/SpeedDayAlarm.vue")
        }, {
            path: "/fatiguedaydriver",
            name: "fatiguedaydriver",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_fatigue_driver"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/FatigueDayDriver.vue")
        }, {
            path: "/speedalarm",
            name: "speedalarm",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmstat_speed_alarm"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/SpeedAlarm.vue")
        }, {
            path: "/fatiguedriver",
            name: "fatiguedriver",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmstat_overtime_alarm"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/FatigueDriver.vue")
        }, {
            path: "/thirdparty",
            name: "thirdparty",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmstat_thirdparty"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/thirdPartyAlarm.vue")
        }, {
            path: "/guangdongalarm",
            name: "guangdongalarm",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmstat_guangdongalarm"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/GuangdongAlarm.vue")
        }, {
            path: "/alarmdetail",
            name: "alarmdetail",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmquery_detail"
            },
            component: () =>
                import ("@/views/DataManage/alarmQuery/AlarmDetail/index.vue")
        }, {
            path: "/alarmaeb",
            name: "alarmaeb",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmquery_aebdetail"
            },
            component: () =>
                import ("@/views/DataManage/alarmQuery/AlarmAeb/index.vue")
        }, {
            path: "/vehofflinewarn",
            name: "vehofflinewarn",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmquery_offlineveh"
            },
            component: () =>
                import ("@/views/DataManage/alarmQuery/VehicleOfflineWarn/index.vue")
        }, {
            path: "/firmofflinewarn",
            name: "firmofflinewarn",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmquery_offlineplat"
            },
            component: () =>
                import ("@/views/DataManage/alarmQuery/FirmOfflineWarn/index.vue")
        }, {
            path: "/activeSecalarm",
            name: "activeSecalarm",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmquery_asdetail"
            },
            component: () =>
                import ("@/views/DataManage/alarmQuery/ActiveSecurityAlarm/index.vue")
        }, {
            path: "/drivercardrecord",
            name: "drivercardrecord",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_driver_card_record"
            },
            component: () =>
                import ("@/views/DataManage/driverDetail/DriverCardRecord/index.vue")
        }, {
            path: "/alarmday",
            name: "alarmday",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmstat_day"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/AlarmDay.vue")
        },
        {
            path: "/vehicleonline",
            name: "vehicleonline",
            meta: {
                parentId: "dataManage",
                menuId: 'data_center_supassess_onlinerate'
            },
            component: () =>
                import ("@/views/DataManage/superviseAssess/VehicleOnlineRate/index.vue")
        },
        {
            path: "/comVehicleonline",
            name: "comVehicleonline",
            meta: {
                parentId: "dataManage",
                menuId: 'data_center_state_entmonthonline'
            },
            component: () =>
                import ("@/views/DataManage/superviseAssess/ComVehicleOnline.vue")
        },
        {
            path: "/trackrate",
            name: "trackrate",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_supassess_trailrate"
            },
            component: () =>
                import ("@/views/DataManage/superviseAssess/TrackRate/index.vue")
        },
        {
            path: "/accessrate",
            name: "accessrate",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_supassess_accessrate"
            },
            component: () =>
                import ("@/views/DataManage/superviseAssess/AccessRate.vue")
        },
        {
            path: "/datapassrate",
            name: "datapassrate",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_supassess_datapassrate"
            },
            component: () =>
                import ("@/views/DataManage/superviseAssess/DataPassRate.vue")
        },
        {
            path: "/connectrate",
            name: "connectrate",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_supassess_connectrate"
            },
            component: () =>
                import ("@/views/DataManage/superviseAssess/ConnectRate.vue")
        },
        {
            path: "/driftrate",
            name: "driftrate",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_supassess_driftrate"
            },
            component: () =>
                import ("@/views/DataManage/superviseAssess/DriftRate.vue")
        }, {
            path: "/mileageDaily",
            name: "mileageDaily",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_mileage_dayrep"
            },
            component: () =>
                import ("@/views/DataManage/MileageReport/MileageDaily.vue")
        }, {
            path: "/mileageMonthly",
            name: "mileageMonthly",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_mileage_monthrep"
            },
            component: () =>
                import ("@/views/DataManage/MileageReport/MileageMonthly.vue")
        }, {
            path: "/mileageHarvester",
            name: "mileageHarvester",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_mileage_harvester"
            },
            component: () =>
                import ("@/views/DataManage/MileageReport/MileageHarvester.vue")
        }, {
            path: "/vehicleonoffdetail",
            name: "vehicleonoffdetail",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_state_updownlinedetail"
            },
            component: () =>
                import ("@/views/DataManage/statusDetails/VehicleOnOffDetail/index.vue")
        }, {
            path: "/offlinevehiclelist",
            name: "offlinevehiclelist",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_state_vehicleofflinequery"
            },
            component: () =>
                import ("@/views/DataManage/statusDetails/offlineVehicleList/index.vue")
        }, {
            path: "/vehicleonoffstatic",
            name: "vehicleonoffstatic",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_state_updownline"
            },
            component: () =>
                import ("@/views/DataManage/statusDetails/VehicleOnOffStatic/index.vue")
        }, {
            path: "/companymonthstatic",
            name: "companymonthstatic",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_state_entmonthonline"
            },
            component: () =>
                import ("@/views/DataManage/statusDetails/CompanyMonthStatic/index.vue")
        }, {
            path: "/vehiclemonthstatic",
            name: "vehiclemonthstatic",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_state_vehmonthonline"
            },
            component: () =>
                import ("@/views/DataManage/statusDetails/VehicleMonthStatic/index.vue")
        }, {
            path: "/vehicleofflinestatic",
            name: "vehicleofflinestatic",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_state_noposition"
            },
            component: () =>
                import ("@/views/DataManage/statusDetails/VehicleOfflineStatic/index.vue")
        }, {
            path: "/drivingstatic",
            name: "drivingstatic",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_travel_drivedetail"
            },
            component: () =>
                import ("@/views/DataManage/drivingDetail/DrivingStatic/index.vue")
        }, {
            path: "/parkingstatic",
            name: "parkingstatic",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_travel_parkingdetail"
            },
            component: () =>
                import ("@/views/DataManage/drivingDetail/ParkingStatic/index.vue")
        }, {
            path: "/driveCollect",
            name: "driveCollect",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_travel_driving"
            },
            component: () =>
                import ("@/views/DataManage/drivingDetail/DriveCollect.vue")
        }, {
            path: "/parkCollect",
            name: "parkCollect",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_travel_parking"
            },
            component: () =>
                import ("@/views/DataManage/drivingDetail/ParkCollect.vue")
        }, {
            path: "/vehicleTrack",
            name: "vehicleTrack",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_positioning_locus"
            },
            component: () =>
                import ("@/views/DataManage/LocationReport/VehicleTrack.vue")
        }, {
            path: "/offlineStatistics",
            name: "offlineStatistics",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_positioning_offline"
            },
            component: () =>
                import ("@/views/DataManage/LocationReport/OfflineStatistics.vue")
        }, {
            path: "/inspectionNotice",
            name: "inspectionNotice",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_regulatory_chagang"
            },
            component: () =>
                import ("@/views/DataManage/OtherReport/InspectionNotice.vue")
        }, {
            path: "/activeSafe",
            name: "activeSafe",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_multimedia_activeSafe"
            },
            component: () =>
                import ("@/views/DataManage/MultimediaQuery/ActiveSafe.vue")
        }, {
            path: "/instructIssue",
            name: "instructIssue",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_multimedia_instruct"
            },
            component: () =>
                import ("@/views/DataManage/MultimediaQuery/InstructIssue.vue")
        }, {
            path: "/entRisk",
            name: "entRisk",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_analysis_ent"
            },
            component: () =>
                import ("@/views/DataManage/AnalyzeReport/EntRisk.vue")
        }, {
            path: "/driverRisk",
            name: "driverRisk",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_analysis_driver"
            },
            component: () =>
                import ("@/views/DataManage/AnalyzeReport/DriverRisk.vue")
        }, {
            path: "/vehicleRisk",
            name: "vehicleRisk",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_analysis_vehicle"
            },
            component: () =>
                import ("@/views/DataManage/AnalyzeReport/VehicleRisk.vue")
        }, {
            path: "/safeWebRisk",
            name: "safeWebRisk",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_analysis_vehicle"
            },
            component: () =>
                import ("@/views/DataManage/AnalyzeReport/SafeWebRisk.vue")
        }, {
            path: "/smsissued",
            name: "smsissued",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_issuerecord_notice"
            },
            component: () =>
                import ("@/views/DataManage/issuedQuery/SmsIssued.vue")
        }, {
            path: "/companyMonthReport",
            name: "companyMonthReport",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_companyquery_monthreport"
            },
            component: () =>
                import ("@/views/DataManage/companyQuery/CompanyMonthReport.vue")
        }, {
            path: "/companyMonthAlarm",
            name: "companyMonthAlarm",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_companyquery_monthalarm"
            },
            component: () =>
                import ("@/views/DataManage/companyQuery/CompanyMonthAlarm.vue")
        }, {
            path: "/handover", //交班记录表
            name: "handover",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_handover"
            },
            component: () =>
                import ("@/views/DataManage/queryPlaceAccount/handover/index.vue")
        }, {
            path: "/staticByMonth", //车辆月报警统计
            name: "staticByMonth",
            meta: {
                parentId: "dataManage",
                menuId: "data_center_alarmstat_staticByMonth"
            },
            component: () =>
                import ("@/views/DataManage/alarmDetails/staticByMonth.vue")
        }
    ]
}];