<template>
    <ul class="recursion-tree" @click.capture='clickNodeWrap'>
        <li v-for="(item, index) in treeList" class="tree-item-container" :key="index">
            <div
                    class="tree-item"
                    :class="item.active?'tree-item-active':''"
                    :style="{paddingLeft: treeIndex + 0.1 + 'rem'}"
                    :id="'tree5'+item[treeProps.value]"
                    data-type="tree-item"
                    :data-index="index"
                    @click.capture="(e) => {clickNode(e, item)}"
                    @dblclick.stop="toggleNode(item)"
            >
                <span
                        v-if="item.extend != null"
                        class="tree-expand iconfont"
                        :class="item.expand?iconList.pullDownIcon:iconList.pullUpIcon"
                        data-type="tree-extend"
                        :data-index="index"
                ></span>
                <span
                        v-if="treeProps.checkBox"
                        class="tree-item-checkbox iconfont"
                        :class="item.checked?'iconziyuan':item.partchecked?'iconbufenxuanzhong-copy':'iconziyuan1'"
                        data-type="tree-check"
                        :data-index="index"
                ></span>
                <span
                        class="tree-item-text"
                        :class="[{
                            'online-vehicle':item.vehicleStatus == 1,
                            'travel-vehicle':item.vehicleStatus == 2,
                            'fence-shape': item.type ? item.type.indexOf('fenceShape') != -1:false
                        }]"
                        :title="item[treeProps.label]"
                        :id="item.type"
                >
                    <i
                            v-if="pageType == 'realtimeMonitor' && item.type == 'vehicle'"
                            style="padding-left: 0.1rem"
                            class="iconfont"
                            :class="item.vehicleStatus == 1?'icontingchechang1-copy':item.vehicleStatus == 2?'icondaohang':'iconlixianim'"
                    ></i>
                    {{item[treeProps.label]}}
                </span>
                <template v-if="pageType == 'realtimeMonitor'">
                    <span v-if="item.vehicleStatus == 0 && item.offlineTime == ''" class="offline-time">从未上线</span>
                    <span v-if="item.vehicleStatus == 0 && item.offlineTime" class="offline-time">{{"离线: " + $utils.getDuration2(item.offlineTime)}}</span>
                    <span class="tree-item-num">{{item.o9!=null&&item.o7!=null?"["+item.o9+"/"+item.o7+"]":""}}</span>
                </template>
            </div>
            <SAASRecursionTree
                v-if="item.type!='vehicle' && item.extend && item[treeProps.children] && item[treeProps.children].length"
                v-show="item.expand"
                :treeList="item[treeProps.children]"
                :treeProps="treeProps"
                :iconList="iconList"
                :tree-index="treeIndex + 1"
                :pageType="pageType"
                @toggleCheckBox="toggleCheckBox(item, index)"
                @treeClick="treeClick"
                @treeChoose="treeChoose"
            />
        </li>
    </ul>
</template>

<script>
    var timer = null;
    export default {
        name: "SAASRecursionTree",
        props: ["treeList", "treeProps", "treeIndex", "pageType", "iconList"],
        methods: {
            clickNode(e, item) {
                let that = this;
                let type = e.target.dataset.type;
                if (type == "tree-check") {
                    if (this.clickTimer) {
                        clearTimeout(this.clickTimer);
                        this.clickTimer = null;
                        return;
                    }
                    this.clickTimer = setTimeout(() => {
                        item.active = true;
                        that.checkBoxClick(item);
                        this.clickTimer = null;
                    }, 250);

                } else if (type == "tree-extend") {
                    this.toggleNode(item);
                } else {
                    // 单击行
                    clearTimeout(timer);
                    that.$set(item, "active", true);
                    timer = setTimeout(() => {
                        that.$emit("treeClick", "itemClick", item);
                    }, 300);
                }
            },
            toggleNode(item) {
                if (item.type == "vehicle" || item.type.indexOf("fenceShape") != -1) {
                    return;
                }
                // 双击行或展开行
                clearTimeout(timer);
                item.active = true;
                this.$set(item, "expand", !item.expand);
                this.$set(item, "extend", true);
            },
            clickNodeWrap() {
                // 选中行高亮
                var that = this;
                var modifyDataFun = function (datas) {
                    if (datas) {
                        datas.forEach((m) => {
                            that.$set(m, "active", false);
                            if (m[that.treeProps.children]) {
                                modifyDataFun(m[that.treeProps.children]);
                            }
                        });
                    }
                };
                modifyDataFun(this.treeList);
            },
            searchListCheck(item) {
                var self = this;
                if (item.checked) {
                    return;
                }
                item.checked = true;
                this.$forceUpdate();
                //设置子元素是否勾选
                var checkChildFun = (childrenDatas) => {
                    childrenDatas.forEach((m) => {
                        self.$set(m, "checked", item.checked);
                        if (m.children) {
                            checkChildFun(m.children);
                        }
                    });
                };
                if (item.children) {
                    checkChildFun(item.children);
                }
                this.$emit("toggleCheckBox", item);
                this.$emit("treeChoose", item);
            },
            checkBoxClick(item) {
                if (item.o7 && item.o7 > 20000) {
                    this.$toast("单次最多选择20000辆车");
                    return;
                }
                var that = this;
                that.$set(item, "checked", !item.checked);
                //设置子元素是否勾选
                var checkChildFun = (childrenDatas) => {
                    childrenDatas.forEach((m) => {
                        that.$set(m, "checked", item.checked);
                        if (m.children) {
                            checkChildFun(m.children);
                        }
                    });
                };
                if (item.children) {
                    checkChildFun(item.children);
                }
                this.$emit("toggleCheckBox", item);
                this.$emit("treeChoose", item);
            },
            treeClick(handler, item) {
                this.$emit("treeClick", handler, item);
            },
            treeChoose(item) {
                this.$emit("treeChoose", item);
            },
            toggleCheckBox(item, index) {
                // 递归调用父组件方法
                this.$emit("toggleCheckBox", item);
            }
        }
    };
</script>

<style scoped lang="less">
    .recursion-tree {
        cursor: pointer;

        .tree-item {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 14px;
            width: 100%;
            line-height: 20px;
            height: 30px;
            padding-right: 5px;
        }

        .tree-expand, .tree-item-checkbox {
            font-size: 18px;
            color: #1890FF;
        }

        .tree-item-active {
            background: url("../../assets/images/monitorManage/tree-item-back.png") no-repeat;
            background-size: 100% 100%;
        }

        .tree-item-text {
            margin-right: 5px;
        }

        .offline-time {
            opacity: 0.8;
        }

        .tree-item-num {
            opacity: 0.8;
        }
    }
</style>
