import axios from "axios";
const service = axios.create({
	baseURL: "https://restapi.amap.com/v3", // api的base_url
	timeout: 30000 // 请求超时时间
});

// respone拦截器
service.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		return Promise.reject(error);
	}
);

export default service;