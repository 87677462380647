// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/largeScreen/model-content-back.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/largeScreen/btn-back.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".jg-plat-dialog .jg-plat-content{align-items:center}.jg-plat-dialog .jg-plat-content input{height:1.2rem;width:10rem;border:.05rem solid #ddd;padding:0 .2rem;text-align:center;font-size:.7rem}.large-screen-jgPlat .el-dialog__body,.large-screen-jgPlat .el-dialog__close,.large-screen-jgPlat .el-dialog__title,.large-screen-jgPlat .el-form-item__label{color:#fff!important}.large-screen-jgPlat .el-input__inner{border:.05rem solid #18c3ff;background:transparent;box-shadow:inset 0 0 .9rem .05rem rgba(25,133,239,.7);color:#fff}.large-screen-jgPlat .el-dialog{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.large-screen-jgPlat .el-dialog .el-button{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100% 100%;border:none;color:#fff;width:auto!important}.large-screen-jgPlat .jg-plat-content input{border:.05rem solid #21ecf6;background:hsla(0,0%,100%,.05);color:#fff}.monitor-manage-jgPlat input{background:#26326d;border:none!important;border-radius:0;color:hsla(0,0%,100%,.7)}.monitor-manage-jgPlat .el-dialog{background:#18214d;border:.05rem solid #0232a1}.monitor-manage-jgPlat .el-dialog .el-dialog__body,.monitor-manage-jgPlat .el-dialog .el-dialog__close,.monitor-manage-jgPlat .el-dialog .el-dialog__title,.monitor-manage-jgPlat .el-dialog .el-form-item__label{color:hsla(0,0%,100%,.7)}.monitor-manage-jgPlat .el-dialog .el-dialog__header{background:#26326d;height:2.2rem;line-height:2.2rem;padding:0 .8rem;font-size:.7rem}.monitor-manage-jgPlat .el-dialog .el-dialog__footer .el-button{background:transparent;border:.05rem solid hsla(0,0%,100%,.1);color:hsla(0,0%,100%,.5)}.monitor-manage-jgPlat .el-dialog .el-dialog__footer .el-button:last-child{background:#3b4ca1}", ""]);
// Exports
module.exports = exports;
