import DataLoader from 'dataloader';
import { chunk, trim } from 'lodash';
import request from './request';
//lngAndLats[]: { x: number; y: number }[]
export function rgeo(lngAndLats) {
  return request.post('vehicle-service/location/address/rgeo', { lngAndLats });
}

export const sf_regeo_address_loader = new DataLoader(async lnglats => {
    lnglats = lnglats.map(v => trim(v, '|'));
    const chunked = chunk([...new Set(lnglats)], 50);
    const ress = await Promise.allSettled(
      chunked.map(chunk => rgeo(chunk.map(it => it.split(',').map(parseFloat)).map(([x, y]) => ({ x, y }))))
    );
  
    const obj = {};
    ress.forEach((r, idx) => {
      if (r.status === 'rejected') return;
      (r.value?.result || []).forEach((regeo, jdx) => (obj[chunked[idx][jdx]] = regeo.name));
    });
    return lnglats.map(it => obj[it] || '');
});
  
/** 适用于经纬度被 乘以了600000 后的值作为参数，顺序是 [lng/lon, lat] */
export const sf_rgeo_600000 = (ll6s) => {
    return sf_regeo_address_loader.loadMany(ll6s.map(([lng6, lat6]) => lng6 / 600000 + ',' + lat6 / 600000));
};
export const sf_rgeo = (ll6s) => {
  let arr = ll6s.map(([lng6, lat6]) => lng6 + ',' + lat6);
  return sf_regeo_address_loader2.loadMany(arr);
};


export const sf_regeo_address_loader2 = new DataLoader(async lnglats => {
  lnglats = lnglats.map(v => trim(v, '|'));
  const chunked = chunk([...new Set(lnglats)], 50);
  const ress = await Promise.allSettled(
    chunked.map(chunk => rgeo(chunk.map(it => it.split(',').map(parseFloat)).map(([x, y]) => ({ x, y }))))
  );

  const obj = {};
  ress.forEach((r, idx) => {
    if (r.status === 'rejected') return;
    (r.value?.result || []).forEach((regeo, jdx) => (obj[chunked[idx][jdx]] = regeo));
  });
  return lnglats.map(it => obj[it] || '');
});