<template>
    <div class="saas-model">
        <el-dialog 
        :title="title" 
        :visible.sync="dialogVisible" 
        :width="width" 
        :top="top"                   
        :close-on-click-modal="closeOnClickModal" 
        :modal-append-to-body="false"
        :center="center"
        :fullscreen="fullscreen"
        @closed="closedWindow"
        ref="dialogRef" >
            <slot v-if="dialogVisible"></slot>
            <template slot="footer" class="dialog-footer" v-if="dialogBtn && customBtns.length===0">
                <el-button @click="hide" v-show="isCancel">{{ cancelBtnText }}</el-button>
                <el-button type="primary" v-show="isBuild" @click="onBuild">生成</el-button>
                <el-button type="primary" v-show="!isConfirm" @click="confirm">{{ confirmBtnText }}</el-button>
            </template>
            <template slot="footer" class="dialog-footer" v-if="customBtns.length>0">
                <el-button v-for="item in customBtns" :key="item.label+'custom'" :type="item.type" @click="btnClick(item)">{{ item.label }}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "SAASModel",
	   props: {
			confirmBtnText: {
			  type: String,
			  default: '保 存'
			},
			confirmType: {
				type: String
			},
			cancelBtnText: {
			  type: String,
			  default: '取 消'				
			},
			isConfirm: {
				type: Boolean,
				default: false
			},
            isCancel:{
                type: Boolean,
				default: true
            },
			isBuild: {
				type: Boolean,
				default: false				
			},
			title: {
			  type: String,
			  default: '操作提示'				
			},
			width:{
			  type: String,
			  default: '30rem'					
			},
			top:{
			  type: String,
			  default: '15vh'					
			},
            center: {
				type: Boolean,
				default: false				
			},
            fullscreen: {
                type: Boolean,
				default: false	 
            },
            customBtns: { // 自定义按钮功能
                type: Array,
				default: () => [],
            },
            closeOnClickModal:{
                type: Boolean,
				default: false	
            },
            role: { // 在页面中区分是哪个功能
                type: String,
			    default: ''	
            }
	   },
        data() {
            return {
                dialogVisible: false,
                dialogBtn: true
            };
        },
        methods: {
            onBuild() {
                this.$emit("handleClick", "onBuild");
            },
            confirm() {
                this.$emit("handleClick", this.confirmType);
            },
            show (){
                this.dialogVisible = true;
            },
            hide (){
                this.dialogVisible = false;
                this.$emit('dialogEvent','cancel',this.role);
            },
            btnClick(item){
                this.$emit('handleClick',item.handler,item);
            },
            hideBtn(){
                this.dialogBtn = false;
            },
            closedWindow(){
                this.$refs.dialogRef.$el.nextSibling?.remove();
                this.$emit('dialogEvent','close',this.role);
            }
        }
    };
</script>
<style lang="less">
    .saas-model {
        .el-dialog__body {
            max-height: 60vh;
            overflow: auto;
        }

        .el-form-item {
            margin-bottom: 20px;
        }
    }
</style>
