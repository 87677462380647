export default [{
    path: "/videoManage",
    name: "videoManage",
    redirect: "/video",
    component: () => import ("@/views/VideoManage/index.vue"),
    children: [{
        path: "/video",
        name: "video",
        meta: {
            parentId: "videoManage",
        },
        component: () =>
            import ("@/views/VideoManage/DPlayer/index.vue")
    }, {
        path: "/alivideo",
        name: "alivideo",
        meta: {
            parentId: "videoManage",
        },
        component: () =>
            import ("@/views/VideoManage/AliPlayer/index.vue")
    }]
}];