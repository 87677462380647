<template>
    <ul class="saas-tab" @click="tabItemClick" v-if="tabListLength">
        <li
                v-for="(item,index) in tabList"
                :data-index="index"
                :data-name="item.name"
                :style="{width: 100 / tabListLength + '%'}"
        >{{item.label}}
        </li>
        <div id="saasTabActiveLine" class="active-line" :style="{width: 100 / tabListLength + '%'}"></div>
    </ul>
</template>

<script>
	export default {
		name: "SAASTab",
		props: ["tabList", "activeName", "tabActiveIndex"],
		data() {
			return {
				tabListLength: this.tabList.length,
				currentIndex: this.tabActiveIndex
			};
		},
		mounted() {
			$("#saasTabActiveLine").css("left", this.currentIndex * 100 / this.tabListLength + "%");
		},
		methods: {
			tabItemClick(e) {
				//if (e.target.dataset.index) {
					this.currentIndex = e.target.dataset.index;
					$("#saasTabActiveLine").css("left", this.currentIndex * 100 / this.tabListLength + "%");
					this.$emit("tabItemClick", this.currentIndex,e.target.dataset.name);
                //}
			}
		}
	};
</script>

<style lang="less">
    .saas-tab {
        line-height: 36px;
        display: flex;
        border-bottom: 2px solid rgba(51, 118, 255, 0.28);
        position: relative;

        li {
            text-align: center;
            cursor: pointer;
        }

        .active-line {
            position: absolute;
            bottom: -2px;
            height: 2px;
            background: #43ADFF;
            transition: left 0.3s ease-out;
        }
    }
</style>