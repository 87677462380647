<template>
	<div class="carTreeToolbar_setter" v-show="visible"> <!-- v-clickoutside="test" -->
		<div class="_title">车辆显示<span class="el-icon-circle-close" @click="visible=false"></span></div>
		<ul>
			<li class="_item">
				<el-radio v-model="treeParams.label" label="vehicleNo">车牌号码</el-radio>
			</li>
			<li class="_item">
				<el-radio v-model="treeParams.label" label="explains">自编号</el-radio>
			</li>
			<li class="_item">
				<el-checkbox v-model="treeParams.state">车辆状态</el-checkbox>
			</li>
			<li class="_item">
				<el-checkbox v-model="treeParams.offTimes">离线时长</el-checkbox>
			</li>
			<li class="_item">
				<el-checkbox v-model="treeParams.eType">2G/4G</el-checkbox>
			</li>
			<li class="_item">
				<el-checkbox v-model="treeParams.area">所在地区</el-checkbox>
			</li>
		</ul>
		<div class="_title">车辆过滤</div>
		<!-- {{(typeof treeParams.filterStatus)}}/{{treeParams.filterStatus}} -->
		<div class="propsFilter_outer">
			<!-- {{ JSON.stringify(treeParams.pt) }} -->
			<div class="inner">
				<el-select v-model="treeParams.filterStatus" clearable placeholder="车辆状态" size="mini" @change="changeFilters">
					<el-option
					v-for="item in statusOptions"
					:key="item.label"
					:label="item.label"
					:value="item.value">
					</el-option>
				</el-select>
				<el-select v-model="treeParams.pays" clearable placeholder="是否缴费" size="mini" @change="changeFilters">
					<el-option label="已缴费" value="1"></el-option>
					<el-option label="未缴费" value="0"></el-option>
				</el-select>
				<el-select v-model="treeParams.pt" clearable placeholder="协议" size="mini" @change="changeFilters">
					<!-- 1：JT808，2：JT808B  10：桂标 11：陕标，12：苏标，13：黑标
                        //14：川标，15：甘标，16: 北京地标，17: 粤标，18：浙标, 19:鲁标 -->
					<el-option label="苏标" value="12"></el-option>
					<el-option label="粤标" value="17"></el-option>
					<el-option label="浙标" value="18"></el-option>
					<el-option label="桂标" value="10"></el-option>
					<el-option label="鲁标" value="19"></el-option>
				</el-select>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "VehicleTreeSetter",
		data(){
			return {
				visible: false,
				treeParams: {},
				checkList:[],
				statusOptions: [{
					label: '在线车辆',
					value: '[11,12,13,20,21]'
				},{
					label: '离线车辆',
					value: '[0]'
				},{
					label: '行驶车辆',
					value: '[20,21]'
				},{
					label: '停止车辆',
					value: '[12,13]'
				},{
					label: '报警车辆',
					value: '[13,21]'
				},{
					label: '从未上线',
					value: '[-1]'
				}]
			}
		},
        watch: {
            treeParams: {
				handler(newValue,oldValue){
					// 存在localStorage中和state中		
					this.$addStorageEvent(1, 'treeParamsShower', JSON.stringify(newValue));
					this.$store.commit('setTreeParamsShower',newValue);
				},
				deep: true
            }
        },		
		created(){
            let params = localStorage.getItem('treeParamsShower');
            // 缓存里没有，直接从state里读取
            if(params=='undefined'||params=='{}'||!params){
                this.treeParams = JSON.parse(JSON.stringify(this.$store.state.treeParamsShower));
            }else{
                let p1 = Object.keys(this.$store.state.treeParamsShower).sort().join(',');
                let p2 = Object.keys(JSON.parse(params)).sort().join(',');
                // 如果p1和p2的key不一致，说明筛选条件进行了增加或减少
                // 则读取state里的值，不从缓存里读取,清除缓存
                this.treeParams = p1==p2 ? JSON.parse(params): JSON.parse(JSON.stringify(this.$store.state.treeParamsShower));
				p1!=p2 && localStorage.removeItem('treeParamsShower');
            }
			// 协议类型不存在缓存中
			this.treeParams.pt = null;
			// 最之前的车辆状态参数缓存的是数字，现在缓存的是字符串
			if(!isNaN(parseInt(this.treeParams.filterStatus))){
				this.treeParams.filterStatus = '';
			}
		},
		methods: {
			show(){				
				if(!this.treeParams.label){
					this.treeParams.label = 'vehicleNo';
					this.$addStorageEvent(1, 'treeParamsShower', JSON.stringify(this.treeParams));
					this.$store.commit('setTreeParamsShower',this.treeParams);
				}

				this.visible = !this.visible;
			},
			hide(){
				this.visible = false;
			},
			changeFilters(){
				this.$emit('changeFilters',this.treeParams)
			}
		},

	};
</script>

<style lang="less">
	.vehicle-tree-show{
		.carTreeToolbar_setter{
			width: 350px;
			right: -360px;
		}		
	}
	.vehicle-tree-hide{
		.carTreeToolbar_setter{
			width: 0;
			left: -350px;
		}
	}
	.carTreeToolbar_setter{		
	    position: absolute;
	    background-color: #fff;
	    border-width: 1px;
	    border-style: solid;
	    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
	    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
	    overflow: hidden;	
		transition-property: left width;
		transition-duration: 0.2s;
		transition-timing-function: ease-out;		
		._title{
			width: 100%;
			background-color: #e8eaed;
			height: 30px;
			line-height: 30px;
			text-indent: 10px;
			position: relative;
			.el-icon-circle-close{
				position: absolute;
				right: 8px;
				top: 8px;
				cursor: pointer;
				font-size: 16px;
				&:hover{
					opacity: 0.6;
				}
			}
		}
		ul{
			width: 100%;
			display:flex;
			flex-wrap: wrap;
			._item{
				width: calc(33% + 1.5px);
				border-left-width: 1px;
				border-left-style: solid;
				border-left-color: #e8eaed;
				border-bottom-width: 1px;
				border-bottom-style: solid;
				border-bottom-color: #e8eaed;
				margin-left: -1px;
				overflow: hidden;
				text-overflow: ellipsis;
				box-sizing: border-box;
				padding-left: 5px;
				height: 32px;
				line-height: 32px;
				&.border-b-n{
					border-bottom: none;
				}
				.el-radio__label,.el-checkbox__label{
					padding-left: 3px;
				}
			}	
			._item_inline{
				padding-left: 10px;
				width: 100%;
				box-sizing: border-box;
			}
		}
		.propsFilter_outer{
			padding: 5px;
			.inner{
				display: flex;
				height: 30px;
				.el-select~.el-select{
					margin-left: 5px;
				}
			}
		}
		

	
		.btnBox{
			clear: both;
			padding: 5px;
			text-align: right;
		}

	}
</style>