export default [
	{
		path: "/largeScreen",
		name: "largeScreen",
		meta: {
			parentId: "largeScreen",
		},
		component: () => import("@/views/LargeScreen/LargeScreen.vue")
	},
	// {
	// 	path: "/largeScreen2",
	// 	name: "largeScreen2",
	// 	component: () => import("@/views/LargeScreen/LargeScreen2.vue")
	// }
];
