<template>
    <div class="saas-tree tree-search-container" :class="className" v-clickoutside="handleClose">
        <div v-if="$store.state.vehicleTreeLoading" class="vehicle-tree-loading">
            <div>数据加载中...</div>
            <div class="loading-icon iconfont iconjiazai"></div>
        </div>
        <template v-else>
            <div class="tree-search">
                <el-input
                        id="vehicleTreeSearch"
                        class="search-input"
                        autocomplete="off"
                        :placeholder="placeholder"
                        v-model="treeSearchValue"
                        clearable
                        @input="treeInput"
                        @focus="treeInputFocus"
                        @mouseenter.native="inputMouseover"
                        @mouseleave.native="inputMouseout"
                >
                    <template>
                        <i v-if="iconXialaShow" class="input-icon iconfont iconsousuo" slot="suffix"></i>
                    </template>
                </el-input>
                <div class="search-refresh iconfont iconshuaxin" @click="treeRefresh"></div>
				<div class="search-refresh iconfont iconshezhi" @click="showSetter" ></div> 
                <!-- v-clickoutside="hideSetter" -->
				<VehicleTreeSetter 
					ref="VehicleTreeSetter"
				></VehicleTreeSetter>
            </div>
            <ul class="search-list el-select-group__wrap" v-if="treeSearchList.length && searchListShow">
				<template v-if="entList.length>0">
					<li class="el-select-group__title" >组织</li>
					<li v-for="item in entList" @click="treeSearchById(item)">
						{{ item[treeProps.label] }}
					</li>				
				</template>
				<template v-if="vehicleList.length>0">
					<li class="el-select-group__title">车辆</li>
					<li v-for="item in vehicleList" @click="treeSearchById(item)">
						{{ item[treeProps.label] }}
					</li>
				</template>
            </ul>
            <div class="no-search-tree-data" v-if="searchListShow && noSearchTreeData">暂无数据</div>
            <div ref="vehicleTree" class="tree-component" id="vehicleTree">
                <div style="position: absolute;min-width: 100%;">
                    <VehicleRecursionTree
						ref="recursionTree"
						:pageType="pageType"
						:treeList="treeList"
						:treeProps="treeProps"
						:iconList="iconList"
						:treeIndex="0"
						@treeClick="treeClick"
						@treeChoose="treeChoose"
						@treeExtend="treeExtend"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import {orgVehCountTree, simpleVehicle, vehicleSearch, leaftree} from "@/api/index";
	import VehicleTreeSetter from './VehicleTreeSetter.vue';
    export default {
        name: "VehicleTree",
		components: {VehicleTreeSetter},
        props: {
            vehicleTreeData: {required: true},
            placeholder: {required: false},
            treeProps: {
                type: Object,
                default: function () {
                    return {
                        label: "label",
                        value: "value",
                        children: "children",
                        checkBox: false
                    };
                }
            },
            iconList: {
                type: Object,
                default: function () {
                    return {
                        pullDownIcon: "iconjianshanchu",
                        pullUpIcon: "iconjiatianjia"
                    };
                }
            },
            pageType: {
                required: false
            },
            treeInputText: {
                required: false
            },
            className: {
                required: false
            },
            maxLength: {
                required: false
            }
        },
        watch: {
            treeInputText(val) {
                this.treeSearchValue = val;
            },
            vehicleTreeData: {
                handler(val) {
                    this.treeList = val;
                    this.$emit("getVehicleTree");
                },
                deep: true
            }
        },
        data() {
            return {
                iconXialaShow: true,
                treeSearchValue: "",
                refreshStatusIds: [],
                timerNum: 0,
                timer: "",
                searchTreeData: {
                    list: []
                },
                searchListShow: false,
                noSearchTreeData: false,
                isiTreeShow: false,
                treeSearchList: [],
                treeList: this.vehicleTreeData,
				entList: [], // 从treeSearchList筛选出组织和车辆
				vehicleList: [], // //dataType为2是组织，1为车辆
            };
        },
        methods: {
            treeInputFocus() {
                this.searchListShow = true;
                this.isiTreeShow = true;
            },
            treeSearch(value) {
                let params = {
                    name: value,
                    limit: 50
                };
                if (this.pageType == "playbackVideo" || this.pageType == "videoPolling") {
                    params.hasChannel = true;
                    params.equipmentType = 4;
                }
				this.entList = [];
				this.vehicleList = [];
                vehicleSearch(params).then(res => {
                    if (res.code === 0) {
                        this.noSearchTreeData = false;
                        if (res.result.length) {
							res.result.forEach(v=>{
								let obj = {
									label: v.name,
									value: v.v1,
									dataType: v.dataType
								};
								this.treeSearchList.push(obj);
								if(v.dataType==1){
									this.vehicleList.push(obj);
								}
								if(v.dataType==2){
									this.entList.push(obj);
								}
							});	
                        } else {
                            this.noSearchTreeData = true;
							this.treeSearchList = [];
                        }
                    }
                });
            },
            treeSearchById(data) {
                let params = {
                    dataType: data.dataType,
                    id: data.value
                }, that = this;
                leaftree(params).then(res => {
                    if (res.code === 0) {
                        var result = res.result;

                        function recursionFun(treeList = [], resultItem) {
                            for (let j = 0; j < treeList.length; j++) {
                                let item = treeList[j];
                                that.$set(item, "active", false);
                                if (data.dataType == 2) {
                                    if (resultItem[0].o1 == item[that.treeProps.value]) {
                                        if (!resultItem[0].c) {
                                            setTimeout(function () {
                                                let node = $("#tree5" + item[that.treeProps.value]);
                                                that.$refs.vehicleTree.scrollTop = 0;
                                                that.$refs.vehicleTree.scrollTop = node.offset().top - $("#vehicleTree").offset().top;
                                                that.$set(item, "active", true);
                                                that.$emit("treeSearchById", item, data.dataType);
                                            }, 500);
                                            continue;
                                        } else {
                                            that.$set(item, "expand", true);
                                            that.treeExtend(item);
                                            recursionFun(item[that.treeProps.children], resultItem[0].c);
                                            continue;
                                        }
                                    }
                                } else {
                                    if (resultItem[0].o1 == item[that.treeProps.value]) {
                                        if (resultItem[0].c) {
                                            that.$set(item, "expand", true);
                                            that.treeExtend(item);
                                            recursionFun(item[that.treeProps.children], resultItem[0].c);
                                            continue;
                                        } else if (resultItem[0].vs) {
                                            that.$set(item, "expand", true);
                                            that.treeExtend(item, () => {
                                                recursionFun(item[that.treeProps.children], resultItem[0].vs);
                                            });
                                            continue;
                                        }
                                    } else if (resultItem[0].v1 == item[that.treeProps.value]) {
                                        setTimeout(function () {
                                            let node = $("#tree5" + item[that.treeProps.value]);
                                            that.$refs.vehicleTree.scrollTop = 0;
                                            that.$refs.vehicleTree.scrollTop = node.offset().top - $("#vehicleTree").offset().top;
                                            that.$set(item, "active", true);
                                            that.$emit("treeSearchById", item, data.dataType);
                                        }, 500);
                                        continue;
                                    }
                                }
                            }
                        }

                        that.searchListShow = false;
                        recursionFun(that.treeList[0].children, result);
                        this.searchListShow = false;
                    }
                });
            },
            treeExtend(item, callback) {
                if (this.pageType == "realtimeMonitor" || this.pageType == "trackPlay" || this.pageType == "videoPolling") {
                    let index = this.refreshStatusIds.indexOf(item.value);
                    if (index == -1) {
                        this.refreshStatusIds.push(item.value);
                    } else if (!item.expand) {
                        this.refreshStatusIds.splice(index, 1);
                    }
                }
                if (item.isExpand) {
                    callback && callback();
                    return;
                }
                let params = {
                    entId: item.value,
                    isCache: true,
                    moreFields: "v7,v8,vt,exp"
                };
                item.isExpand = false;
                simpleVehicle(params).then(res => {
                    if (res.code === 0 && res.result.length) {
                        let result = this.$utils.deepClone(res.result);
                        for (let i = 0; i < result.length; i++) {
                            let vehicle = result[i];
                            vehicle.type = 2;
                            vehicle.label = vehicle.v2;
                            vehicle.value = vehicle.v1;
                            let {icon, className,status} = this.setIconColor(vehicle);
                            vehicle.icon = icon;
                            vehicle.className = className;
							vehicle.status = status;
                            vehicle.vehicleStatus = vehicle.vbs;                            
                            vehicle.checked = item.checked;
                            if (vehicle.vehicleStatus) {
                                vehicle.offlineTime = 1000;
                            } else {
                                vehicle.offlineTime = vehicle.v7 ? Date.now() - vehicle.v7 : "";
                            }
                            vehicle.pathIds = [...item.pathIds, item.value];
                            /// v8是否有通道号，vt: 4为1078，2是2g
                            if (this.pageType == "playbackVideo" && (!vehicle.v8 || vehicle.vt != '4')) {
                                result.splice(i, 1);
                                i--;
                            } else if (this.pageType == "videoPolling" && (!vehicle.vbs || !vehicle.v8 || vehicle.vt != '4')) {
                                result.splice(i, 1);
                                i--;
                            }
                            delete vehicle.v1;
                            delete vehicle.v2;
                            delete vehicle.v6;
                            delete vehicle.v7;
                        }
       
                        item.children.push(...result);
                        item.isExpand = true;
                        callback && callback();
                    }
                });
            },
            setIconColor(vehicle) {
                let icon = "",
                    className = "",
                    status = "";
                switch (vehicle.vbs) {
                    case -1:
						icon = "lixianim";
						className = "offline-time";
						status = '从未上线';
                        break;
                    case 0:
						icon = "daohang";
						className = "offline-time";
						status = '离线';
                        break;
                    case 11:
						icon = "lixianim";
						className = "online-vehicle";
						status = '在线/未定位';
                        break;
                    case 12:
						icon = "tingchechang1";
						className = "online-vehicle";
						status = '在线/停车';
                        break;
                    case 13:
						icon = "tingchechang1";
						className = "alarm-vehicle";
						status = '在线/停车/报警';
                        break;
                    case 20:
						icon = "daohang";
						className = "travel-vehicle";
						status = '在线/行驶';
                        break;
                    case 21:
						icon = "daohang";
						className = "alarm-vehicle";
						status = '在线/行驶/报警';
                        break;
                    default:
                        break;
                }
                return {icon, className,status};
            },
            treeRefresh() {
                this.treeSearchValue = "";
                this.noSearchTreeData = false;
                this.treeSearchList = [];
                this.getVehicleTree(false);
            },
            getVehicleTree(flag = true) {
                if(this.$store.state.vehicleTreeLoading) return;
                this.loading = true;
                let params = {
                    isCache: flag,
                    removeFreeNode: true
                };
                this.$store.state.vehicleTreeLoading = true;
                orgVehCountTree(params).then(res => {
                    let treeList = [{
                        value: "-1",
                        label: "监控中心",
                        expand: true,
                        type: "ent",
                        children: []
                    }];
                    if (res.code === 0) {
                        function recursion(list, parentItem) {
                            list.forEach(item => {
                                item.label = item.o2;
                                delete item.o2;
                                item.value = item.o1;
                                delete item.o1;
                                item.children = item.c || [];
                                delete item.c;
                                item.type = 1;
                                item.pathIds = parentItem ? [...parentItem.pathIds, parentItem.value] : ["-1"];
                                if (item.children && item.children.length) {
                                    recursion(item.children, item);
                                }
                            });
                        }

                        recursion(res.result);
                        treeList[0].children = res.result;
                        this.$store.commit("setMonitorVehicleTree", treeList);
                        this.$store.state.vehicleTreeLoading = false;
                    }
                });
            },
            treeInput(value) {
                this.iconXialaShow = false;
                if (!value.length) {
                    this.iconXialaShow = true;
                }
                let that = this;
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                // if (value.length == 2) {
                //     if (!value.match(/[\u4E00-\u9FA5]{2}/g)) {
                //         this.treeSearchList = [];
                //         this.noSearchTreeData = false;
                //         return;
                //     }
                // } else if (value.length < 3) {
                //     this.treeSearchList = [];
                //     this.noSearchTreeData = false;
                //     return;
                // }
				if (value.length < 2) {
				    this.treeSearchList = [];
				    this.noSearchTreeData = false;
				    return;
				}
                this.timer = setTimeout(function () {
                    that.treeSearch(value);
                }, 500);
            },
            treeClick(handler, item) {
                this.isiTreeShow = false;
                this.$emit("treeClick", handler, item);
            },
            treeChoose(item) {
                this.toggleCheckBox();
                this.$emit("treeChoose", item);
            },
            inputMouseover() {
                if (this.treeSearchValue) {
                    this.iconXialaShow = false;
                }
            },
            inputMouseout() {
                if (!this.isiTreeShow) {
                    this.iconXialaShow = true;
                }
            },
            toggleCheckBox() {
                // 勾选某一行时，修改父节点及子节点选中状态
                var that = this;

                function recursionList(item) {
                    var checkedNum = 0;
                    var partCheckedNum = 0;
                    item[that.treeProps.children].forEach((li) => {
                        if (li[that.treeProps.children] && li[that.treeProps.children].length > 0) {
                            recursionList(li);
                        }
                        if (li.checked === true) {
                            checkedNum++;
                        } else if (li.partchecked === true) {
                            partCheckedNum++;
                        }
                    });
                    if (checkedNum === item[that.treeProps.children].length) {	//全选
                        that.$set(item, "checked", true);
                        that.$set(item, "partchecked", false);
                    } else if (checkedNum > 0 || partCheckedNum > 0) {	//部分勾选
                        that.$set(item, "checked", false);
                        that.$set(item, "partchecked", true);
                    } else {	//没有勾选
                        that.$set(item, "checked", false);
                        that.$set(item, "partchecked", false);
                    }
                }

                recursionList(this.treeList[0]);
            },
            handleClose() {
                this.searchListShow = false;
                this.iconXialaShow = true;
                this.isiTreeShow = false;
            },
			showSetter(){
				this.$refs.VehicleTreeSetter.show();
			},
			hideSetter(){
				this.$refs.VehicleTreeSetter.hide();
			}
        }
    };
</script>

<style lang="less" scoped>
    .saas-tree {
        height: 100%;
        position: relative;

        .tree-search {
            height: 34px;
            position: relative;
            display: flex;
            line-height: 32px;
            font-size: 14px;
            z-index: 10;
            input {
                height: 100%;
            }
            input::placeholder {
                font-size: 14px;
                color: #cccccc;
            }
        }

        .search-input {
            width: calc(100% - 50px);
            outline: none;
            position: relative;
            font-size: 14px;
            .el-input__inner {
                border: none;
                padding: 0 8px;
            }
        }

        .search-refresh {
            width: 30px;
            height: 100%;
            text-align: center;
            background: #2C7AF8;
            margin-left: 3px;
            cursor: pointer;

            &:active {
                background: #266BDC;
            }
        }

        .search-list {
            position: absolute;
            top: 34px;
            z-index: 100;
            width: 100%;
            margin-top: 5px;
            height: calc(100% - 39px);
            overflow: auto;
            font-size: 14px;
            line-height: 34px;
            border: none;

            li {
                padding-left: 12px;

                &:hover {
                    background: rgba(255, 255, 255, 0.2);;
                }
            }
        }

        .no-search-tree-data {
            position: absolute;
            top: 34px;
            margin-top: 5px;
            z-index: 100;
            width: 100%;
            height: calc(100% - 39px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border: none;
        }

        .tree-component {
            margin-top: 10px;
            height: calc(100% - 44px);
            overflow: auto;
            display: flex;
            position: relative;
        }
    }
</style>