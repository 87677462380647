import { render, staticRenderFns } from "./SAASModel.vue?vue&type=template&id=db0bd97c"
import script from "./SAASModel.vue?vue&type=script&lang=js"
export * from "./SAASModel.vue?vue&type=script&lang=js"
import style0 from "./SAASModel.vue?vue&type=style&index=0&id=db0bd97c&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports