export default {
    platConfigParams: "projectName,logoImg,headImg,companyName,varcharCode,versionsCode,servicePhone,linkAddress,loginImg,publicNetworkSecurity",
    updatePassword: [
        {
            label: "原始密码:",
            type: "input",
            key: "oldPwd",
            showPassword: true,
            handler:'oldPwd'

        },
        {
            label: "新改密码:",
            type: "input",
            key: "newPwd",
            showPassword: true,
            maxLength: 16,
            minLength: 8,
            handler:'newPwd',
            placeholder: '8-16位包含大小写字母、数字中的两种或以上'
        },
        {
            label: "确认密码:",
            type: "input",
            key: "confirmPwd",
            showPassword: true,
            maxLength: 16,
            minLength: 8,
            handler:'confirmPwd'
        }
    ],
    menuList: [
        {
            name: "实时监控",
            iconClass: "iconjiankong1",
            checked: false,
            active: false,
            parent: "monitorManage",
            path: "/realtimeMonitor",
            routerName: "RealtimeMonitor",
            menuId: "monitor_center_realtime"
        }, {
            name: "轨迹回放",
            iconClass: "iconguijifenxi",
            checked: false,
            active: false,
            parent: "monitorManage",
            path: "/trackPlay",
            routerName: "TrackPlay",
            menuId: "monitor_center_trail"
        }, {
            name: "录像回放",
            iconClass: "iconguijihuifang",
            checked: false,
            active: false,
            parent: "monitorManage",
            path: "/playBackVideo",
            routerName: "PlayBackVideo",
            menuId: "monitor_center_videotape"
        }, {
            name: "视频轮询",
            iconClass: "iconziyuan179",
            checked: false,
            active: false,
            parent: "monitorManage",
            path: "/videoPolling",
            routerName: "VideoPolling",
            menuId: "monitor_center_videopoll"
        }, {
            name: "警情监管",
            iconClass: "iconbaojing",
            checked: false,
            active: false,
            parent: "monitorManage",
            path: "/alarmManage",
            routerName: "AlarmManage",
            menuId: "monitor_center_alarm"
        },
        {
            name: "重点监控",
            iconClass: "iconcrosshairmiaozhun",
            checked: false,
            active: false,
            parent: "monitorManage",
            path: "/emphasisMonitor",
            routerName: "EmphasisMonitor",
            menuId: "monitor_center_important"
        },
        {
            name: "区域管理",
            iconClass: "iconwinfo-icon-quyusaomiao",
            checked: false,
            active: false,
            parent: "monitorManage",
            path: "/areaManage",
            routerName: "AreaManage",
            menuId: "monitor_center_areamanage"
        },
        {
            name: "终端参数",
            iconClass: "iconweiwangguanicon-defuben-",
            checked: false,
            active: false,
            parent: "monitorManage",
            path: "/terminalparam",
            routerName: "terminalparam",
            menuId: "user_center_serve_terminal"
        }
    ],
    mapJson: [
        {
            "featureType": "land",
            "elementType": "geometry",
            "stylers": {
                "color": "#0c112aff",
                "visibility": "on"
            }
        }, {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#0c112aff",
                "visibility": "on"
            }
        }, {
            "featureType": "building",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#01051bff",
                "visibility": "on"
            }
        }, {
            "featureType": "building",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#000002ff",
                "visibility": "on"
            }
        }, {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": {
                "color": "#182240ff",
                "visibility": "on"
            }
        }, {
            "featureType": "village",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "town",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "district",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "country",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#5ae6f7ff",
                "visibility": "on"
            }
        }, {
            "featureType": "city",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#fce588ff",
                "visibility": "on"
            }
        }, {
            "featureType": "continent",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#63edf8ff",
                "visibility": "on"
            }
        }, {
            "featureType": "poilabel",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "poilabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "scenicspotslabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "scenicspotslabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#182240ff",
                "visibility": "on"
            }
        }, {
            "featureType": "transportationlabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#182240ff",
                "visibility": "on"
            }
        }, {
            "featureType": "transportationlabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "airportlabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#182240ff",
                "visibility": "on"
            }
        }, {
            "featureType": "airportlabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#010372ff",
                "visibility": "on"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2d44cb00",
                "visibility": "on"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "green",
            "elementType": "geometry",
            "stylers": {
                "color": "#6c7c9717",
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspots",
            "elementType": "geometry",
            "stylers": {
                "color": "#05177269",
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspots",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#e1e7faff",
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspots",
            "elementType": "labels.text.stroke",
            "stylers": {
                "weight": 1,
                "color": "#51536500",
                "visibility": "on"
            }
        }, {
            "featureType": "continent",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536500",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "country",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536500",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "city",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536500",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "city",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspotslabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#515365ff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "airportlabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#515365ff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "transportationlabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#515365ff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "railway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "subway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "highwaysign",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "nationalwaysign",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "nationalwaysign",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "provincialwaysign",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "provincialwaysign",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "tertiarywaysign",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "tertiarywaysign",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "subwaylabel",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "subwaylabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#c3d1fdff",
                "visibility": "on",
                "weight": 90
            }
        }, {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#515365ff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "shopping",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "scenicspots",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspotslabel",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "manmade",
            "elementType": "geometry",
            "stylers": {
                "visibility": "on",
                "color": "#3043830f"
            }
        }, {
            "featureType": "manmade",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "highwaysign",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#6c7c9700",
                "visibility": "on"
            }
        }, {
            "featureType": "road",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "road",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "road",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "road",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "road",
            "elementType": "labels.text",
            "stylers": {
                "fontsize": 24
            }
        }, {
            "featureType": "highway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536500",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#3953d84f",
                "visibility": "on"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#1c4f7e00"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#beceffff",
                "visibility": "on"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#4b67f3ff",
                "visibility": "on"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#1c4f7e00"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#beceffff",
                "visibility": "on"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536500",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#7d8acc54",
                "visibility": "on"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#fcc737ff",
                "visibility": "on"
            }
        }, {
            "featureType": "arterial",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#fcc14d4a",
                "visibility": "on"
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#a8e4eb96",
                "visibility": "on"
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#9adcff4a",
                "visibility": "on"
            }
        }, {
            "featureType": "local",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#b0c1fa61",
                "visibility": "on"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2d44cb00",
                "visibility": "on"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2d44cbff",
                "visibility": "on"
            }
        }, {
            "featureType": "arterial",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2d44cb00",
                "visibility": "on"
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2d44cb08",
                "visibility": "on"
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2d44cb00",
                "visibility": "on"
            }
        }, {
            "featureType": "local",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2d44cb00",
                "visibility": "on"
            }
        }, {
            "featureType": "local",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#beceffff",
                "visibility": "on"
            }
        }, {
            "featureType": "local",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536500",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#beceffff",
                "visibility": "on"
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#beceffff",
                "visibility": "on"
            }
        }, {
            "featureType": "arterial",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#beceffff",
                "visibility": "on"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#cfdafff2",
                "visibility": "on"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#beceffff",
                "visibility": "on"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536514",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#515365ff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "arterial",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536500",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#51536500",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#515365ff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "geometry",
            "stylers": {
                "weight": 1
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "geometry",
            "stylers": {
                "weight": 1
            }
        }, {
            "featureType": "local",
            "elementType": "geometry",
            "stylers": {
                "weight": 1
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "weight": 5
            }
        }, {
            "featureType": "arterial",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "highway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "highway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "highway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "highway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "nationalway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "nationalway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "nationalway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "nationalway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "provincialway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "8"
            }
        }, {
            "featureType": "provincialway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "9"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "8"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "9"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "8"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "9"
            }
        }, {
            "featureType": "cityhighway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "cityhighway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "cityhighway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "cityhighway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "arterial",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "9,9",
                "level": "9"
            }
        }, {
            "featureType": "arterial",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "9,9",
                "level": "9"
            }
        }, {
            "featureType": "arterial",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "9,9",
                "level": "9"
            }
        }, {
            "featureType": "districtlabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#94d2ffff"
            }
        }, {
            "featureType": "districtlabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff00"
            }
        }, {
            "featureType": "district",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff00"
            }
        }, {
            "featureType": "district",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#7dd2ffff"
            }
        }, {
            "featureType": "town",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#a7e8fdff"
            }
        }, {
            "featureType": "scenicspotsway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#ffffff00"
            }
        }, {
            "featureType": "scenicspotsway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#56def32e"
            }
        }, {
            "featureType": "subway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#fff7cdff"
            }
        }, {
            "featureType": "subway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#ffffff0f"
            }
        }, {
            "featureType": "subway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#beceffff"
            }
        }, {
            "featureType": "subway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff08"
            }
        }, {
            "featureType": "subwaylabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#affaffff"
            }
        }, {
            "featureType": "subwaylabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff03"
            }
        }, {
            "featureType": "building",
            "elementType": "geometry.topfill",
            "stylers": {
                "color": "#62a9f829"
            }
        }, {
            "featureType": "building",
            "elementType": "geometry.sidefill",
            "stylers": {
                "color": "#91ebfa3b"
            }
    }],
    mapJson2: [
        {
            "featureType": "land",
            "elementType": "geometry",
            "stylers": {
                "color": "#050e64ff",
                "visibility": "on"
            }
        }, {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#050e64ff",
                "visibility": "on"
            }
        }, {
            "featureType": "building",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#000255ff",
                "visibility": "on"
            }
        }, {
            "featureType": "building",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#00003cff",
                "visibility": "on"
            }
        }, {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": {
                "color": "#002a85ff",
                "visibility": "on"
            }
        }, {
            "featureType": "village",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "town",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "district",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "country",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#6eeff8ff",
                "visibility": "on"
            }
        }, {
            "featureType": "city",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#ffd605ff",
                "visibility": "on"
            }
        }, {
            "featureType": "continent",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#002a85ff",
                "visibility": "on"
            }
        }, {
            "featureType": "poilabel",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "poilabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "scenicspotslabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "scenicspotslabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#002a85ff",
                "visibility": "on"
            }
        }, {
            "featureType": "transportationlabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#002a85ff",
                "visibility": "on"
            }
        }, {
            "featureType": "transportationlabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "airportlabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#002a85ff",
                "visibility": "on"
            }
        }, {
            "featureType": "airportlabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#4c8fe7ff",
                "visibility": "on"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2e6cbfff",
                "visibility": "on"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "green",
            "elementType": "geometry",
            "stylers": {
                "color": "#1560bb40",
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspots",
            "elementType": "geometry",
            "stylers": {
                "color": "#081fe930",
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspots",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#dbe7ffff",
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspots",
            "elementType": "labels.text.stroke",
            "stylers": {
                "weight": 1,
                "color": "#005a5d00",
                "visibility": "on"
            }
        }, {
            "featureType": "continent",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5dff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "country",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "city",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "city",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "scenicspotslabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5dff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "airportlabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5dff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "transportationlabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5dff",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "railway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "subway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "highwaysign",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "nationalwaysign",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "nationalwaysign",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "provincialwaysign",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "provincialwaysign",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "tertiarywaysign",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "tertiarywaysign",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "subwaylabel",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "subwaylabel",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#f3ffd5ff",
                "visibility": "on",
                "weight": 90
            }
        }, {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d03",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "shopping",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "scenicspots",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "scenicspotslabel",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "manmade",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "manmade",
            "elementType": "labels",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "highwaysign",
            "elementType": "labels.icon",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#1560bb00",
                "visibility": "on"
            }
        }, {
            "featureType": "road",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "road",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "road",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "road",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "road",
            "elementType": "labels.text",
            "stylers": {
                "fontsize": 24
            }
        }, {
            "featureType": "highway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#4c8fe77d",
                "visibility": "on"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#1c4f7eff"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#d0fffdff",
                "visibility": "on"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#4c8fe785",
                "visibility": "on"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#1c4f7eff"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#98e9ffff",
                "visibility": "on"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#4c8fe782",
                "visibility": "on"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#12e6fdff",
                "visibility": "on"
            }
        }, {
            "featureType": "arterial",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#7198f18f",
                "visibility": "on"
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#4c8fe7ff",
                "visibility": "on"
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#4c8fe766",
                "visibility": "on"
            }
        }, {
            "featureType": "local",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#4c8fe75c",
                "visibility": "on"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2e6cbf00",
                "visibility": "on"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2e6cbf03",
                "visibility": "on"
            }
        }, {
            "featureType": "arterial",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2e6cbf08",
                "visibility": "on"
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2e6cbfff",
                "visibility": "on"
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2e6cbfff",
                "visibility": "on"
            }
        }, {
            "featureType": "local",
            "elementType": "geometry.stroke",
            "stylers": {
                "color": "#2e6cbfff",
                "visibility": "on"
            }
        }, {
            "featureType": "local",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#a8faff8f",
                "visibility": "on"
            }
        }, {
            "featureType": "local",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d08",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#a8faffe0",
                "visibility": "on"
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#a8faffba",
                "visibility": "on"
            }
        }, {
            "featureType": "arterial",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#a8faffa6",
                "visibility": "on"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#a8faffc9",
                "visibility": "on"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#a8faffff",
                "visibility": "on"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "arterial",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#005a5d00",
                "visibility": "on",
                "weight": 1
            }
        }, {
            "featureType": "fourlevelway",
            "elementType": "geometry",
            "stylers": {
                "weight": 1
            }
        }, {
            "featureType": "tertiaryway",
            "elementType": "geometry",
            "stylers": {
                "weight": 1
            }
        }, {
            "featureType": "local",
            "elementType": "geometry",
            "stylers": {
                "weight": 1
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "weight": 5
            }
        }, {
            "featureType": "arterial",
            "elementType": "geometry",
            "stylers": {
                "weight": 3
            }
        }, {
            "featureType": "highway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "highway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "highway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "highway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "highway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "highway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "nationalway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "nationalway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "nationalway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "nationalway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "nationalway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "provincialway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "8"
            }
        }, {
            "featureType": "provincialway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "9"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "8"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "9"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "8"
            }
        }, {
            "featureType": "provincialway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "8,10",
                "level": "9"
            }
        }, {
            "featureType": "cityhighway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "cityhighway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "cityhighway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "cityhighway",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "6"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "7"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "8"
            }
        }, {
            "featureType": "cityhighway",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "6,9",
                "level": "9"
            }
        }, {
            "featureType": "arterial",
            "stylers": {
                "curZoomRegionId": "0",
                "curZoomRegion": "9,9",
                "level": "9"
            }
        }, {
            "featureType": "arterial",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "9,9",
                "level": "9"
            }
        }, {
            "featureType": "arterial",
            "elementType": "labels",
            "stylers": {
                "visibility": "off",
                "curZoomRegionId": "0",
                "curZoomRegion": "9,9",
                "level": "9"
            }
        }, {
            "featureType": "district",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#8bddf8ff"
            }
        }, {
            "featureType": "district",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff00"
            }
        }, {
            "featureType": "scenicspotsway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "universityway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "vacationway",
            "elementType": "geometry",
            "stylers": {
                "visibility": "off"
            }
        }, {
            "featureType": "subway",
            "elementType": "geometry.fill",
            "stylers": {
                "color": "#8fffd47d"
            }
        }, {
            "featureType": "subwaylabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#70fae2ff"
            }
        }, {
            "featureType": "subwaylabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff00"
            }
        }, {
            "featureType": "tertiarywaysign",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff00"
            }
        }, {
            "featureType": "tertiarywaysign",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#aafaf7ff"
            }
        }, {
            "featureType": "medical",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#b6defcff"
            }
        }, {
            "featureType": "town",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff00"
            }
        }, {
            "featureType": "town",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#ffececff"
            }
        }, {
            "featureType": "districtlabel",
            "elementType": "labels.text.stroke",
            "stylers": {
                "color": "#ffffff00"
            }
        }, {
            "featureType": "districtlabel",
            "elementType": "labels.text.fill",
            "stylers": {
                "color": "#18ffdfff"
            }
        }, {
            "featureType": "districtlabel",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
        }, {
            "featureType": "city",
            "elementType": "labels",
            "stylers": {
                "visibility": "on"
            }
    }]
};