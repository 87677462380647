import Vue from "vue";
import VueRouter from "vue-router";
import largeScreen from "./LargeScreen";
import monitorManage from "./MonitorManage";
import userManage from "./UserManage";
import dataManage from "./DataManage";
import videoManage from "./VideoManage";
import intercept from "./intercept";
import store from "../store";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);
const constantRoutes = [
    {
        path: "/login",
        name: "login",
        meta: {
            parentId: "login"
        },
        component: () =>
            import ("../views/Login/Login.vue")
    },
    {
        path: "/",
        name: "index",
        redirect: "/largeScreen"
    },
    {
        path: "/notPage",
        name: "notPage",
        component: () =>
            import ("../views/NotPage.vue")
    }
];
const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes: [
        ...constantRoutes,
        ...largeScreen,
        ...monitorManage,
        ...userManage,
        ...dataManage,
        ...videoManage
    ]
});
router.beforeEach(intercept.beforeEach);
router.afterEach(intercept.afterEach);

export default router;