<template>
    <div class="recursion-tree" @click.capture='clickNodeWrap'>
        <ul>
            <li v-for="(item, index) in treeData" class="tree-item-container" :key="index">
				<div
                    class="tree-item"
                    :class="[item.active?'tree-item-active':'', item.disabled?'tree-item-disabled':'']"
                    :style="{paddingLeft: treeIndex + 0.3 + 'rem'}"
                    :id="'treeItem'+item[treeProps.value]"
                    data-type="tree-item"
                    :data-index="index"
                    @click.capture="(e) => {clickNode(e, item)}"
                    @dblclick.stop="toggleNode(item)"
                >
                    <span
                            v-if="item[treeProps.children]"
                            class="tree-expand iconfont"
                            :class="item.expand?'vehicle-item-expand':'vehicle-item-shrink'"
                            data-type="tree-extend"
                            :data-index="index"
                    >
                    </span>
                    <span
                        v-if="item.checkBox !== null && item.checkBox !== undefined ? item.checkBox: checkBox"
                        class="tree-item-checkbox iconfont"
                        :class="item.checked?'iconCheckboxChecked-1-copy':item.partchecked?'iconindeterminatecheckbox-copy':'iconCheckboxUnchecked'"
                        data-type="tree-check"
                        :data-index="index"></span>
                    <span
                            class="tree-item-text"
                            :class="[{
                                'online-vehicle':item.vehicleStatus == 1,
                                'travel-vehicle':item.vehicleStatus == 2,
                            }]"
                            :title="item[treeProps.label]"
                    >{{item[treeProps.label]}}</span>
                </div>
                <VehicleRecursionTree2
                    v-if="item[treeProps.children]"
                    v-show="item.expand"
                    :treeData="item[treeProps.children]"
                    :tree-index="treeIndex + 1"
                    :treeProps="treeProps"
                    :checkBox="checkBox"
                    :checkedLength="checkedLength"
                    :maxLength="maxLength"
                    :pageType="pageType"
                    @treeClick="treeClick"
                    @treeChoose="treeChoose"
                    @treeExtend="treeExtend"
                />
            </li>
        </ul>
    </div>
</template>

<script>
    import {simpleVehicle} from "@/api/index";

    var timer = null;
    export default {
        name: "VehicleRecursionTree2",
        props: ["treeData", "treeIndex", "treeProps", "checkBox", "checkedLength", "maxLength", "pageType"],
        computed: {
            vehicles(){
                return JSON.parse(JSON.stringify(this.$store.state.vehicles));
            } 
        },       
        methods: {
            clickNode(e, item) {
                // 单击行
                let that = this;
                let type = e.target.dataset.type;
                if (type == "tree-check") {
                    if (this.clickTimer) {
                        clearTimeout(this.clickTimer);
                        this.clickTimer = null;
                        return;
                    }
                    this.clickTimer = setTimeout(() => {
                        that.$set(item, "active", true);						
                        if (
                            this.pageType == "velAssign" ||
                            this.pageType == "ruleVelTree" ||
                            this.pageType == "velAssignSms"
                        ) {
                            that.checkBoxClick2(item);
                        } else {
                            that.checkBoxClick(item);
                        }
                        this.clickTimer = null;
                    }, 50);
                } else if (type == "tree-extend") {
                    this.toggleNode(item);
                } else {
                    // 单击行
                    clearTimeout(timer);
                    that.$set(item, "active", true);
                    timer = setTimeout(() => {
                        that.$emit("treeClick", "itemClick", item);
                    }, 300);
                }
            },
            toggleNode(item) {
                if (item.type == 2) {
                    return;
                }
                // 双击行或展开行
                clearTimeout(timer);
                this.$set(item, "active", true);        
                this.$emit("treeExtend", {data:item,flag:true,callback:(vehicles)=>{
                    if (!vehicles.length) {
                        this.$toast("该组织下无车辆");
                        return;
                    }                    
                    this.$set(item, "expand", !item.expand); 
                    this.$forceUpdate();  
                }});                
            },
            clickNodeWrap() {
                // 选中行高亮
                var that = this;
                var modifyDataFun = function (datas) {
                    if (datas) {
                        datas.forEach((m) => {
                            that.$set(m, "active", false);
                            if (m[that.treeProps.children]) {
                                modifyDataFun(m[that.treeProps.children]);
                            }
                        });
                    }
                };
                modifyDataFun(this.treeData);
            },
            getAllVehicles(item) {
                return new Promise((resolve => {
                    let params = {
                        entId: item.value,
                        isCache: true,
                        selectOrgOwnVeh: false
                    };
                    if (this.pageType.indexOf("terminalParam") > -1) {
                        params.moreFields = "pt";
                    }
                    let arr = [];
                    simpleVehicle(params).then(res => {
                        if (res.code === 0 && res.result.length) {
                            if (this.pageType == "user") {
                                res.result.forEach(vehicle => {
                                    arr.push(vehicle.v1);
                                });
                            } else if (this.pageType == "terminalParam") {
                                res.result.forEach(itm => {
                                    if (itm.pt != 17 && itm.pt != 18 && itm.pt != 10) {
                                        arr.push(itm);
                                    }
                                });
                            } else if (this.pageType == "terminalParamYue") {
                                item.o7 = 0;
                                res.result.forEach(itm => {
                                    if (itm.pt == 17) {
                                        item.o7++;
                                        arr.push(itm);
                                    }
                                });
                            } else if (this.pageType == "terminalParamZhe") {
                                item.o7 = 0;
                                res.result.forEach(itm => {
                                    if (itm.pt == 18) {
                                        item.o7++;
                                        arr.push(itm);
                                    }
                                });
                            } else if (this.pageType == "terminalParamGui") {
                                item.o7 = 0;
                                res.result.forEach(itm => {
                                    if (itm.pt == 10) {
                                        item.o7++;
                                        arr.push(itm);
                                    }
                                });
                            }
                        }
                        resolve(arr);
                    });
                }));
            },          
            async checkBoxClick(item) {
                const that = this;
                let vehicles = "";
                if (item.type == 1 && (this.pageType.indexOf("terminalParam") > -1 || this.pageType == "user")) {
                    vehicles = await this.getAllVehicles(item);
                    if (this.pageType.indexOf("terminalParam") > -1 && !vehicles.length) {
                        this.$toast("该组织下无车辆");
                        return;
                    }
                }
                if (!item.checked && this.maxLength) {
                    if (item.type == 1 && this.checkedLength + item.o7 > this.maxLength) {
                        this.$toast("最多只能选择" + this.maxLength + "辆车");
                        return;
                    } else if (item.type == 2 && this.checkedLength == this.maxLength) {
                        this.$toast("最多只能选择" + this.maxLength + "辆车");
                        return;
                    }
                }
                
                if (item.disabled) {
                    return;
                }
                that.$set(item, "checked", !item.checked);
                //设置子元素是否勾选
                var checkChildFun = (childrenDatas) => {
                    childrenDatas.forEach((m) => {
                        that.$set(m, "checked", item.checked);
                        if (m[this.treeProps.children]) {
                            checkChildFun(m[this.treeProps.children]);
                        }
                    });
                };
                if (item[this.treeProps.children]) {
                    checkChildFun(item[this.treeProps.children]);
                }
				// 设置父元素是否勾选
				//item.pathIds
				var checkParentFun = (treeData) =>{
					
				}
				checkParentFun(this.treeData);
                this.$forceUpdate();
                this.$emit("treeChoose", item, vehicles);
            },
            checkBoxClick2(item) {
                var that = this;
                if (item.disabled) {
                    return;
                }
                if (item.type == 1) {
                    if (this.maxLength && this.checkedLength + item.o7 > this.maxLength) {
                        this.$toast("最多只能选择" + this.maxLength + "辆车");
                        return;
                    }
                    that.$set(item, "partchecked", !item.partchecked);
                } else {
                    if (this.maxLength && this.checkedLength == this.maxLength) {
                        this.$toast("最多只能选择" + this.maxLength + "辆车");
                        return;
                    }
                    that.$set(item, "checked", !item.checked);
                }
                //设置子元素是否勾选
                var checkChildFun = (childrenDatas) => {
                    childrenDatas.forEach((m) => {
                        if (m.type == 1) {
                            that.$set(m, "partchecked", item.partchecked);
                            if (m[this.treeProps.children]) {
                                checkChildFun(m[this.treeProps.children]);
                            }
                        } else {
                            if (!m.disabled) {
                                that.$set(m, "checked", item.partchecked);
                            }
                        }
                    });
                };
                if (item[this.treeProps.children]) {
                    checkChildFun(item[this.treeProps.children]);
                }
                this.$emit("treeChoose", item);
            },
            treeClick(handler, item) {
                this.$emit("treeClick", handler, item);
            },
            treeChoose(item, vehicles) {
                this.$emit("treeChoose", item, vehicles);
            },
            treeExtend(item) {
                this.$emit("treeExtend", item);
            }
        },
    };
</script>

<style scoped lang="less">
    .recursion-tree {
        cursor: pointer;

        .tree-item {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 14px;
            width: 100%;
            line-height: 20px;
            height: 28px;
            padding-right: 5px;
        }

        .tree-expand {
            height: 100%;
            width: 15px;
            transition: transform 0.1s linear;
        }

        .vehicle-item-expand {
            background: url("../../assets/images/common/select_icon.png") no-repeat center;
            background-size: 100%;
        }

        .vehicle-item-shrink {
            background: url("../../assets/images/common/select_icon.png") no-repeat center;
            background-size: 100%;
            transform: rotate(-90deg);
            height: 15px;
        }

        .tree-item-text {
            margin-right: 5px;
        }

        .tree-item-disabled {
            color: #ddd;

            .tree-item-checkbox {
                color: #ddd;
            }
        }
    }
</style>
