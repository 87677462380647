<template>
    <div id="app">
        <router-view v-if="appShow"/>
        <JGPlatForm
            ref="jgPlatForm"
            :jgPlatClass="jgPlatClass"
            :jgPlatOption="jgPlatOption"
        />
    </div>
</template>
<script>
    import {generalList, dictionaryOem, iconList, alarmAuthList, queryTreeOwn} from "@/api/userManage";
    import {queryAllAlarmType, getOrg, queryUser,queryAllVehicleTree,queryAllTerminalTree} from "@/api/index";
    import JGPlatForm from "./components/common/JGPlatForm";

    export default {
        name: "App",
        components: {JGPlatForm},
        computed: {
            isLogin() {
                return this.$store.state.isLogin;
            }
        },
        data() {
            return {
                jgPlatTimer: "",
                jgPlatClass: "",
                jgPlatOption: "",
                treeData: [{
                    label: "监控中心",
                    type: 1,
                    value: "-1",
                    expand: true,
                    children: []
                }],
                appShow: false,
                connectNum: 0,
                connectNumMsg: 0
            };
        },
        watch: {
            isLogin(val) {
                if (val) {
                    this.getLoginInfo(() => {
                        this.setLocalStorage();
                        this.connectSocket();
                    });
                } else {
                    if (this.$jgPlatformQuery) {
                        clearTimeout(this.jgPlatTimer);
                        this.$jgPlatformQuery.close();
                    }
                }
            }
        },
        created() {
            //this.getAllVehiclesAndOrgs();
            this.$store.commit("setMonitorCrumbsList", []);
            this.$store.commit("setUserCrumbsList", []);
            let dataCrumbsList = this.$store.state.dataCrumbsList
            dataCrumbsList = dataCrumbsList.filter(item => item.isLock)
            this.$store.commit("setDataCrumbsList", dataCrumbsList);
            const clientWidth = document.body.clientWidth;
            let htmlDom = document.getElementsByTagName("html")[0];
            const scale = (clientWidth / 1920) * 20;
            htmlDom.style.fontSize = scale + "px";
            htmlDom = null;
            let that = this;
            window.addEventListener("storage", function (e) {
                if (e.key == "isJGPlatForm" && e.newValue == "true") {
                    that.$refs.jgPlatForm.close();
                } else if (e.key == "isLogin" && e.newValue == "false") {
                    that.$router.push("/login");
                } else if (e.key == "projectTitle") {
                    $("#projectTitle").text(JSON.parse(e.newValue));
                    $("#largeTitle").text(JSON.parse(e.newValue));
                    $("#monitorTitle").text(JSON.parse(e.newValue));
                    $("#userTitle").text(JSON.parse(e.newValue));
                    $("#dataTitle").text(JSON.parse(e.newValue));
                }
            });
        },
        mounted() {
            let token = localStorage.getItem("Authorization");
            if (!token) {
                this.appShow = true;
                return;
            }
            let hash = window.location.hash;
            if (this.isLogin && hash !== "#/video") {
                this.getLoginInfo(() => {
                    this.setLocalStorage();
                    this.connectSocket();
                });
            } else {
                this.appShow = true;
                this.$router.push("/login");
            }
            window.onbeforeunload = function () {
                if (this.$jgPlatformQuery) {
                    clearTimeout(this.jgPlatTimer);
                    this.$jgPlatformQuery.close();
                }
            };
        },
        destroyed() {
            clearTimeout(this.jgPlatTimer);
            this.$jgPlatformQuery.close();
        },
        methods: {
            setLocalStorage() {
                this.$local.remove("allAlarmType");
                this.$local.remove("dictionaryOem");
                this.$local.remove("vehicleBrandArr");
                //this.$local.remove("loginInfo");
                this.getQueryAllAlarmType();
                this.getGeneralList();
                this.getDictionaryOem();
                this.getOrgTree();
                this.getIconList();
                // this.getAlarmCodes();
                this.getAlarmTree();                
            },
            connectSocket() {
                let that = this;
                this.$webSocket.ws({
                    type: "jgPlatformQuery",
                    onopened: () => {
                        setTimeout(() => {
                            let href = location.hash.split("/").pop();
                            let routes = that.$router.options.routes;
                            let currentPage = that.checkPage(href, routes);
                            that.setClassName(currentPage);
                        }, 500);
                        that.msgJGPlatformQuery(that);
                        that.sendJGPlatformQuery();
                    },
                    onerror: () => {
                        that.socketClose(that);
                    },
                    onclose: () => {
                        that.socketClose(that);
                    }
                });
            },
            
            socketClose(that) {
                if (this.$route.name == "login") return;
                if (that.connectNum < 3) {
                    setTimeout(() => {
                        that.connectSocket();
                    }, 1000);
                    that.connectNum++;
                }
            },
            sendJGPlatformQuery() {
                this.jgPlatTimer = setTimeout(this.sendJGPlatformQuery, 5000);
                let params = {
                    type: "jgPlatformQuery",
                    state: 0
                };
                this.$jgPlatformQuery.send(JSON.stringify(params));
            },
            msgJGPlatformQuery(that) {
                this.$jgPlatformQuery.onmessage = (data) => {
                    let res = JSON.parse(data.data);
                    //{"data":{"data":"[270]","type":"orderRemindPush"},"state":1,"type":"jgPlatformQuery"}
                    if(res.state == 1 && res.data != "{}"){
                        switch (res.type) {
                            case 'jgPlatformQuery':
                                if(res.data.contentType && res.data.contentType=='orderRemindPush'){
                                    if(res.data.content && res.data.content.length){
                                        var data = JSON.parse(res.data.content);
                                        // 区分两次推过来同一条记录，hasNewMsg数据相同，watch “hasNewMsg”时不执行
                                        data.push(Date.now());
                                        this.$store.commit("setHasNewMsg",data);
                                        console.log(this.$store.state.hasNewMsg);
                                    }
                                }else{
                                    that.jgPlatOption = res.data;
                                    that.$refs.jgPlatForm.init();
                                }
                        
                                break;  
                            default:
                                break;
                        }
                    }
                };
            },

            getLoginInfo(callback) {
                let Authorization = this.$local.get("Authorization");
                queryUser({haveMenu: true, Authorization: Authorization}).then(res => {
                    if (res.code == 0) {
                        // 记录菜单以map形式存储，方便在v-has里使用
                        if(res.result.menuInfos.length){
                            let map = new Map();
                            res.result.menuInfos.forEach(item=>{
                                map.set(item.menuId,item);
                            });
                            res.result.menuInfosMap = map;
                        }
                        this.$store.commit("setLoginInfo", res.result);
                        callback();
                    }
                    this.appShow = true;
                });
            },
            getIconList() {
                iconList().then(res => {
                    if (res.code === 0) {
                        this.$store.commit("setIconList", res.result);
                    }
                });
            },
            getAlarmCodes() {
                alarmAuthList().then(res => {
                    if (!res.code && res.result) {
                        let alarmCodes = [];
                        res.result.forEach(item => {
                            alarmCodes.push(item.alarmCode);
                        });
                        this.$store.commit("setAlarmCodes", alarmCodes);
                    }
                });
            },
            getAlarmTree() {
                queryTreeOwn().then(res => {
                    if (!res.code) {
                        this.$store.commit("setAlarmTree", res.result);
                    }
                });
            },
            getQueryAllAlarmType() {
                queryAllAlarmType().then(res => {
                    if (res.code === 0) {
                        this.$store.commit("setAllAlarmType", res.result);
                    }
                });
            },
            getGeneralList() {
                let classTypes = ["1", "2", "3", "4", "5", "6", "9", "10", "11", "12", "13", "14"];
                generalList(classTypes).then(res => {
                    if (res.code == 0) {
                        let result = res.result,
                            vehiclePlateColorArr = [],
                            vehicleTypeArr = [],
                            vehicleIndustryArr = [],
                            vehicleBrandArr = [],
                            equipmentTypeArr = [],
                            protocolTypeArr = [],
                            audioCodeArr = [],
                            vehicleColorArr = [],
                            service809Arr = [],
                            service808Arr = [],
                            serviceComtorArr = [],
                            templateType = [];
                        result.forEach(item => {
                            if (item.classType == "5") {
                                // 车牌颜色选项
                                let vehiclePlateColor = {
                                    label: item.className,
                                    value: item.classValue
                                };
                                vehiclePlateColorArr.push(vehiclePlateColor);
                            } else if (item.classType == "1") {
                                // 车辆类型选项
                                let vehicleType = {
                                    label: item.className,
                                    value: item.classValue
                                };
                                vehicleTypeArr.push(vehicleType);
                            } else if (item.classType == "2") {
                                // 运输行业类别
                                let vehicleIndustry = {
                                    label: item.className,
                                    value: item.classValue
                                };
                                vehicleIndustryArr.push(vehicleIndustry);
                            } else if (item.classType == "6") {
                                // 车辆品牌选项
                                let vehicleBrand = {
                                    label: item.className,
                                    value: item.classValue
                                };
                                vehicleBrandArr.push(vehicleBrand);
                            } else if (item.classType == "4") {
                                // 设备类型选项
                                let equipmentType = {
                                    label: item.className,
                                    value: item.classValue
                                };
                                equipmentTypeArr.push(equipmentType);
                            } else if (item.classType == "3") {
                                // 终端协议类型选项
                                let protocolType = {
                                    label: item.className,
                                    value: item.classValue
                                };
                                protocolTypeArr.push(protocolType);
                            } else if (item.classType == "9") {
                                // 音频编码选项
                                let audioCode = {
                                    label: item.className,
                                    value: item.classValue
                                };
                                audioCodeArr.push(audioCode);
                            } else if (item.classType == "10") {
                                // 音频编码选项
                                let vehicleColor = {
                                    label: item.className,
                                    value: item.classValue
                                };
                                vehicleColorArr.push(vehicleColor);
                            } else if (item.classType == "11") {
                                // 转发服务ID
                                let serverId = {
                                    label: item.className,
                                    value: item.classValue * 1
                                };
                                service809Arr.push(serverId);
                            } else if (item.classType == "12") {
                                // 转发服务ID
                                let serverId = {
                                    label: item.className,
                                    value: item.classValue * 1
                                };
                                service808Arr.push(serverId);
                            } else if (item.classType == "13") {
                                // 模板类型
                                let templateId = {
                                    label: item.className,
                                    value: item.classValue * 1
                                };
                                templateType.push(templateId);
                            } else if (item.classType == "14") {
                                // 模板类型
                                let comtorId = {
                                    label: item.className,
                                    value: +item.classValue
                                };
                                serviceComtorArr.push(comtorId);
                            }
                        });
                        vehicleTypeArr.sort((a,b)=>{
                            return parseInt(a.value)-parseInt(b.value);
                        });
                        this.$local.set("vehiclePlateColorArr", vehiclePlateColorArr);
                        this.$local.set("vehicleTypeArr", vehicleTypeArr);
                        this.$local.set("vehicleIndustryArr", vehicleIndustryArr);
                        this.$store.commit("setVehicleBrandArr", vehicleBrandArr);
                        this.$local.set("equipmentTypeArr", equipmentTypeArr);
                        this.$local.set("protocolTypeArr", protocolTypeArr);
                        this.$local.set("audioCodeArr", audioCodeArr);
                        this.$local.set("vehicleColorArr", vehicleColorArr);
                        this.$local.set("service809Arr", service809Arr);
                        this.$local.set("service808Arr", service808Arr);
                        this.$local.set("templateType", templateType);
                        this.$local.set("serviceComtorArr", serviceComtorArr);
                    }
                });
            },
            getDictionaryOem() {
                dictionaryOem().then(res => {
                    if (res.code == 0) {
                        this.$store.commit("setDictionaryOem", res.result);
                    }
                });
            },
            getOrgTree() {
                var params = {
                    entName: "",
                    choiceState: 3
                };
                getOrg(params).then((res) => {
                    if (res.code == 0) {
                        this.$store.commit("setOrgTreeList", res.result);
                    }
                });
            },
            checkPage(href, routes) {
                var currentRoute = "";
                for (let i = 0; i < routes.length; i++) {
                    if (routes[i].name == href) {
                        return routes[i];
                    } else {
                        if (routes[i].children && routes[i].children.length > 0) {
                            currentRoute = this.checkPage(href, routes[i].children);
                            if (currentRoute) {
                                return currentRoute;
                            }
                        }
                    }
                }
            },
            setClassName(currentPage) {
                if (!currentPage) {
                    this.jgPlatClass = "large-screen-jgPlat";
                    return;
                }
                switch (currentPage.meta && currentPage.meta.parentId) {
                    case "largeScreen":
                        this.jgPlatClass = "large-screen-jgPlat";
                        break;
                    case "monitorManage":
                        let currentSkin = this.$store.state.userConfig.monitorSkin;
                        if (currentSkin == "heiseshangwu" || currentSkin == "shenhaiweilan") {
                            this.jgPlatClass = "monitor-manage-jgPlat";
                        }
                        break;
                    case "userManage":
                        let currentSkin2 = this.$store.state.userConfig.userSkin;
                        if (currentSkin2 == "heiseshangwu" || currentSkin2 == "shenhaiweilan") {
                            this.jgPlatClass = "monitor-manage-jgPlat";
                        }
                        break;
                    case "dataManage":
                        let currentSkin3 = this.$store.state.userConfig.userSkin;
                        if (currentSkin3 == "heiseshangwu" || currentSkin3 == "shenhaiweilan") {
                            this.jgPlatClass = "monitor-manage-jgPlat";
                        }
                        break;
                }
            },
            getAllVehiclesAndOrgs() {
                // 获取所有组织并转成树状结构
                this.$Foo.getAllOrgTree(this);
                // 获取所有的车辆和对应终端的信息
                this.$Foo.getVehiclesAndTerminal(this);
            },

            connectSocketMsg() {
                let that = this;
                this.$webSocket.ws({
                    type: "order",
                    onopened: () => {
                        that.receiveMsg();
                        that.sendMsg();
                    },
                    onerror: () => {
                        that.socketMsgClose();
                    },
                    onclose: () => {
                        that.socketMsgClose();
                    }
                });
            },
            socketMsgClose() {
                if (this.$route.name == "login") return;
                if (this.connectNumMsg < 3) {
                    setTimeout(() => {
                        this.connectSocketMsg();
                    }, 1000);
                    this.connectNumMsg++;
                }
            },
            sendMsg() {
                this.jgPlatTimer = setTimeout(this.sendMsg, 5000);
                let params = {
                    type: "order",
                    state: 0
                };
                this.$order.send(JSON.stringify(params));
            },
            receiveMsg() {
                this.$order.onmessage = (data) => {
                    let res = JSON.parse(data.data);
                    if (res.type == "order") {
                        if (res.state == 1) {
                            if (res.data != "{}") {
                                // 请求接口
                            }
                        }
                    }
                };
            },

		
		}
    };
</script>
<style lang="less">
    /*@font-face {
        font-family: "ChuanQiTeZhan-ZT";
        src: url(assets/font/chuangqitezhanti/chuanqitezhan-zt.ttf);
    }

    @font-face {
        font-family: "PingFang-ZT";
        src: url(assets/font/pingfang/pingfangheiti-zhun-jian.ttf);
    }

    @font-face {
        font-family: "DS-Digi-ZT";
        src: url(assets/font/DS-DIGI/ds-digi.ttf);
    }*/

    * {
        padding: 0;
        margin: 0;
        list-style: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        /*font-family: PingFang-ZT;*/
        font-family: Microsoft YaHei,微软雅黑,Microsoft JhengHei,华文细黑,STHeiti,MingLiu;
    }

    a {
        text-decoration: none;
    }

    html {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    body {
        width: 100%;
        height: 100%;
        /*-moz-user-select: text; !*火狐*!*/
        /*-webkit-user-select: none; !*webkit浏览器*!*/
        /*-ms-user-select: none; !*IE10*!*/
        /*user-select: none;*/
        font-size: 0.8rem;
        padding-right: 0 !important;
        color: #fff;
    }

    #app {
        height: 100%;
    }
</style>
