import utils from "../../../utils";
import store from "../../../store";


export default {
    realtimeMonitor: {
        tableHeader: [
            {
                prop: "v6",
                label: "状态",
                width: utils.setFontSize(60),
                isCenter: true,
                render: row => {
					let {icon, className} = utils.setIconColorStatus(row.vbs);	
					return `<span class="${className}"><i class="iconfont icon${icon}"></i></span>`;

                }
            },
			{
			    prop: "v2",
			    label: "车牌号",
			    width: utils.setFontSize(110),
			},
            {
                prop: "vbs",
                label: "在线状态",
                width: utils.setFontSize(100),
				tooltipDefine: true,
                render: row => {
					let {status} = utils.setIconColorStatus(row.vbs);
                    return status;
                }
            },	
            {
                prop: "bs",
                label: "点火状态",
                width: utils.setFontSize(100),
                render: (row) => {
					let baseStateDecode = row.bs && row.bs.b1? row.bs.b1:'--';
					return baseStateDecode;
                }
            },	
            {
                prop: "t2",
                label: "行驶速度",
                width: utils.setFontSize(110),
				tootip: false,
				render: (row) => {
					return  row.t2>-1?row.t2+' km/h':'--';					
				}
            },	
            {
                prop: "h",
                label: "行驶方向",
                width: utils.setFontSize(90),
                render: (row) => {
					return  utils.getDriection(row.h);					
				}
            },
            {
                prop: "t3",
                label: "定位时间",
                width: utils.setFontSize(180)
            },
            {
                prop: "m2",
                label: "经度",
                width: utils.setFontSize(100),
                render: function (row) {
                    return row.m2 ? (row.m2 / 600000).toFixed(5) : "--";
                }
            },
            {
                prop: "m1",
                label: "纬度",
                width: utils.setFontSize(100),
                render: function (row) {
                    return row.m1 ? (row.m1 / 600000).toFixed(5) : "--";
                }
            },			
			{
			    prop: "d1",
			    label: "驾驶员(登签)",
			    width: utils.setFontSize(180),
			    render: function (row) {
			        let d1 = row.d1 || "";
			        let lc = row.lc || "";
			        if (d1 && lc) {
			            return d1 + "(" + lc + ")";
			        } else {
			            return "--";
			        }
			    }
			},
			{
			    prop: "alm",
			    label: "当前报警",
			    width: utils.setFontSize(150),
			    colDisabled: true,
			},
			{
			    prop: "address",
			    label: "地理位置",
			    width: utils.setFontSize(300),
                tooltip: true,
			    render: function (row) {
			        return !utils.isEmpty(row.address) ? row.address : "--";
			    }
			},
            {
                prop: "t9",
                label: "终端总里程",
                width: utils.setFontSize(125),
                render: function (row) {
                    return row.t9+' km' || "--";
                }
            },	
            {
                prop: "exp",
                label: "自编号",
				display:'none',
                width: utils.setFontSize(150)
            },
            {
                prop: "e2",
                label: "所属组织",
                minWidth: utils.setFontSize(200),
				display:'none',
            },
            {
                prop: "v8",
                label: "SIM卡号",
                minWidth: utils.setFontSize(120),
				display:'none',
            },
            {
                prop: "tl1",
                label: "终端号",
                minWidth: utils.setFontSize(120),
				display:'none',
            },
            {
                prop: "tl2",
                label: "设备型号",
                minWidth: utils.setFontSize(120),
				display:'none',
            },

            {
                prop: "ct",
                label: "接收时间",
                width: utils.setFontSize(170),
				display:'none',
            },
            {
                prop: "p1",
                label: "正反转",
                width: utils.setFontSize(120),
				display:'none',
                render: (row) => {
                    let positionExpand = {
                        "0": "未知",
                        "1": "正转",
                        "2": "反转",
                        "3": "停转"
                    };
                    let p1 = row.p1 && row.p1.split(";")[0].split("=");
                    return p1 && positionExpand[p1[1]] || "--";
                }
            },
            {
                prop: "d2",
                label: "联系电话",
                width: utils.setFontSize(120),
				display:'none',
                render: function (row) {
                    return row.d2 || "--";
                }
            }
        ]
    },
    trackPlay: {
        tableHeader: [
            {
                label: "接收时间",
                prop: "ct",
                display: "block",
                minWidth: utils.setFontSize(170)
            },
            {
                label: "定位时间",
                prop: "g2",
                display: "block",
                minWidth: utils.setFontSize(170)
            },
            {
                label: "行驶速度",
                prop: "g1",
                minWidth: utils.setFontSize(90),
                display: "block",
				tooltip: false,
				render: (row) => {
					return row.g1>=-1?row.g1+' km/h':'--';	
				}
            },
            {
                prop: "h",
                label: "行驶方向",
                width: utils.setFontSize(90),
                render: (row) => {
					return  utils.getDriection(row.h);					
				}
            },
            {
                label: "行驶里程",
                prop: "t1",
                minWidth: utils.setFontSize(120),
                display: "block",
                render: function (row) {
                    return row.t1+' km' || "--";
                }
            },
            {
                label: "终端总里程",
                prop: "m3",
                minWidth: utils.setFontSize(140),
                display: "block",
                render: function (row) {
                    return row.m3+' km' || "--";
                }
            },
            {
                label: "车辆状态",
                prop: "b1",
                display: "block",
                minWidth: utils.setFontSize(180)
            },
            {
                label: "报警信息",
                prop: "a1",
                minWidth: utils.setFontSize(140),
                display: "block"
            },
            {
                label: "卫星数",
                prop: "s1",
                minWidth: utils.setFontSize(70),
                display: "block"
            },
            {
                label: "信号强度",
                prop: "w1",
                minWidth: utils.setFontSize(120),
                display: "block"
            },
            {
                label: "地理位置",
                prop: "address",
                display: "block",
                width: utils.setFontSize(300),
                tooltip: true
            }
        ],
        tableHeader2: [
            {
                label: "开始时间",
                prop: "startTime",
                minWidth: utils.setFontSize(150),
                render: function (row) {
                    return utils.formatDate(row.startTime) || "--";
                }
            },
            {
                label: "结束时间",
                prop: "endTime",
                minWidth: utils.setFontSize(150),
                render: function (row) {
                    return utils.formatDate(row.endTime) || "--";
                }
            },
            {
                label: "停车时长",
                prop: "parkDuration",
                minWidth: utils.setFontSize(200),
                render: function (row) {
                    return utils.getDuration(row.parkDuration) || "--";
                }
            },
            {
                label: "已行驶里程",
                prop: "travelMileage",
                render: function (row) {
                    let travelMileage = "--";
                    if (row.travelMileage > 0) {
                        travelMileage = (row.travelMileage / 10).toFixed(2);
                    }
                    return travelMileage;
                }
            },
            {
                label: "距前停里程(km)",
                prop: "distanceLastPark",
                render: function (row) {
                    let distanceLastPark = "--";
                    if (row.distanceLastPark > 0) {
                        distanceLastPark = (row.distanceLastPark / 10).toFixed(2);
                    }
                    return distanceLastPark;
                }
            },
            {
                label: "地理位置",
                prop: "address",
                width: utils.setFontSize(300),
                tooltip: true
            }
        ],
        tableHeader3: [
            {
                label: "报警类型",
                prop: "alarmCode",
                minWidth: utils.setFontSize(180),
                render(row) {
                    let allAlarmType = store.state.allAlarmType;
                    return allAlarmType[row.alarmCode] || "--";
                }
            },
            {
                label: "报警级别",
                prop: "alarmLevel",
                minWidth: utils.setFontSize(100),
                render(row) {
                    let alarmLevel = {
                        1: "一级",
                        2: "二级"
                    };
                    return alarmLevel[row.alarmLevel] || "--";
                }
            },
            {
                label: "报警来源",
                prop: "alarmSource",
                minWidth: utils.setFontSize(120),
                render(row) {
                    let alarmSource = {
                        1: "终端报警",
                        2: "平台报警",
                        3: "下级平台报警"
                    };
                    return alarmSource[row.alarmSource] || "--";
                }
            },
            {
                label: "速度(km/h)",
                prop: "speed",
                minWidth: utils.setFontSize(120),
                render(row) {
                    return (row.speed || row.speed === 0) ? parseFloat((row.speed / 10).toFixed(1)) + "" : "--";
                }
            },
            {
                label: "报警开始时间",
                prop: "startTime",
                minWidth: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "报警结束时间",
                prop: "endTime",
                minWidth: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "报警位置",
                prop: "address",
                minWidth: utils.setFontSize(250),
                render(row) {
                    return row.address || "--";
                }
            }
        ],
        tableHeader4: [
            {
                label: "组织名称",
                prop: "entName",
                minWidth: utils.setFontSize(180),
                render(row) {
                    return row.entName || "--";
                }
            },
            {
                label: "车牌号",
                prop: "vehicleNo",
                minWidth: utils.setFontSize(100),
                render(row) {
                    return row.vehicleNo || "--";
                }
            },
            {
                label: "驾驶员姓名",
                prop: "driverName",
                minWidth: utils.setFontSize(120),
                render(row) {
                    return row.driverName || "--";
                }
            },
            {
                label: "驾驶员身份证号",
                minWidth: utils.setFontSize(200),
                prop: "idcard",
                render(row) {
                    return row.idcard || "--";
                }
            },
            {
                label: "发证机构",
                prop: "licenceIssuingAuthority",
                minWidth: utils.setFontSize(120),
                render(row) {
                    return row.licenceIssuingAuthority || "--";
                }
            },
            {
                label: "从业资格证号",
                prop: "licenseCode",
                minWidth: utils.setFontSize(200),
                render(row) {
                    return row.licenseCode || "--";
                }
            },
            {
                label: "从业资格证有效期",
                prop: "certificateValidityExpiration",
                minWidth: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.certificateValidityExpiration, "YYYY-MM-DD") || "--";
                }
            },
            {
                label: "读卡结果",
                prop: "icReadResult",
                minWidth: utils.setFontSize(120),
                render(row) {
                    let icReadResult = {
                        0: "读卡成功",
                        1: "读卡失败，原因为卡片密钥认证未通过",
                        2: "读卡失败，原因为卡片已被锁定",
                        3: "读卡失败，原因为卡片被拔出",
                        4: "读卡失败，原因为数据校验错误"
                    };
                    return icReadResult[row.icReadResult] || "--";
                }
            },
            {
                label: "刷卡时间",
                prop: "swipeTime",
                minWidth: utils.setFontSize(250),
                render(row) {
                    return utils.formatDate(row.swipeTime) || "--";
                }
            },
            {
                label: "退卡时间",
                prop: "pullTime",
                minWidth: utils.setFontSize(250),
                render(row) {
                    return utils.formatDate(row.pullTime) || "--";
                }
            },
            {
                label: "合计时长",
                prop: "duration",
                minWidth: utils.setFontSize(250),
                render(row) {
                    return row.duration || "--";
                }
            },
            {
                label: "刷卡位置",
                prop: "swipeAddress",
                minWidth: utils.setFontSize(250),
                render(row) {
                    return row.swipeAddress || "--";
                }
            },
            {
                label: "拔卡位置",
                prop: "pullAddress",
                minWidth: utils.setFontSize(250),
                render(row) {
                    return row.pullAddress || "--";
                }
            }
        ]
    },
    playBackVideo: {
        tableHeader: [{
            label: "通道",
            prop: "channelNum",
            width: utils.setFontSize(60)
        },
            {
                label: "开始时间",
                prop: "startTime",
                render: (row) => {
                    return "20" + row.startTime.slice(0, 2) + "-" + row.startTime.slice(2, 4) + "-" + row.startTime.slice(4, 6) + " " + row.startTime.slice(6, 8) + ":" + row.startTime.slice(8, 10) + ":" + row.startTime.slice(10, 12);
                }
            },
            {
                label: "结束时间",
                prop: "endTime",
                render: (row) => {
                    return "20" + row.endTime.slice(0, 2) + "-" + row.endTime.slice(2, 4) + "-" + row.endTime.slice(4, 6) + " " + row.endTime.slice(6, 8) + ":" + row.endTime.slice(8, 10) + ":" + row.endTime.slice(10, 12);
                }
            },
            {
                label: "音频类型",
                prop: "dataType",
                render: (row) => {
                    if (row.dataType == 0) {
                        return "音视频";
                    } else if (row.dataType == 1) {
                        return "音频";
                    } else if (row.dataType == 2) {
                        return "视频";
                    } else if (row.dataType == 3) {
                        return "视频或者音视频";
                    }
                }
            },
            {
                label: "码流类型",
                prop: "streamType",
                render: (row) => {
                    if (row.streamType == 0) {
                        return "全部";
                    } else if (row.streamType == 1) {
                        return "高清";
                    } else if (row.streamType == 2) {
                        return "流畅";
                    }
                }
            },
            {
                label: "文件大小",
                prop: "fileSize",
                render: (row) => {
                    if (row.fileSize + "") {
                        return row.fileSize == 0 ? "0M" : (row.fileSize / 1024 / 1024).toFixed(2) + "M";
                    } else {
                        return null;
                    }
                }
            },
            {
                label: "上传状态",
                prop: "uploadStatus",
                render: (row) => {
                    if (row.uploadStatus == -1) {
                        return "未上传";
                    } else if (row.uploadStatus == 0) {
                        return "上传中";
                    } else if (row.uploadStatus == 1) {
                        return "上传成功";
                    } else if (row.uploadStatus == 2) {
                        return "上传失败";
                    } else if (row.uploadStatus == 3) {
                        return "上传超时";
                    } else if (row.uploadStatus == 4) {
                        return "暂停上传";
                    } else if (row.uploadStatus == 5) {
                        return "取消上传";
                    }
                }
            },
            {
                label: "操作",
                option: [{
                    label: "播放",
                    name: "video-play-btn",
                    handler: "onPlay",
                    render: (row) => {
                        if (row.isStreamPlay) {
                            return "停止";
                        } else {
                            return "播放";
                        }
                    }
                }, {
                    label: "",
                    handler: "onUpload",
                    name: "uploadStatus",
                    render: (row) => {
                        if (row.uploadStatus == 5) {
                            return "重新上传";
                        } else if (row.uploadStatus == 0) {
                            return "取消上传";
                        } else if (row.uploadStatus == 1) {
                            return;
                        } else {
                            return "上传";
                        }
                    }
                }]
            }
        ],
        tableHeaderDown: [
            {
                label: "车牌号",
                prop: "vehicleNo",
                width: utils.setFontSize(120),
                render(row) {
                    return row.vehicleNo || "--";
                }
            }, {
                label: "通道",
                prop: "channelNum",
                width: utils.setFontSize(60)
            }, {
                label: "开始时间",
                prop: "startTime",
                render(row) {
                    if (row.startTime) {
                        return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss");
                    }
                }
            }, {
                label: "结束时间",
                prop: "endTime",
                render(row) {
                    if (row.endTime) {
                        return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss");
                    }
                }
            }, {
                label: "文件大小",
                prop: "fileSize",
                render(row) {
                    let fileSize = "--";
                    if (row.fileSize && row.fileSize !== 0) {
                        fileSize = (row.fileSize / 1024 / 1024).toFixed(2) + "M";
                    }
                    return fileSize;
                }
            }, {
                label: "上传状态",
                prop: "uploadStatus",
                render: (row) => {
                    if (row.uploadStatus == 0) {
                        return "上传中";
                    } else if (row.uploadStatus == 1) {
                        return "上传成功";
                    } else if (row.uploadStatus == 2) {
                        return "上传失败";
                    } else if (row.uploadStatus == 3) {
                        return "上传超时";
                    } else if (row.uploadStatus == 4) {
                        return "暂停上传";
                    } else if (row.uploadStatus == 5) {
                        return "取消上传";
                    }
                }
            }, {
                label: "操作",
                option: [{
                    label: "",
                    name: "playBtn",
                    handler: "onPlayFile",
                    render: (row) => {
                        if (row.uploadStatus == 1) {
                            if (row.isFilePlay) {
                                return "停止";
                            } else {
                                return "播放";
                            }
                        }
                    }
                },
                    {
                        label: "",
                        name: "downloadStatus",
                        handler: "onDown",
                        render: (row) => {
                            console.log(row);
                            if (row.downloadStatus == 1) {
                                return "下载完成";
                            } else if (row.uploadStatus == 1) {
                                return "下载";
                            } else {
                                return;
                            }
                        }
                    }
                ]
            }
        ],
        tableHeaderSerDown: [{
            label: "车牌号",
            prop: "vehicleNo",
            render(row) {
                return row.vehicleNo || "--";
            }
        }, {
            label: "通道",
            prop: "channelNum"
        }, {
            label: "开始时间",
            prop: "startTime",
            render(row) {
                if (row.startTime) {
                    return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss");
                }
            }
        },
            {
                label: "结束时间",
                prop: "endTime",
                render(row) {
                    if (row.endTime) {
                        return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss");
                    }
                }
            },
            {
                label: "下载状态",
                prop: "upStatus",
                render: (row) => {
                    if (row.uploadStatus == 0) {
                        return "未上传";
                    } else if (row.uploadStatus == 1) {
                        return "上传中";
                    } else if (row.uploadStatus == 2) {
                        return "上传成功";
                    } else if (row.uploadStatus == 2) {
                        return "上传失败";
                    }
                }
            },
            {
                label: "操作",
                option: [{
                    label: "",
                    name: "downloadStatus",
                    handler: "onDown",
                    render: (row) => {
                        if (row.uploadStatus == 2) {
                            return "下载";
                        } else {
                            return "--";
                        }
                    }
                }]
            }
        ]
    },
    alarmManage: {
        formInput: [
            {
                label: "车牌号码:",
                type: "select",
                key: "vid",
                leftLabel: true,
                rightValue: true,
                popperClass: "search-monitor-select monitor-select",
                style: {
                    width: "20%"
                },
                options: []
            },
            {
                label: "报警类型:",
                type: "select",
                key: "alarmCode",
                leftLabel: true,
                rightValue: true,
                popperClass: "search-monitor-select monitor-select",
                display: "none",
                style: {
                    width: "20%"
                },
                options: []
            },
            {
                id: "vehicle_monitor_alarm_query",
                label: "",
                text: "查询",
                type: "button",
                notFormItem: true,
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        tableHeader: [
            {
                label: "所属组织",
                prop: "entName",
                width: utils.setFontSize(180)
            },
            {
                label: "车牌号",
                prop: "vehicleNo",
                width: utils.setFontSize(150)
            },
            {
                label: "开始时间",
                prop: "startTime",
                width: utils.setFontSize(180),
                render: function (row) {
                    if (!row.startTime) {
                        return "";
                    }
                    return utils.utc_date(row.startTime);
                }
            },
            {
                label: "结束时间",
                prop: "endTime",
                width: utils.setFontSize(180),
                render: function (row) {
                    if (!row.endTime) {
                        return "";
                    }
                    return utils.utc_date(row.endTime);
                }
            },
            {
                label: "速度(km/h)",
                prop: "speed",
                width: utils.setFontSize(100),
                render: (row) => {
                    return (row.speed || row.speed === 0) ? parseFloat((row.speed / 10).toFixed(1)) + "" : "--";
                }
            },
            {
                label: "证据",
                prop: "photoShow",
                width: utils.setFontSize(120)
            },
            {
                label: "报警类型",
                prop: "alarmName",
                width: utils.setFontSize(180)
            },
            {
                label: "报警位置",
                prop: "address",
                width: utils.setFontSize(300)
            },
            {
                label: "持续时长",
                prop: "duration",
                width: utils.setFontSize(150),
                render: (row) => {
                    return utils.getDuration(row.duration) || "--";
                }
            },
            {
                name: "",
                label: "操作",
                width: utils.setFontSize(120),
                option: [{
                    label: "处理",
                    handler: "dealAlarmBtn",
                    auth: "monitor_center_alarm_dealwith"
                }]
            }
        ]
    },
    areaManage: {
        addAreaClassItem: [{
            label: "所属分类:",
            type: "saasTree4",
            key: "parentId",
            style: {
                width: "100%"
            },
            rules: [{
                required: true,
                message: "请选择所属分类",
                trigger: "change"
            }]
        },
            {
                label: "分类名称:",
                type: "input",
                key: "fenceName",
                maxLength: 20,
                style: {
                    width: "100%"
                },
                handler: "fenceName",
                rules: [
                    {required: true, message: "请输入分类名称", trigger: "blur"}
                    // {max: 20, message: "最长输入20个字符", trigger: "blur"}
                ]
            }
        ]
    },
    plateColor: [{
        "code": "1",
        "name": "蓝色"
    },
        {
            "code": "2",
            "name": "黄色"
        },
        {
            "code": "3",
            "name": "黑色"
        },
        {
            "code": "4",
            "name": "白色"
        },
        {
            "code": "5",
            "name": "绿色"
        },
        {
            "code": "9",
            "name": "其他"
        }
    ]
};