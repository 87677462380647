<template>
    <div>
        <div class="evidence-list-container" v-if="newEvidenceList.length">
            <el-carousel class="evidence-list" :autoplay="false" :initial-index="initialIndex || 0" >
                <el-carousel-item v-for="(item, index) in newEvidenceList" :key="index" class="carousel-container">
                    <div v-if="item.type == 'img'" class="carousel-item">
                        <img :src="item.url" alt="" :class="item.imgErrorImg ? 'error-img' : ''"
                            @error="(e) => {errorImg(e, item)}">
                        <div v-if="item.imgErrorImg" class="error-hint">图 片 加 载 失 败</div>
                    </div>
                    <div v-else-if="item.type == 'video'" class="carousel-item">
                        <video id="video" controls="controls">
                            <source :src="item.url"/>
                        </video>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div class="iconfont iconclose evidence-close" @click="$emit('evidenceClick')"></div>
        </div>
</div>
</template>

<script>
    import {pullFtpEvidenceStream} from "@/api/index";

    export default {
        name: "SAASEvidenceList",
        props: ["evidenceList", "initialIndex"],
        data() {
            return {
                newEvidenceList: [],
                imgSrc: ""
            };
        },
        methods: {
            errorImg(e, item) {
                item.imgErrorImg = true;
                e.target.src = require("../../assets/images/common/img-error.png");
            }
        },
        mounted() {
            this.evidenceList.forEach(item => {
                if (item.accessUrl) {
                    let arr = item.accessUrl.split(".");
                    if (arr[arr.length - 1] == "bin") {
                        return;
                    }
                    if (item.accessUrl.includes("ftp://")) {
                        let params = {
                            accessUrl: item.accessUrl,
                            id: item.id
                        };
                        pullFtpEvidenceStream(params).then(res => {
                            let tmpDown = "";
                            let obj = "";
                            if (res.type == "image/jpeg") {
                                tmpDown = new Blob([res], {type: "image/jpeg"});
                                obj = {
                                    type: "img",
                                    url: (window.URL || window.webkitURL).createObjectURL(tmpDown),
                                    imgErrorImg: false
                                };
                            } else {
                                tmpDown = new Blob([res], {type: "video/mp4"});
                                obj = {
                                    type: "video",
                                    url: (window.URL || window.webkitURL).createObjectURL(tmpDown)
                                };
                            }
                            this.newEvidenceList.push(obj);
                        });
                    } else {
                        let obj = {};
                        if (item.accessUrl.includes(".jpg") || item.accessUrl.includes(".png") || item.accessUrl.includes(".jpeg")) {
                            obj = {
                                type: "img",
                                url: item.accessUrl,
                                imgErrorImg: false
                            };
                        } else if (item.accessUrl.includes(".mp4")) {
                            obj = {
                                type: "video",
                                url: item.accessUrl
                            };
                        }
                        this.newEvidenceList.push(obj);
                    }
                }
            });
            if(this.newEvidenceList.length){
                let that = this;
                setTimeout(() => {
                    $(".el-carousel__indicator").css("width", 100 / that.newEvidenceList.length + "%");
                }, 300);
            }else{
                this.$toast("暂无证据");
            }

        }
    };
</script>

<style lang="less">
    .evidence-list-container {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 10000;

        .evidence-close {
            margin: 0 auto;
            width: 1.8rem;
            height: 1.8rem;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            text-align: center;
            line-height: 1.8rem;
            font-size: 0.6rem !important;
            flex-wrap: nowrap;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 0.6);
            }
        }

        .evidence-list {
            width: 1000px;
            height: 600px;
            margin: 160px auto 40px;

            .el-carousel__indicators {
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: center;

                .el-carousel__indicator {
                    display: block;
                    max-width: 40px;

                    .el-carousel__button {
                        width: 100%;
                    }
                }
            }

            .el-carousel__container {
                width: 100%;
                height: calc(100% - 30px);

                .el-carousel__arrow {
                    font-size: 18px;
                    background: rgba(0, 0, 0, 0.3);

                    &:hover {
                        background: rgba(0, 0, 0, 0.6);
                    }
                }

                .el-carousel__item {
                    display: flex;
                    justify-content: center;
                }
            }

            .carousel-item {
                width: 100%;
                height: 100%;
                background: #333333;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .error-img {
                    height: calc(100% - 100px);
                }

                .error-hint {
                    height: 100px;
                    text-align: center;
                }

                video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
</style>