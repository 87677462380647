import store from "../store/index";
import comment from "../assets/js/common/comment";
import userComment from "../assets/js/userManage/comment";
import dataComment from "../assets/js/dataManage/comment";
import { queryUser } from "@/api/index";

let whiteList = ["/login", "/video", "/alivideo"]; // 不重定向白名单
const beforeEach = (to, from, next) => {   
    console.log( 'store.state.loginInfo----',store.state.loginInfo);
    const loginInfo  = JSON.parse(localStorage.getItem('loginInfo'));
    if (to.name == 'login') {
        next()
    } else {        
        if (loginInfo) {
            if(!store.state.loginInfo){
                if(loginInfo.menuInfos.length){								
                    let map = new Map();
                    loginInfo.menuInfos.forEach(item=>{
                        map.set(item.menuId,item);
                    });
                    loginInfo.menuInfosMap = map;
                }
                store.commit("setLoginInfo",loginInfo);
            }
            console.log(store.state.loginInfo);
            toPage(to, from, next, loginInfo);
        } else {
            // if (to.path !== "/video") {
            //     getLoginInfo(function(loginInfo) {
            //         toPage(to, from, next, loginInfo);
            //     });
            // } else {
            //     next()
            // }
            next("/login");
        }
    }
};

function toPage(to, from, next, loginInfo) {
    let authorization = localStorage.getItem("Authorization");
    if (authorization && loginInfo) {
        const { menuId } = to.meta;
        if (menuId) {
            const hasPermission = new Map(loginInfo.menuInfosMap).has(menuId);            
            if (!hasPermission) {
                next({ name: "notPage", path: "/notPage" });
            }
        }
        next();
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            next("/login");
        }
    }
}

function getLoginInfo(callback) {
    let Authorization = localStorage.getItem("Authorization");
    queryUser({ haveMenu: true, Authorization: Authorization }).then(res => {        
        if (res.code == 0) {
            // 记录菜单以map形式存储，方便在v-has里使用
            if(res.result.menuInfos.length){
                let map = new Map();
                res.result.menuInfos.forEach(item=>{
                    map.set(item.menuId,item);
                });
                res.result.menuInfosMap = map;
            }
            callback(res.result);
        } else {
            callback()
        }
    });
}

const afterEach = (to, from) => {
    let path = to.path,
        thirdList = "",
        crumbsList = "",
        activeTabIndex = -1,
        setCrumbsList = "";
    if (to.meta.parentId == "monitorManage") {
        store.commit("updateMenu", { headMenu: path });
        // 设置面包屑数据
        comment.menuList.map(item => {
            item.active = false;
            if (item.path == path) {
                item.active = true;
                thirdList = item;
            }
        });
        crumbsList = [...store.state.monitorCrumbsList];
        setCrumbsList = "setMonitorCrumbsList";
    } else if (to.meta.parentId == "userManage") {
        userComment.userMenu.userMenuList.forEach(item => {
            item.children.forEach(itm => {
                itm.active = false;
                if (itm.path == path) {
                    itm.active = true;
                    thirdList = itm;
                }
            });
        });
        crumbsList = [...store.state.userCrumbsList];
        setCrumbsList = "setUserCrumbsList";
    } else if (to.meta.parentId == "dataManage") {
        dataComment.dataMenu.dataMenuList.forEach(item => {
            item.children.forEach(itm => {
                itm.active = false;
                if (itm.path == path) {
                    itm.active = true;
                    thirdList = itm;
                }
            });
        });
        crumbsList = [...store.state.dataCrumbsList];
        setCrumbsList = "setDataCrumbsList";
    }
    for (let i = 0; i < crumbsList.length; i++) {
        if (crumbsList[i]) {
            crumbsList[i].active = false;
            if (thirdList.path == crumbsList[i].path) {
                crumbsList[i].active = true;
                activeTabIndex = i;
            }
        } else {
            crumbsList.splice(i, 1);
            i--;
        }
    }
    if (activeTabIndex != -1) {} else {
        if (thirdList) {
            if (thirdList.path != "/largeScreen" && thirdList.path != "" && thirdList.path != "/largeScreen2") {
                crumbsList.unshift(thirdList);
            }
        }
    }
    if (setCrumbsList) {
        store.commit(setCrumbsList, crumbsList);
    }
};

export default {
    beforeEach: beforeEach,
    afterEach: afterEach
};