<template>
    <div style="width:100%;height: 100%;">
        <div :id="mapId" ref="saasAMap2" style="height: 100%;"></div>
    </div>
</template>

<script>
	export default {
		name: "SAASAMap2",
		data() {
			return {
				map: ""
			};
		},
		props: ["mapId"],
		computed: {
			monitorSkin() {
				return this.$store.state.userConfig.monitorSkin;
			}
		},
		watch: {
			monitorSkin: {
				handler(newVal) {
					if (newVal == "heiseshangwu") {
						this.map.setMapStyle("amap://styles/de358bfdbed9c341185a8ca84aa0d3e9");
					} else if (newVal == "shenhaiweilan") {
						this.map.setMapStyle("amap://styles/a20318f8ed3d53ac45bb7acb1d878f10");
					} else if (newVal == "baisejianyue" || newVal == "tiankongzhilan") {
						this.map.setMapStyle("amap://styles/normal");
					}
				}
			}
		},
		mounted() {
			this.setMap();
		},
		methods: {
			// 初始化地图
			setMap() {
				let mapStyleStr = "";
				if (this.monitorSkin == "heiseshangwu") {
					mapStyleStr = "de358bfdbed9c341185a8ca84aa0d3e9";
				} else if (this.monitorSkin == "shenhaiweilan") {
					mapStyleStr = "a20318f8ed3d53ac45bb7acb1d878f10";
				} else if (this.monitorSkin == "baisejianyue" || this.monitorSkin == "tiankongzhilan") {
					mapStyleStr = "normal";
				}
				this.map = new AMap.Map(this.mapId, {
					resizeEnable: true, //是否监控地图容器尺寸变化
					zoom: 4, //初始化地图层级
					center: [113.397428, 36.90923], //初始化地图中心点
					mapStyle: "amap://styles/" + mapStyleStr
				});
			}
		}
	};
</script>

<style lang="less">
    #mapGaode2 {
        background: transparent !important;
    }
</style>
