export default [{
    path: "/userManage",
    name: "userManage",
    redirect: "/user",
    component: () =>
        import ("@/views/UserManage/Index.vue"),
    meta: {
        menuId: 'user_center_user_account'
    },
    children: [{
            path: "/organization",
            name: "organization",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_base_org'
            },
            component: () =>
                import ("@/views/UserManage/baseInfoManage/Organization.vue")
        }, {
            path: "/vehicle",
            name: "vehicle",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_base_vehicle'
            },
            component: () =>
                import ("@/views/UserManage/baseInfoManage/vehicle/index.vue")
        }, {
            path: "/dictionaries",
            name: "dictionaries",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_base_dictionaries'
            },
            component: () =>
                import ("@/views/UserManage/baseInfoManage/Dictionaries.vue")
        }, {
            path: "/driver",
            name: "driver",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_base_driver'
            },
            component: () =>
                import ("@/views/UserManage/baseInfoManage/Driver/index.vue")
        }, {
            path: "/terminal",
            name: "terminal",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_base_termincompany'
            },
            component: () =>
                import ("@/views/UserManage/baseInfoManage/Terminal/index.vue")
        }, {
            path: "/terminalmodel",
            name: "terminalmodel",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_base_terminmodel'
            },
            component: () =>
                import ("@/views/UserManage/baseInfoManage/TerminalModel/index.vue")
        }, {
            path: "/alarmlevel",
            name: "alarmlevel",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_rule_grade'
            },
            component: () =>
                import ("@/views/UserManage/ruleManage/AlarmLevel/index.vue")
        }, {
            path: "/alarmwarn",
            name: "alarmwarn",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_rule_warn'
            },
            component: () =>
                import ("@/views/UserManage/ruleManage/AlarmWarn/index.vue")
        }, {
            path: "/alarmrules",
            name: "alarmrules",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_rule_arule'
            },
            component: () =>
                import ("@/views/UserManage/ruleManage/AlarmRules/index.vue")
        },
        {
            path: "/role",
            name: "role",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_user_role'
            },
            component: () =>
                import ("@/views/UserManage/systemManage/Role/index.vue")
        }, {
            path: "/user",
            name: "user",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_user_account'
            },
            component: () =>
                //import ("@/views/UserManage/systemManage/UserManage.vue")
                import ("@/views/UserManage/systemManage/UserManagev2.vue")
        }, {
            path: "/userv2",
            name: "userv2",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_user_account'
            },
            component: () =>
                import ("@/views/UserManage/systemManage/UserManagev2.vue")
        }, {
            path: "/configManage",
            name: "configManage",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_config_manage'
            },
            component: () =>
                import ("@/views/UserManage/systemManage/ConfigManage.vue")
        }, {
            path: "/alarmConfig",
            name: "alarmConfig",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_alarm_config'
            },
            component: () =>
                import ("@/views/UserManage/systemManage/AlarmConfig.vue")
        }, {
            path: "/menuConfig",
            name: "menuConfig",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_admin_menu'
            },
            component: () =>
                import ("@/views/UserManage/adminOnly/MenuConfig.vue")
        }, {
            path: "/feedBack",
            name: "feedBack",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_admin_complain'
            },
            component: () =>
                import ("@/views/UserManage/adminOnly/FeedBack.vue")
        }, {
            path: "/accountmanage",
            name: "accountmanage",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_admin_apiaccount'
            },
            component: () =>
                import ("@/views/UserManage/adminOnly/AccountManage.vue")
        }, {
            path: "/noticetemplate",
            name: "noticetemplate",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_user_template'
            },
            component: () =>
                import ("@/views/UserManage/systemManage/NoticeTemplate.vue")
        }, {
            path: "/SMSConfig",
            name: "SMSConfig",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_user_smsConfig'
            },
            component: () =>
                import ("@/views/UserManage/systemManage/SMSConfig.vue")
        }, {
            path: "/videoserver",
            name: "videoserver",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_serve_video'
            },
            component: () =>
                import ("@/views/UserManage/serverManage/VideoServer.vue")
        }, {
            path: "/accessserver",
            name: "accessserver",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_serve_access'
            },
            component: () =>
                import ("@/views/UserManage/serverManage/AccessServer/index.vue")
        }, {
            path: "/forwardserver",
            name: "forwardserver",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_serve_forward'
            },
            component: () =>
                import ("@/views/UserManage/serverManage/ForwardServer/index.vue")
        }, {
            path: "/terminalupgrade",
            name: "terminalupgrade",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_serve_upgrade'
            },
            component: () =>
                import ("@/views/UserManage/serverManage/TerminalUpgrade/index.vue")
        }, {
            path: "/batchdispatch",
            name: "batchdispatch",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_serve_batchdispatch'
            },
            component: () =>
                import ("@/views/UserManage/serverManage/BatchDispatch.vue")
        },
        {
            path: "/SMSServer",
            name: "SMSServer",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_serve_smsServer'
            },
            component: () =>
                import ("@/views/UserManage/serverManage/SMSServer.vue")
        },
        {
            path: "/vehicleLog",
            name: "vehicleLog",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_vehicle_log'
            },
            component: () =>
                import ("@/views/UserManage/logManage/VehicleLog.vue")
        },
        {
            path: "/userLog",
            name: "userLog",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_user_log'
            },
            component: () =>
                import ("@/views/UserManage/logManage/UserLog.vue")
        },
        {
            path: "/orgLog",
            name: "orgLog",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_org_log'
            },
            component: () =>
                import ("@/views/UserManage/logManage/OrgLog.vue")
        },
        {
            path: "/orderLog",
            name: "orderLog",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_order_log'
            },
            component: () =>
                import ("@/views/UserManage/logManage/OrderLog.vue")
        },
        {
            path: "/order", // 工单管理
            name: "order",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_order_list'
            },
            component: () =>
                import ("@/views/UserManage/orderManage/order.vue")
        },
        {
            path: "/orderSearch", // 工单详情搜索
            name: "orderSearch",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_order_list'
            },
            component: () =>
                import ("@/views/UserManage/orderManage/orderSearch.vue")
        },
        {
            path: "/orderExport", // 工单验收记录
            name: "orderExport",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_order_orderExportList'
            },
            component: () =>
                import ("@/views/UserManage/orderManage/orderExport.vue")
        },
        {
            path: "/installer", // 安装工管理
            name: "installer",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_installer'
            },
            component: () =>
                import ("@/views/UserManage/orderManage/installer.vue")
        },
        {
            path: "/disassemblyGps", // 拆机记录
            name: "disassemblyGps",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_order_disassemblyGpsList'
            },
            component: () =>
                import ("@/views/UserManage/orderManage/disassemblyGps.vue")
        },
        {
            path: "/addDeviceList", // 设备采购
            name: "addDeviceList",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_stock_repairDevice'
            },
            component: () =>
                import ("@/views/UserManage/stockManage/addDeviceList.vue")
        },
        {
            path: "/serviceStock", // 服务商库存
            name: "serviceStock",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_stock_spStock'
            },
            component: () =>
                import ("@/views/UserManage/stockManage/serviceStock.vue")
        },
        {
            path: "/deviceStock", // 设备库存
            name: "deviceStock",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_stock_deviceStock'
            },
            component: () =>
                import ("@/views/UserManage/stockManage/deviceStock.vue")
        },
        {
            path: "/logisticalPackage", // 物流包管理
            name: "logisticalPackage",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_stock_logistics'
            },
            component: () =>
                import ("@/views/UserManage/stockManage/logisticalPackage.vue")
        },
        {
            path: "/installerStock", // 安装工库存
            name: "installerStock",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_stock_installerStoce'
            },
            component: () =>
                import ("@/views/UserManage/stockManage/installerStock.vue")
        },
        {
            path: "/prepareStock", // 网点备货
            name: "prepareStock",
            meta: {
                parentId: "userManage",
                menuId: 'user_center_stock_prepareStock'
            },
            component: () =>
                import ("@/views/UserManage/stockManage/prepareStock.vue")
        },


    ]
}];