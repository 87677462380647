<template>
    <div id="dialog" class="jg-plat-dialog"
         :class="jgPlatClass"
    >
        <el-dialog
                title="查岗通知"
                :visible.sync="dialogVisible"
                width="18%"
                top="30vh"
                :before-close="jgPlatClose"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
        >
            <div class="dialog-hint">
                <i class="iconfont icontanhao tanhao-icon"></i>
                <div class="jg-plat-content">{{jgPlatOption.infor}}<br /><input type="text" v-model="answerContent"/></div>
            </div>
            <template slot="footer" class="dialog-footer">
                <el-button @click="jgPlatClose">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </template>
        </el-dialog>
        <SAASDialog
                ref="saasDialog"
                confirmHint="是否取消回复"
                confirmType="cancelAnswer"
                @handleClick="handleClick"
        />
    </div>
</template>

<script>
	import {jgPlatformAnswer} from "@/api/index";

	export default {
		name: "JGPlatForm",
		props: ["jgPlatClass", "jgPlatOption"],
		data() {
			return {
				dialogVisible: false,
				answerContent: "",
				jgPlatDialogTimer: "",
				isClickConfirm: false
			};
		},
		methods: {
			init() {
				this.isClickConfirm = false;
				this.answerContent = "";
				clearTimeout(this.jgPlatDialogTimer);
				localStorage.setItem("isJGPlatForm", false);
				this.dialogVisible = true;
				let that = this,
					params = {
						id: this.jgPlatOption.answerId,
						qId: this.jgPlatOption.qId,
                        seq: this.jgPlatOption.seq,
						answerStatus: "2"
					};
				this.jgPlatDialogTimer = setTimeout(() => {
					that.queryJgPlatAnswer(params);
				}, 300000);
			},
			close() {
				if (this.dialogVisible) {
					this.dialogVisible = false;
					this.$refs.saasDialog.dialogVisible = false
					clearTimeout(this.jgPlatDialogTimer);
				}
			},
			confirm() {
				if (!this.answerContent) {
					this.$toast('回复内容不能为空')
                    return
                }
				let params = {
					id: this.jgPlatOption.answerId,
					qId: this.jgPlatOption.qId,
                    seq: this.jgPlatOption.seq,
					answerContent: this.answerContent,
					answerStatus: '3'
				};
				// params.answerStatus = this.answerContent ? "3" : "2";
				this.dialogVisible = false;
				this.isClickConfirm = true;
				this.queryJgPlatAnswer(params);
			},
			jgPlatClose() {
				if (this.isClickConfirm) {
					return;
				}
				this.$refs.saasDialog.dialogVisible = true;
			},
			handleClick(handler) {
				switch (handler) {
					case "cancelAnswer": {
						this.dialogVisible = false;
						localStorage.setItem("isJGPlatForm", true);
					}
				}
			},
			queryJgPlatAnswer(params) {
				clearTimeout(this.jgPlatDialogTimer);
				jgPlatformAnswer(params).then(res => {
					if (res.code == 0) {
						localStorage.setItem("isJGPlatForm", true);
					}
				});
			}
		}
	};
</script>

<style lang="less">
    .jg-plat-dialog {
        .jg-plat-content {
            align-items: center;

            input {
                height: 24px;
                width: 200px;
                border: 1px solid #ddd;
                padding: 0 4px;
                text-align: center;
                font-size: 14px;
            }
        }
    }

    .large-screen-jgPlat {
        .el-form-item__label, .el-dialog__title, .el-dialog__close, .el-dialog__body {
            color: #ffffff !important;
        }

        .el-input__inner {
            border: 1px solid #18C3FF;
            background: transparent;
            box-shadow: 0 0 18px 1px rgba(25, 133, 239, 0.7) inset;
            color: #ffffff;
        }

        .el-dialog {
            background: url("../../assets/images/largeScreen/model-content-back.png") no-repeat;
            background-size: 100% 100%;

            .el-button {
                background: url("../../assets/images/largeScreen/btn-back.png") no-repeat;
                background-size: 100% 100%;
                border: none;
                color: #ffffff;
                width: auto !important;
            }
        }

        .jg-plat-content {
            input {
                border: 1px solid #21ecf6;
                background: rgba(255, 255, 255, 0.05);
                color: #fff;
            }
        }
    }

    .monitor-manage-jgPlat {
        input {
            background: #26326D;
            border: none !important;
            border-radius: 0;
            color: rgba(255, 255, 255, 0.7);
        }

        .el-dialog {
            background: #18214D;
            border: 1px solid #0232A1;

            .el-form-item__label, .el-dialog__title, .el-dialog__close, .el-dialog__body {
                color: rgba(255, 255, 255, 0.7);
            }

            .el-dialog__header {
                background: #26326D;
                height: 44px;
                line-height: 44px;
                padding: 0 16px;
                font-size: 14px;
            }

            .el-dialog__footer {
                .el-button {
                    background: transparent;
                    border: 0.05rem solid rgba(255, 255, 255, 0.1);
                    color: rgba(255, 255, 255, 0.5);
                }

                .el-button:last-child {
                    background: #3B4CA1;
                }
            }
        }
    }
</style>