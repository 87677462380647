import Vue from "vue";
import store from "../store";

export default {
    ws: (options) => {
        var socket;
        if (typeof(WebSocket) == "undefined") {
            console.log("遗憾：您的浏览器不支持WebSocket");
        } else {
            //实现化WebSocket对象
            //指定要连接的服务器地址与端口建立连接
            //注意ws、wss使用不同的端口。我使用自签名的证书测试，
            //无法使用wss，浏览器打开WebSocket时报错
            //ws对应http、wss对应https。
            if (!options.socket) {
                let loginInfo = store.state.loginInfo || {};
                // ws地址
                // const ws = "wss://www.zgjiyi.com/saas/ws/saas/api/dispatcher-service/web/ws/websocket/";
                // let url = ws + loginInfo.userId + "/" + options.type;
                // socket = new WebSocket(options.url || url);
                let socketName = location.host.indexOf('127.0.0.1')>-1?'wss://':location.protocol == "http:" ? "ws://" : "wss://";
                let url = `${socketName + testhost}` + "/saas/ws/saas/api/dispatcher-service/web/ws/websocket/" + loginInfo.userId + "/" + type;
                socket = new WebSocket(options.url || url);
            } else {
                socket = options.socket;
            }
            //连接打开事件
            socket.onopen = (data) => {
                options.onopened && options.onopened(data);
            };
            // //连接关闭事件
            socket.onclose = (msg) => {
                options.onclose && options.onclose(msg);
            };
            //发生了错误事件
            socket.onerror = (msg) => {
                options.onerror && options.onerror(msg);
            };
            // //窗口关闭时，关闭连接
            window.onbeforeunload = function() {
                socket.close();
            };
            if (options.type) {
                Vue.prototype["$" + options.type] = socket;
            } else {
                Vue.prototype.$socket = socket;
            }
            return socket;
        }
    }
};