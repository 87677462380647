<template>
    <SAASModel
            ref="saasModel"
            title="表格列配置"
            confirm-btn-text="保 存"
            width="40rem"
            top="20vh"
            @handleClick="handleClick"
    >
        <div class="saas-col-set">
            <div class="cols">
                <div class="col-set-content">
                    <el-checkbox
                            class="checkbox-all"
                            :indeterminate="isIndeterminate"
                            v-model="checkAll"
                            @change="handleCheckAllChange"
                    >全选
                    </el-checkbox>
                    <el-checkbox-group v-model="checkedCols" @change="handleCheckedShowCol">
                            <el-checkbox
                                    v-for="item in tableHeader"
                                    :label="JSON.stringify(item)"
                                    :key="item.prop"
                            >
                                {{item.label}}
                            </el-checkbox>    
                    </el-checkbox-group>
                </div>
            </div>
            <div class="cols">
                 <div class="col-set-content">
                    <ul>
                        <draggable
                                :list="checkedCols"
                                :options="draggableOption"
                                class="list-group"
                        >						
							<li
									v-for="(item,index) in checkedCols"
									:key="index"
							>
								{{JSON.parse(item).label}}
							</li>
						</draggable>
                    </ul>
                </div>
            </div>		
		</div>
		<div class="fixedNum">
			<span>固定</span>
			<el-input v-model="fixedNumber" size="small" type="number">
			</el-input><span>列</span>
		</div>		
    </SAASModel>
</template>

<script>
	import draggable from "vuedraggable";

	export default {
		name: "SAASColSet",
		props: ["currentPage"],
		components: {draggable},
		data() {
			return {
				draggableOption: {
					group: "people",
					animation: 150,
					ghostClass: "sortable-ghost",
					chosenClass: "chosenClass",
					scrollSensitivity: 100
				},
				tableHeader: [],
				checkedCols: [],
				isIndeterminate: true,
				checkHideAll: false,
				checkAll: false,
				fixedNumber: 4
			};
		},
		computed: {
			showHideCol() {
				return this.$store.state.showHideCol;
			},
		},
		methods: {
			init(tableHeader) {
				this.tableHeader = [];
				this.tableHeader = this.$utils.deepClone(tableHeader);
				let arr = [];
				this.tableHeader.forEach(v=>{
					if (v.display!=='none'){
						arr.push(JSON.stringify(v));
					}
				});
				this.checkedCols = [...arr];					
				this.$refs.saasModel.dialogVisible = true;
			},
			// 全选和取消全选
			handleCheckAllChange(val) {
				this.checkedCols = [];
				this.isIndeterminate = false;
				if(val){
					this.tableHeader.forEach(v=>{
						this.checkedCols.push(JSON.stringify(v));
					});
				}
			},
			// 单个选中
			handleCheckedShowCol(val,a) {
				let checkedCount = val.length;
				this.checkAll = checkedCount === this.tableHeader.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableHeader.length;
				this.checkedCols = [...val];
			},
			handleClick() {
				let fixedNumber = parseInt(this.fixedNumber);
				if(fixedNumber<0){
					this.$toast("固定列数请输入大于0的数字");
					return;
				}

				let showArr = [],hideArr = [];
				let exitObj = {};
				this.checkedCols.forEach((v,index)=>{
					let v1 = JSON.parse(v);
						
					this.tableHeader.forEach(item=>{
						if(v1.prop===item.prop){
							item.fixed = index+1<=fixedNumber?'left':false;
							item.display = 'block';
							showArr.push(item);
							exitObj[item.prop] = true;
						}
					});
				});
				this.tableHeader.forEach(v=>{
					if(!exitObj[v.prop]){
						v.display = 'none';
						hideArr.push(v);
					}
				});
				let newHeader = [...showArr,...hideArr];
				localStorage.setItem(this.currentPage, JSON.stringify(newHeader));
				this.showHideCol[this.currentPage] = [...newHeader];
				this.$refs.saasModel.dialogVisible = false;
			},
		}
	};
</script>

<style lang="less">
    .saas-col-set {
        height: 500px;
        display: flex;
        align-items: center;

        .cols {
            width: calc(100% - 50px);
            height: 100%;

            .col-set-title {
                font-size: 14px;
                text-align: center;
                height: 20px;
                margin-bottom: 10px;
            }

            .col-set-content {
                height: calc(100% - 30px);
                overflow: auto;
                padding: 10px 18px;
				li{
					line-height: 1.8;
				}
            }

            .checkbox-all {
                line-height: 20px !important;
                padding-bottom: 4px;
            }

            .el-checkbox {
                display: block;
                line-height: 28px;
            }
        }

        .col-set-btns {
            width: 100px;

            div {
                line-height: 40px;
                font-size: 24px;
                text-align: center;
                color: #1F55F7;
            }
        }
    }
	.fixedNum{
		position: absolute;
		*{
			display: inline-block;
		}
		.el-input{
			text-align: center;
			width: 30%;
		}
	}
</style>