<template>
    <div class="saas-tree tree-search-container" :class="className">
        <div class="tree-search">
            <input
                    id="vehicleTreeSearch"
                    class="search-input"
                    autocomplete="off"
                    :placeholder="placeholder"
                    v-model="treeSearchValue"
                    @input="((val)=>{treeInput(val)})"
                    @click.stop="treeInputFocus"
                    @blur="treeInputBlur"
            />
            <div class="search-refresh iconfont iconshuaxin" @click="treeRefresh"></div>
        </div>
        <ul class="search-list" v-if="searchTreeData.list.length && searchListShow">
            <li v-for="item in searchTreeData.list" @click="(e) => {searchItemClick(e, item)}">
                {{ item[treeProps.label] }}
            </li>
        </ul>
        <div class="no-search-tree-data" v-if="searchListShow && noSearchTreeData">暂无数据</div>
        <div ref="treeComponent" class="tree-component" id="treeComponent">
            <div v-if="treeLoading && $store.state.treeLoading" class="tree-loading iconfont iconjiazai">
                <div></div>
            </div>
            <div style="position: absolute;min-width: 100%;">
                <SAASRecursionTree
                        ref="recursionTree"
                        :pageType="pageType"
                        :treeList="treeList"
                        :treeProps="treeProps"
                        :iconList="iconList"
                        :treeIndex="0"
                        @treeClick="treeClick"
                        @treeChoose="treeChoose"
                        @toggleCheckBox="toggleCheckBox"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SAASTree",
        props: {
            placeholder: {
                required: false
            },
            treeList: {
                required: false
            },
            treeProps: {
                type: Object,
                default: function () {
                    return {
                        label: "label",
                        value: "value",
                        children: "children",
                        checkBox: false
                    };
                }
            },
            iconList: {
                type: Object,
                default: function () {
                    return {
                        pullDownIcon: "iconjianshanchu",
                        pullUpIcon: "iconjiatianjia"
                    };
                }
            },
            pageType: {
                required: false
            },
            treeInputText: {
                required: false
            },
            className: {
                required: false
            },
            maxLength: {
                required: false
            },
            treeLoading: {
                type: Boolean,
                default: function () {
                    return true;
                }
            }
        },
        watch: {
            treeInputText(val) {
                this.treeSearchValue = val;
            },
            "$store.state.treeLoading"(val) {
                this.treeDataTile = [];
            }
        },
        data() {
            return {
                treeSearchValue: "",
                timerNum: 0,
                timer: "",
                searchTreeData: {
                    list: []
                },
                treeDataTile: [],
                searchListShow: false,
                noSearchTreeData: false // 搜索无数据
            };
        },
        methods: {
            treeRefresh() {
                this.treeSearchValue = "";
                this.searchTreeData = {
                    list: []
                };
                this.noSearchTreeData = false;
                this.$emit("treeRefresh");
            },
            // TODO:前端本地搜索
            treeSearch(item) {
                if (this.treeDataTile.length == 0) {
                    this.treeDataTile = this.flatTreeData(this.treeList);
                }
                if (item.length > 0) {
                    var data = {
                        list: []
                    };
                    for (var i = 0; i < this.treeDataTile.length; i++) {
                        var it = this.treeDataTile[i];
                        if (it[this.treeProps.label].indexOf(item) > -1) {
                            data.list.push(it);
                        }
                    }
                    if (!data.list.length) {
                        this.noSearchTreeData = true;
                    } else {
                        this.noSearchTreeData = false;
                    }
                    this.searchTreeData = data;
                    data = null;
                } else {
                    this.searchTreeData = {
                        list: []
                    };
                    this.noSearchTreeData = false;
                }
            },
            resetTree(value) {
                var that = this;

                function recursionFun(arr = []) {
                    for (let i = 0; i < arr.length; i++) {
                        that.$set(arr[i], "active", false);
                        if (value.pathIds.indexOf(arr[i].value) != -1) {
                            that.$set(arr[i], "expand", true);
                            that.$set(arr[i], "extend", true);
                        }
                        recursionFun(arr[i][that.treeProps.children]);
                    }
                }

                recursionFun(that.treeList);
            },
            treeSearchById(data) {
                var that = this;

                function recursionFun(treeList = []) {
                    for (let j = 0; j < treeList.length; j++) {
                        let item = treeList[j];
                        if (data[that.treeProps.value] == item[that.treeProps.value]) {
                            that.treeSearchValue = data[that.treeProps.label];
                            that.resetTree(item);
                            setTimeout(function () {
                                let node = $("#tree5" + item[that.treeProps.value]);
                                that.$refs.treeComponent.scrollTop = 0;
                                that.$refs.treeComponent.scrollTop = node.offset().top - $("#treeComponent").offset().top;
                            }, 500);
                            that.$set(item, "active", true);
                            that.$emit("treeClick", "itemClick", item, "bySearch");
                            return true;
                        }
                        if (item[that.treeProps.children] && item[that.treeProps.children].length > 0) {
                            let result = recursionFun(item[that.treeProps.children]);
                            if (result) {
                                return true;
                            }
                        }
                    }
                }

                that.searchListShow = false;
                recursionFun(that.treeList);
            },
            // 将数据拆分成一级
            flatTreeData(arr) {
                let newArr = [];
                for (let i = 0; i < arr.length; i++) {
                    let node = arr[i];
                    if (Array.isArray(node)) {
                        let newData = this.flatTreeData(node);
                        newArr = newArr.concat(newData);
                        newData = null;
                    } else {
                        newArr.push(node);
                    }
                    if (Array.isArray(node[this.treeProps.children])) {
                        let newData = this.flatTreeData(node[this.treeProps.children]);
                        newArr = newArr.concat(newData);
                        newData = null;
                    }
                    node = null;
                }
                return newArr;
            },
            treeInput(e) {
                let that = this;
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(function () {
                    let value = e.target.value;
                    that.treeSearch(value);
                }, 500);
            },
            treeInputFocus() {
                this.searchListShow = true;
                this.$emit("treeInputFocus", "treeInputFocus");
            },
            treeInputBlur() {
                let that = this;
                setTimeout(() => {
                    that.searchListShow = false;
                }, 500);
            },
            treeClick(handler, item) {
                this.$emit("treeClick", handler, item);
            },
            treeChoose(item) {
                this.$emit("treeChoose", item);
            },
            searchItemClick(e, item) {
                this.treeSearchById(item);
            },
            toggleCheckBox() {
                // 勾选某一行时，修改父节点及子节点选中状态
                var that = this;

                function recursionList(item) {
                    var checkedNum = 0;
                    var partCheckedNum = 0;
                    item[that.treeProps.children].forEach((li) => {
                        if (li[that.treeProps.children] && li[that.treeProps.children].length > 0) {
                            recursionList(li);
                        }
                        if (li.checked === true) {
                            checkedNum++;
                        } else if (li.partchecked === true) {
                            partCheckedNum++;
                        }
                    });
                    if (checkedNum === item[that.treeProps.children].length) {	//全选
                        that.$set(item, "checked", true);
                        that.$set(item, "partchecked", false);
                    } else if (checkedNum > 0 || partCheckedNum > 0) {	//部分勾选
                        that.$set(item, "checked", false);
                        that.$set(item, "partchecked", true);
                    } else {	//没有勾选
                        that.$set(item, "checked", false);
                        that.$set(item, "partchecked", false);
                    }
                }

                recursionList(this.treeList[0]);
            }
        }
    };
</script>

<style scoped lang="less">
    .saas-tree {
        height: 100%;
        position: relative;

        .tree-search {
            height: 34px;
            position: relative;
            display: flex;
            line-height: 32px;
            font-size: 14px;
            z-index: 10;

            input {
                height: 100%;

            }

            input::placeholder {
                font-size: 14px;
                color: #cccccc;
            }
        }

        .search-input {
            /* width: calc(100% - 42px);*/
            outline: none;
            position: relative;
            padding: 0 32px 0 8px;
            font-size: 14px;
            flex: 1
        }

        .search-refresh {
            width: 32px;
            height: 100%;
            text-align: center;
            background: #2C7AF8;
            margin-left: 10px;
            cursor: pointer;

            &:active {
                background: #266BDC;
            }
        }

        .search-list {
            position: absolute;
            top: 34px;
            z-index: 100;
            width: 100%;
            margin-top: 5px;
            height: calc(100% - 39px);
            overflow: auto;
            font-size: 14px;
            line-height: 34px;
            border: none;

            li {
                padding-left: 12px;

                &:hover {
                    background: rgba(255, 255, 255, 0.2);;
                }
            }
        }

        .no-search-tree-data {
            position: absolute;
            top: 34px;
            margin-top: 5px;
            z-index: 100;
            width: 100%;
            height: calc(100% - 39px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border: none;
        }

        .tree-component {
            margin-top: 10px;
            height: calc(100% - 44px);
            overflow: auto;
            display: flex;
            position: relative;
        }
    }
</style>
