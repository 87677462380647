<template>
    <div class="saas-log-tree" v-clickoutside="handleClose">
        <el-input
                placeholder=""
                class="suffix"
                v-model="formValueLabel"
                autocomplete="off"
                :disabled="isLogTypeDisabled"
                clearable
				@focus="onResionIsShow"
                @clear="clear()"
                @input="logTreeInput"
                @mouseover.native="inputMouseover"
                @mouseout.native="inputMouseout"
        >
            <i v-if="iconXialaShow" class="input-icon iconfont iconxiala" slot="suffix"></i>
        </el-input>
        <el-tree
                v-if="isTreeShow"
                :data="logData"
				:props="defaultProps"
                :show-checkbox="formProps.showCheckbox"
                ref='tree'
                :filter-node-method="filterNode"
                class="tree"
                @node-click="handleClick"
        >
                <span :class="[data.class?data.class:'','custom-tree-node']" slot-scope="{ node, data }">
                    <span v-if="data[formProps.parentLabel]" :title="data[formProps.parentLabel]">
                        {{data[formProps.parentLabel]}}
                    </span>
                    <span :title="data[formProps.label]" v-else>
                        {{data[formProps.label]}}
                    </span>
                </span>
        </el-tree>
    </div>
</template>

<script>
	import {logTreeUrl} from "@/api/userManage";

	export default {
		name: "SAASLogTree",
		props: ["formValue", "formProps", "isLogTypeDisabled", "category"],
		watch: {
			"filterText"(val) {
				if (this.$refs.tree) {
					this.$refs.tree.filter(val);
				}
			}
		},
		data() {
			return {
				defaultProps: {
					children: 'oprater',
				},
				formValueLabel: "",
				logData: [],
				logName: "",
				filterText: "",
				logIdArr: [],
				logNameArr: [],
				isTreeShow: false,
				iconXialaShow: true
			};
		},
		methods: {
			inputMouseover() {
				if (this.formValueLabel) {
					this.iconXialaShow = false;
				}
			},
			inputMouseout() {
				if (!this.isTreeShow) {
					this.iconXialaShow = true;
				}
			},
			logTreeInput(val) {
				this.filterText = val;
			},
			clear() {
				this.iconXialaShow = true;
				this.formValue.logName = "";
				this.formValue.logCode = "";
				this.formValue.opraterCode = "";
				this.logIdArr = [];
				this.logNameArr = [];
				// this.$emit("handleClick", "logTree");
			},
			handleClose() {
				this.isTreeShow = false;
				this.iconXialaShow = true;
			},
			filterNode(value, data) {
				if (!value) return true;
				return data[this.formProps.label] ? data[this.formProps.label].indexOf(value) !== -1 : data[this.formProps.parentLabel].indexOf(value) !== -1;
			},
			onResionIsShow() {
				this.filterText = "";
				this.isTreeShow = true;
				// this.$nextTick(() => {
				// 	this.$refs.tree && this.$refs.tree.setCheckedKeys(this.logIdArr);
				// });
			},
			getTree() {
				logTreeUrl({}).then(res => {
					if (!res.code) {
						this.logData = res.result;
					}
				});
			},
			handleClick(data, node) {
				if (this.formProps.showCheckbox) {
					return;
				}
				if (data) {
					this.formValueLabel = data[this.formProps.label] ? data[this.formProps.label] : this.formValueLabel;
					if (data[this.formProps.label]) {
						this.isTreeShow = false;
						this.formValue[this.formProps.label] = data[this.formProps.label];
						this.formValue[this.formProps.value] = data[this.formProps.value];
						this.$emit("treeCheckChange", data[this.formProps.value], node.parent.data);
					}
				}
			},
		},
		mounted() {
			this.getTree();
			this.formValueLabel = this.formValue[this.formProps.label];
		}
	};
</script>

<style lang="less">
    .saas-log-tree {
        .el-input--suffix .el-input__inner {
            padding-right: 25px;
        }

        .el-input__inner {
            height: 34px;
        }

        .tree {
            height: 320px;
            z-index: 10;
            overflow: auto;
            -webkit-box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, .1);
            box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, .1);

            .el-tree-node:focus > .el-tree-node__content, .el-tree-node__content:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }

        .el-tree-node > .el-tree-node__children {
            overflow: inherit;
        }
    }
</style>
