export default [{
    path: "/monitorManage",
    name: "monitorManage",
    component: () =>
        import ("@/views/MonitorManage/MonitorManage.vue"),
    children: [{
        path: "/monitorNotAuth",
        name: "monitorNotAuth",
        meta: {
            parentId: "monitorManage"
        },
        component: () =>
            import ("@/views/MonitorManage/MonitorNoAuth.vue")
    },
        {
            path: "/realtimeMonitor",
            name: "realtimeMonitor",
            meta: {
                parentId: "monitorManage",
                menuId: "monitor_center_realtime"
            },
            component: () =>
                //import ("@/views/MonitorManage/VehicleMonitor/RealtimeMonitor.vue")
                import ("@/views/MonitorManage/VehicleMonitor/RealtimeMonitorV2.vue")
        }, {
            path: "/trackPlay",
            name: "trackPlay",
            meta: {
                parentId: "monitorManage",
                menuId: "monitor_center_trail"
            },
            component: () =>
                import ("@/views/MonitorManage/VehicleMonitor/TrackPlayV2.vue")
        }, 
        {
            path: "/playBackVideo",
            name: "playBackVideo",
            meta: {
                parentId: "monitorManage",
                menuId: "monitor_center_videotape"
            },
            component: () =>
                import ("@/views/MonitorManage/VehicleMonitor/PlayBackVideo.vue")
        }, {
            path: "/videoPolling",
            name: "videoPolling",
            meta: {
                parentId: "monitorManage",
                menuId: "monitor_center_videopoll"
            },
            component: () =>
                import ("@/views/MonitorManage/VehicleMonitor/VideoPolling.vue")
        }, {
            path: "/alarmManage",
            name: "alarmManage",
            meta: {
                parentId: "monitorManage",
                menuId: "monitor_center_alarm"
            },
            component: () =>
                import ("@/views/MonitorManage/VehicleMonitor/AlarmManage.vue")
        }, {
            path: "/emphasisMonitor",
            name: "emphasisMonitor",
            meta: {
                parentId: "monitorManage",
                menuId: "monitor_center_important"
            },
            component: () =>
                import ("@/views/MonitorManage/VehicleMonitor/EmphasisMonitor.vue")
        }, {
            path: "/areaManage",
            name: "areaManage",
            meta: {
                parentId: "monitorManage",
                menuId: "monitor_center_areamanage"
            },
            component: () =>
                import ("@/views/MonitorManage/VehicleMonitor/AreaManage.vue")
        }, {
            path: "/terminalparam",
            name: "TerminalParam",
            meta: {
                parentId: "monitorManage",
                menuId: "user_center_serve_terminal"
            },
            component: () =>
                import ("@/views/UserManage/serverManage/TerminalParam/indexv2.vue")
                //import ("@/views/UserManage/serverManage/TerminalParam/index.vue")
        }
    ]
}];