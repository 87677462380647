<template>
    <div class="recursion-tree" @click.capture='clickNodeWrap'>
        <ul>
            <li v-for="(item, index) in treeData" class="tree-item-container" :key="index">
                <div
                        class="tree-item"
                        :class="[item.active?'tree-item-active':'', item.disabled?'tree-item-disabled':'']"
                        :style="{paddingLeft: treeIndex + 0.3 + 'rem'}"
                        :id="'treeItem'+item[treeProps.value]"
                        @click="clickNode(item, index)"
                        @dblclick.stop="toggleNode(item)"
                >
                    <span
                            v-if="item[treeProps.children]"
                            class="tree-expand iconfont"
                            :class="item.expand?'vehicle-item-expand':'vehicle-item-shrink'"
                            @click.stop="toggleNode(item)"
                    >
                    </span>
                    <span
                            v-if="checkBox"
                            class="tree-item-checkbox iconfont"
                            :class="item.checked?'iconCheckboxChecked-1-copy':item.partchecked?'iconindeterminatecheckbox-copy':'iconCheckboxUnchecked'"
                            @click.stop="checkBoxClick(item, index)"
                    ></span>
                    <span
                            class="tree-item-text"
                            :class="pageType=='terminalParam'?item.v6 == 1?'online-vehicle':item.v6 == 2?'travel-vehicle':'':''"
                            :title="item[treeProps.label]"
                    >{{item[treeProps.label]}}</span>
                </div>
                <SAASRecursionTree3
                        v-if="item.type!='vehicle' && item.extend && item[treeProps.children] && item[treeProps.children].length"
                        v-show="item.expand"
                        :treeData="item[treeProps.children]"
                        :tree-index="treeIndex + 1"
                        :treeProps="treeProps"
                        :checkBox="checkBox"
                        :checkedArr="checkedArr"
                        :maxLength="maxLength"
                        :pageType="pageType"
                        @toggleCheckBox="toggleCheckBox(item, index)"
                        @treeClick="treeClick"
                        @treeChoose="treeChoose"
                />
            </li>
        </ul>
    </div>
</template>

<script>
    var timer = null;
    export default {
        name: "SAASRecursionTree3",
        props: ["treeData", "treeIndex", "treeProps", "checkBox", "checkedArr", "maxLength", 'pageType'],
        methods: {
            clickNode(item) {
                // 单击行
                clearTimeout(timer);
                let that = this;
                that.$set(item, "active", true);
                timer = setTimeout(() => {
                    that.$emit("treeClick", "itemClick", item);
                }, 300);
            },
            toggleNode(item) {
                // 双击行或展开行
                clearTimeout(timer);
                item.active = true;
                this.$set(item, "expand", !item.expand);
                this.$set(item, "extend", true);
                this.$forceUpdate()
            },
            clickNodeWrap() {
                // 选中行高亮
                var that = this;
                var modifyDataFun = function (datas) {
                    if (datas) {
                        datas.forEach((m) => {
                            that.$set(m, "active", false);
                            if (m[that.treeProps.children]) {
                                modifyDataFun(m[that.treeProps.children]);
                            }
                        });
                    }
                };
                modifyDataFun(this.treeData);
            },
            checkBoxClick(item, index) {
                if (!item.checked && this.maxLength) {
                    if (item.type == 1 && this.checkedArr.length + item.totalCount > this.maxLength) {
                        this.$toast("最多只能选择" + this.maxLength + "辆车");
                        return;
                    } else if (item.type == 2 && this.checkedArr.length >= this.maxLength) {
                        this.$toast("最多只能选择" + this.maxLength + "辆车");
                        return;
                    }
                }
                var that = this;
                if (item.disabled) {
                    return;
                }
                that.$set(item, "checked", !item.checked);
                //设置子元素是否勾选
                var checkChildFun = (childrenDatas) => {
                    childrenDatas.forEach((m) => {
                        that.$set(m, "checked", item.checked);
                        if (m[this.treeProps.children]) {
                            checkChildFun(m[this.treeProps.children]);
                        }
                    });
                };
                if (item[this.treeProps.children]) {
                    checkChildFun(item[this.treeProps.children]);
                }
                this.clickNode(item);
                this.$emit("toggleCheckBox", item);
                this.$emit("treeChoose", item);
            },
            treeClick(handler, item) {
                this.$emit("treeClick", handler, item);
            },
            treeChoose(item) {
                this.$emit("treeChoose", item);
            },
            toggleCheckBox(item, index) {
                // 递归调用父组件方法
                this.$emit("toggleCheckBox", item);
            }
        }

    };
</script>

<style scoped lang="less">
    .recursion-tree {
        cursor: pointer;

        .tree-item {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 14px;
            width: 100%;
            line-height: 20px;
            height: 28px;
            padding-right: 5px;
        }

        .tree-expand {
            height: 100%;
            width: 15px;
            transition: transform 0.1s linear;
        }

        .vehicle-item-expand {
            background: url("../../assets/images/common/select_icon.png") no-repeat center;
            background-size: 100%;
        }

        .vehicle-item-shrink {
            background: url("../../assets/images/common/select_icon.png") no-repeat center;
            background-size: 100%;
            transform: rotate(-90deg);
            height: 15px;
        }

        .tree-item-text {
            margin-right: 5px;
        }

        .tree-item-disabled {
            color: #ddd;

            .tree-item-checkbox {
                color: #ddd;
            }
        }
    }
</style>
