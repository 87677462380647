<template>
    <div id="modelTable" class="saas-model-table">
        <el-dialog :title="title" :visible.sync="dialogVisible" :width="width" :top="top"
                   :close-on-click-modal="false">
            <slot v-if="dialogVisible"></slot>
            <template slot="footer" class="dialog-footer" v-if="dialogBtn">
                <el-button type="" @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" v-show="!isConfirm" @click="confirm">{{ confirmBtnText }}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
	export default {
		name: "SAASModelTable",
		props: [
			"confirmBtnText",
			"confirmType",
			"isConfirm",
			"title",
			"width",
			"top"
		],
		data() {
			return {
				dialogVisible: false,
				dialogBtn: true,
			};
		},
		methods: {
			confirm() {
				this.$emit("handleClick", this.confirmType);
			}
		}
	};
</script>
<style lang="less">
    #modelTable {
        .el-dialog__body {
            max-height: 70vh;
            overflow: auto;
        }
    }
</style>
