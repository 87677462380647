import axios from "axios";
import {Loading,Message} from "element-ui";
import store from "../store";

let loadingInstance = "";
let loadingInstanceFlag = {};
const options = {
	lock: true,
	text: "加载中",
	spinner: "el-icon-loading",
	background: "rgba(0, 0, 0, 0.5)"
};

// 创建axios实例 process.env.BASE_API
const service = axios.create({
	baseURL: "/saas/api/", // api的base_url
	timeout: 60000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
	let Authorization = window.localStorage.getItem("Authorization");
	const url = config.url.split("/")[config.url.split("/").length - 1];
	config.headers["Authorization"] = Authorization; // 让每个请求携带自定义token 请根据实际情况自行修改
	if (config.showMask) {
		loadingInstanceFlag[url] = true;
	}
	setTimeout(function () {
		if (loadingInstanceFlag[url]) {
			loadingInstance = Loading.service(options);
		}
	}, 1000);
	return config;
}, error => {
	// Do something with request error
	if (loadingInstance) {
		loadingInstance.close();
	}
	Promise.reject(error);
});

// respone拦截器
service.interceptors.response.use(
	response => {
		const url = response.config.url.split("/")[response.config.url.split("/").length - 1];
		loadingInstanceFlag[url] = false;
		/**
		 * code为非200是抛错 可结合自己业务进行修改
		 */
		const res = response.data;
		if (res.code === 401) {
			const _Vue = require("../main");
			store.dispatch("setLogout").then(() => {
				_Vue.default.$router.push("/login");
				// location.reload();// 为了重新实例化vue-router对象 避免bug
			});
		}
		if (loadingInstance) {
			loadingInstance.close();
		}
		if(res.code === 500){
			Message.error({message: res.message||'服务出现异常，及时联系管理员!', offset: 200});
		}

		return response.data;

		// else{
		// 	Message.error({message: '数据请求出错，请稍后再试', offset: 150});
		// }		
	},
	error => {
		if (loadingInstance) {
			loadingInstance.close();
		}
		// store.dispatch("setLogout").then(() => {
		// 	location.reload();// 为了重新实例化vue-router对象 避免bug
		// });
		return Promise.reject(error);
	}
);

export default service;